import React, { useEffect, useState } from 'react'
import { AflNavbar, AflSidebar, MerchantDashboardnavbar } from '../../../components';
import { Outlet, useNavigate } from 'react-router-dom';
import { notificationSound } from '../../../assets/sounds';
import { toast } from 'react-toastify';

const AflDashboard = () => {

    const [currentPage, setCurrentPage] = useState("dashboard");
    const navigate = useNavigate();




    return (
        <div className="afl-custom">
            <div className="col-md-12 col-xl-12 container-fluid dashboardcontainer  ">
                <div className="row flex-nowrap ">
                    <AflSidebar
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                    <div className="col dashboardright d-flex justify-content-center ">
                        <div className="dashboardright-width">
                            <AflNavbar
                                currentPage={currentPage}
                            />
                            <div className="dashboardright-scroll">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="custom-toast">
        <ToastContainer limit={1} />
      </div> */}
            </div>
        </div>
    )
}
export default AflDashboard;
import React, { useEffect, useState } from "react";
//import "./orderdetails.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/axios.instance";
import { dashboard_invoice_approve } from "../../../assets/images";
import SuccessModal from "../../SuccessModal";

const RescheduleDate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("OrderId");
  //const { orderDetails } = location.state;
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const {
    data: serviceorderDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["serviceorderDetails", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.SERVICE_DETAILS}/${OrderId}`,
        {
          headers: {
            location: "0,0",
            language: "english",
          },
        }
      );
      return response.data;
    },
  });

  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  //--------------------------------------------------------------------------

  const [dateTime, setDateTime] = useState("");
  const [minDateTime, setMinDateTime] = useState("");

  useEffect(() => {
    const now = new Date();
    const isoString = now.toISOString().substring(0, 16);
    setMinDateTime(isoString);
  }, []);

  const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateTime(event.target.value);
  };

  //--------------------------------------------------------------

  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);

  const handleUpdate = () => {
    handleReschedule.mutate({
      erpOrderNumber:
        serviceorderDetails?.aaa_service_request_model?.erpOrderNumber,
      scheduledDate: dateTime,
    });
  };

  const handleReschedule = useMutation({
    mutationFn: ({
      erpOrderNumber,
      scheduledDate,
    }: {
      erpOrderNumber: string;
      scheduledDate: string;
    }) =>
      axiosInstance.put(
        `${API_URL.RESCHEDULE_SERVICE}`,
        {
          erp_order_number: erpOrderNumber,
          scheduled_date: scheduledDate,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ),
    onSuccess: (successResponse) => {
      console.log("handleReschedule successResponse", successResponse);
      if (successResponse.status && successResponse?.data) {
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        alert(error?.response?.data?.message);
      } else {
        alert("An error occurred while rescheduling.");
      }
    },
  });

  //-------------------------------------------------------------
  return (
    <div>
      <div className="col-12">
        <div className="row">
          <div className="col-md-12 text-start div-bg-invoice-details pt-3">
            <div className="card card-bg-invoice-details">
              <div className="card-body px-5">
                <div className="col-12 pt-3">
                  <div className="invoice-text-yellow">
                    JobRefNo:
                    {
                      serviceorderDetails?.order_service_details
                        ?.referenceNumber
                    }
                  </div>
                </div>

                <div className="col-md-6 pt-3">
                  <div className="invoice-div-head">Scheduled Date & Time</div>
                  <div className="invoice-div-data">
                    {formatDate(
                      serviceorderDetails?.order_service_details?.scheduledFor
                    )}
                  </div>

                  <div className="col-md-6 pt-3">
                    <label className="form-label dashboard-poppins-font-style">
                      Reschedule for
                    </label>

                    <div className="input-date-container">
                      <input
                        type="datetime-local"
                        id="datetime"
                        value={dateTime}
                        onChange={handleDateTimeChange}
                        min={minDateTime}
                        className="merchant-input-style-create-product"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-4 pb-3 text-end">
                  <button
                    className="invoice-approve-btn px-4"
                    disabled={!dateTime}
                    onClick={handleUpdate}
                  >
                    +{" "}
                    <img
                      src={dashboard_invoice_approve}
                      height={"14px"}
                      alt=""
                    />
                    &nbsp;Update Date
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/dashboard/servicelist");
          setShowSuccessModal(false);
        }}
        message={"Rescheduled successfully!"}
      />
    </div>
  );
};

export default RescheduleDate;

import React, { useState, useEffect } from "react";
import ServiceList from "../ServiceList";
import { Card, CardBody } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  dashboard_red_car,
  dashboard_yellow_car,
  kirkos_merchant_white_car,
  merchant_dashboard_location_icon,
} from "../../../assets/images";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useMutation } from "@tanstack/react-query";
import { AflApiResponse } from "../../../types";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";

const AflDashboardHome = () => {
  //=========================SERVICELIST============================

  const [serviceList, setServiceList] = useState<AflApiResponse | null>(null);

  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);

  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  const businessArea =
    decodedToken && decodedToken["planet"] ? decodedToken["planet"] : null;

  const mutation = useMutation({
    mutationFn: (userData: any) =>
      axiosInstance.post(`${API_URL.SERVICE_LIST}?page=0`, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),

    onSuccess: (successResponse) => {
      console.log("successResponseproductlist", successResponse);

      setServiceList(successResponse?.data);
      //setTotalPages(successResponse?.data?.totalPages);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
      }
    },
  });

  useEffect(() => {
    mutation.mutate({
      erp_service_number: "",
      phone_number: "",
      // status: selectedStatus,
    });
  }, []);

  const ordersToDisplay = serviceList?.content.slice(0, 3);

  //==================================================================
  return (
    <div>
      <div className="row">
        <div className="col-md-6 pt-3">
          <Card className="card-bg-afl text-start">
            <CardBody>
              <h4 className="text-start car_upload_text align-items-center d-flex">
                <ChecklistRtlIcon
                  style={{ color: "#ed7d31", fontSize: "35px" }}
                />
                <b>&nbsp;Services Summary</b>
              </h4>
              <div className="text-start pt-3">
                <table className="myincome-table-two pt-2">
                  <tr className="myincome-table-income">
                    <td style={{ width: "70%" }}>Total booked Services</td>
                    <td className="myincome-per">1110</td>
                  </tr>
                </table>
              </div>
              <div className="car_upload_text pt-4">
                <DoneAllIcon style={{ color: "#2DC24E", fontSize: "20px" }} />
                &nbsp;Last Month Services
              </div>
              <div className="text-start pt-2">
                <table className="myincome-table-two pt-2 pb-4">
                  <tr className="myincome-table-income">
                    <td style={{ width: "70%" }}>Booked Services</td>
                    <td className="myincome-per">10</td>
                  </tr>
                  <tr className="pt-3">
                    <td>Last Month Services(Dec)</td>
                    <td>3100</td>
                  </tr>
                  <tr className="pt-3">
                    <td>Yesterday total Services</td>
                    <td> 11</td>
                  </tr>
                </table>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-6 pt-3">
          <Card className="card-bg-afl">
            <CardBody
              as={Link}
              to={"/dashboard/servicecreation"}
              className="text-start"
              style={{ textDecoration: "none" }}
            >
              <h4 className="text-start car_upload_text align-items-center d-flex">
                <ControlPointIcon
                  style={{ color: "#ed7d31", fontSize: "35px" }}
                />
                <b>&nbsp;Create Services</b>
              </h4>
              <div className="">
                <img src={dashboard_red_car} className="img-fluid" />
                <img src={dashboard_yellow_car} className="img-fluid" />
              </div>
              <div className="row text-start">
                <h3 className="car_upload_text">Click & Create</h3>
              </div>
              <div className="row text-end">
                <Link
                  to="/dashboard/servicecreation"
                  className="col ps-5 car_upload_update_text"
                >
                  Create
                </Link>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-12 pt-3">
          <Card className="card-bg-afl">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <h4 className="text-start car_upload_text align-items-center d-flex">
                    <FormatListBulletedIcon
                      style={{ color: "#ed7d31", fontSize: "35px" }}
                    />
                    Service List
                  </h4>
                </div>
                <div>
                  <Link
                    to="/dashboard/servicelist"
                    className="col ps-5 car_upload_update_text"
                  >
                    See more
                  </Link>
                </div>
              </div>
              <div className="col-12">
                {ordersToDisplay && ordersToDisplay.length > 0 ? (
                  ordersToDisplay.map((order: any, index: number) => (
                    <div className="row car_listing_row" key={index}>
                      <div className="col-3 text-start align-content-center">
                        <div className="car_listing_text">
                          {order.orderServiceDetails.referenceNumber}
                        </div>
                        <div className="list_status_grey_text"></div>
                      </div>
                      <div className="col-3 text-start align-content-center">
                        <div className="car_listing_text">
                          {order.orderView.customer_name || "N/A"}
                        </div>
                        <div className="list_status_grey_text">
                          {new Date(
                            order.orderView.createdAt
                          ).toLocaleDateString()}
                        </div>
                      </div>

                      <div className="col-4 d-flex text-start">
                        <img
                          src={merchant_dashboard_location_icon}
                          width="20"
                          height="20"
                          alt="Logo"
                          className="img-fluid "
                        />
                        <div className="ps-1">
                          <div className="list_status_grey_text">
                            To Location
                          </div>
                          <div className="car_listing_text">
                            {order?.orderView?.delivery_location?.address}
                          </div>
                        </div>
                      </div>
                      <div className="col-2  list_status_green_text align-content-center">
                        {order.orderServiceDetails.serviceName}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="row car_listing_row ">
                    No services available
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AflDashboardHome;

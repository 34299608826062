import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Dayjs } from "dayjs";
import "./TimePickerComponent.css";

interface TimePickerProps {
  onChange: (time: string | null) => void;
  label: string;
}

const TimePickerComponent: React.FC<TimePickerProps> = ({
  onChange,
  label,
}) => {
  const [open, setOpen] = useState(false);

  const handleSave = (newValue: Dayjs | null) => {
    if (newValue) {
      const formattedTime = newValue.format("HH:mm");
      onChange(formattedTime);
    } else {
      onChange(null);
    }
    setOpen(false);
  };

  return (
    <div className="time-picker-container pt-2" onClick={() => setOpen(true)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label={label}
          views={["hours", "minutes"]}
          ampm={false}
          open={open}
          onClose={() => setOpen(false)}
          onChange={(newValue) => handleSave(newValue)}
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
              onClick: () => setOpen(true),
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default TimePickerComponent;

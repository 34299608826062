import React from "react";
import { Outlet } from "react-router-dom";
import "./styleafl.css";

const AflHome = () => {
  return (
    <div className="afl-custom">
      <Outlet />
    </div>
  );
};

export default AflHome;

import React, { useEffect, useState } from "react";
import {
  afl_logo_white,
  afl_logo_white_two,
  dashboard_car,
  dashboard_icon,
  dashboard_list_my_car,
  dashboard_logout,
  sidebar_collapse_icon,
} from "../../../assets/images";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearBookingValues } from "../../../Redux/BookingData/bookingDataSlice";
import { afl_log_out } from "../../../services/axios.instance";

const dataNavTop = [
  {
    label: "Dashboard",
    icon: dashboard_icon,
    value: "dashboard",
    navlink: "/dashboard",
  },
  {
    label: "Service Creation",
    icon: dashboard_car,
    value: "servicecreation",
    navlink: "/dashboard/servicecreation",
  },
  {
    label: "Service List",
    icon: dashboard_list_my_car,
    value: "servicelist",
    navlink: "/dashboard/servicelist",
  },
];

interface MerchantDashboardSidebarProps {
  currentPage: string;
  setCurrentPage: (page: string) => void;
}

const AflSidebar: React.FC<MerchantDashboardSidebarProps> = ({
  setCurrentPage,
}) => {
  const [selectedNav, setSelectedNav] = useState("dashboard");
  const [selectedNavToggle, setSelectedNavToggle] = useState(false);
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);

  //-------------------------
  const path_location = useLocation();

  useEffect(() => {
    if (path_location.pathname === "/dashboard/servicelist") {
      handleSetActiveNavigation("servicelist");
    }
    if (path_location.pathname === "/dashboard/servicecreation") {
      handleSetActiveNavigation("servicecreation");
    }
    if (path_location.pathname === "/dashboard/servicelist/servicedetails") {
      handleSetActiveNavigation("servicelist");
    }
  }, [path_location.pathname]);

  //-----------------------

  const handleSetNavToggle = () => {
    setSelectedNavToggle(!selectedNavToggle);
  };

  const handleSetActiveNavigation = (nav: string) => {
    setSelectedNav(nav);
    setCurrentPage(nav);
  };

  const dispatch = useDispatch();
  const handleSetActiveNavigationLogout = (nav: string) => {
    afl_log_out();
    dispatch(clearBookingValues());
  };

  return (
    <div
      className={` px-0 afl-sidebar align-items-center sidebar-min-width ${
        selectedNavToggle
          ? "col-1"
          : "col-auto col-md-3 col-xl-2 responsive-sidebar-ab"
      }`}
    >
      <div className="sidebar-dash d-flex flex-column align-items-center  px-3 pt-2 text-white min-vh-100 ">
        <div className="  text-end toggle-sidebar-btn-div">
          <button className="toggle-sidebar-btn" onClick={handleSetNavToggle}>
            <img
              src={sidebar_collapse_icon}
              alt=""
              className="toggle-sidebar-btn-icon"
            />
          </button>
        </div>
        <div className="text-center mb-3 mt-2 div-logo-sidebar">
          <Link
            to="#"
            className={` align-items-center ${
              selectedNavToggle ? "sidebar-icon-toggle" : ""
            }`}
          >
            <img
              // src={dashboard_logo_kirkos}
              src={selectedNavToggle ? afl_logo_white_two : afl_logo_white}
              // width="80%"
              // height="80%"
              style={{ minWidth: "45px" }}
              alt="Logo"
              className="img-fluid ps-2"
            />
          </Link>
        </div>
        <ul
          className="nav nav-tabs flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-tab-style pt-"
          id="side-bar-menu"
        >
          {dataNavTop.map((item) => (
            <li className="nav-item sidebar-list " key={item.value}>
              <div className="dashboard-side-bar-link-div">
                <Link
                  to={item.navlink}
                  onClick={() => handleSetActiveNavigation(item.value)}
                  className={`nav-link align-middle px-1 dashboard-side-bar-link  ${
                    selectedNav === item.value ? "active-class" : ""
                  }`}
                >
                  <i className="">
                    <img src={item.icon} width="15" height="15" />
                  </i>
                  <span
                    className={`ms-1  label-sidebar label-sidebar-customer ${
                      selectedNavToggle ? "d-none-custom" : ""
                    }`}
                  >
                    {item.label}
                  </span>
                </Link>
              </div>
            </li>
          ))}
        </ul>

        <ul
          className="nav nav-tabs flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-tab-style"
          id="menu2"
        >
          <li className="nav-item p-1 sidebar-list">
            <div className="dashboard-side-bar-link-div">
              <Link
                to="/afl"
                onClick={() => handleSetActiveNavigationLogout("logout")}
                className={`nav-link align-middle px-1 dashboard-side-bar-link  ${
                  selectedNav === "logout" ? "active-class" : ""
                }`}
              >
                <i className="">
                  <img src={dashboard_logout} width="15" height="15" />
                </i>
                <span
                  className={`ms-1  label-sidebar label-sidebar-customer ${
                    selectedNavToggle ? "d-none-custom" : ""
                  }`}
                >
                  Logout
                </span>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AflSidebar;

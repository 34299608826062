import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  signUpdata,
  Country,
  CountryState,
  CustomerDetails,
} from "../../../types";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";

import "../../LocationStatus/styles.css";
import "./MerchantManageMyProfile.css";
import {
  edit_icon_profile,
  kirkos_merchant_profile,
  manage_my_info_edit_logo,
  manage_my_profile,
  manage_my_profile_gen_info_icon,
  manage_my_profile_incomplete_logo,
  manage_my_profile_license_logo,
  manage_my_profile_location_logo,
  manage_my_profile_tick,
  pdf,
} from "../../../assets/images";
import CustomInput from "../../CustomInput";
import { useForm } from "react-hook-form";
import rules from "../../../rules";
import { PencilFill, Plus, PlusSquare } from "react-bootstrap-icons";
import ImagePopupModal from "../ImagePopupModal";
import SuccessModal from "../../SuccessModal";
import { useNavigate } from "react-router-dom";

const Merchantmanagemyprofile = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [selectedCountryId, setSelectedCountryId] = useState<string>("");
  const [stateData, setStateData] = useState<CountryState[]>([]);
  const [state1, setState1] = useState<string>("");
  const [imageSource, setImageSource] = useState("");
  const [showImagePopupModal, setShowImagePopupModal] = useState(false);
  const [bussinessName, setBussinessName] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [profileImage, setProfileImage] = useState<string>("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>("");
  const [newImage, setNewImage] = useState<string>("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (name: string, value: string) => {
    if (name === "password") {
      setPassword(value);
    }
  };
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({ mode: "onChange" });

  const {
    data: countriesData,
    isLoading,
    isError,
  } = useQuery<Country[], Error>({
    queryKey: ["countries"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.COUNTRY);
      return response.data;
    },
  });

  const handleCountryChange = async (e: any) => {
    const selectedCountryName = e.target.value;
    setCountry(selectedCountryName);

    const selectedCountry = countriesData?.find(
      (country) => country.countryName === selectedCountryName
    );

    if (selectedCountry) {
      setSelectedCountryId(selectedCountry.countryId.toString());

      const response = await axiosInstance.get(
        `${API_URL.STATE}${selectedCountry.countryId}`
      );
      setStateData(response.data);
    }
  };
  const {
    data: customerData,
    error,
    refetch,
  } = useQuery<CustomerDetails, Error>({
    queryKey: ["profiledetails"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.CUSTOMER_DETAILS);
      const details = response.data;
      return details;
    },
  });
  useEffect(() => {
    if (customerData?.customerDocs) {
      const customerDocs = customerData?.customerDocs;

      const tradeLicenseDocs = Object.keys(customerDocs)?.filter((key) =>
        key.startsWith("TRADE_LICENSE")
      );

      tradeLicenseDocs?.forEach((docKey) => {
        const documentId = customerDocs[docKey];

        if (documentId !== undefined && documentId !== null) {
          axiosInstance
            .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              if (response && response.data) {
                const contentType = response.headers["content-type"];
                const isPdf = contentType === "application/pdf";
                const isImage = contentType.startsWith("image/");

                if (isPdf) {
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  const objectUrl = URL.createObjectURL(blob);

                  const downloadLink = document.getElementById(
                    `${docKey}DownloadLink`
                  ) as HTMLAnchorElement;
                  if (downloadLink) {
                    downloadLink.href = objectUrl;
                    downloadLink.style.display = "block";
                    downloadLink.download = `${docKey}.pdf`;
                  }

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  if (imageElement) {
                    imageElement.src = pdf;
                  }
                } else if (isImage) {
                  const blob = new Blob([response.data], { type: contentType });
                  const imageUrl = URL.createObjectURL(blob);

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  if (imageElement) {
                    imageElement.src = imageUrl;
                    imageElement.onclick = () => {
                      setImageSource(imageUrl);
                      setShowImagePopupModal(true);
                    };
                  }

                  const imageSizeKB = response.data.byteLength / 1000;
                  console.log(`${docKey} Image Size:`, imageSizeKB, "KB");
                } else {
                  console.error(
                    `Unsupported content type for ${docKey}: ${contentType}`
                  );
                }
              } else {
                console.error(`Failed to fetch document for ${docKey}`);
              }
            })
            .catch((error) => {
              console.error(`Error fetching ${docKey}:`, error);
            });
        } else {
          console.warn(`Document ID for ${docKey} is null or undefined`);
        }
      });
    } else {
      console.warn("No customerDocs found in customer_details.");
    }
  }, [customerData]);

  useEffect(() => {
    if (customerData) {
      setProfileImage(customerData?.merchant_details?.siteLogo);
    }
  }, [customerData]);

  useEffect(() => {
    if (customerData?.customerDocs) {
      const customerDocs = customerData?.customerDocs;

      const kycDocs = Object.keys(customerDocs)?.filter((key) =>
        key.startsWith("KYC_DETAILS")
      );

      kycDocs?.forEach((docKey) => {
        const documentId = customerDocs[docKey];

        if (documentId !== undefined && documentId !== null) {
          axiosInstance
            .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              if (response && response.data) {
                const contentType = response.headers["content-type"];
                const isPdf = contentType === "application/pdf";
                const isImage = contentType.startsWith("image/");

                if (isPdf) {
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  const objectUrl = URL.createObjectURL(blob);

                  const downloadLink = document.getElementById(
                    `${docKey}DownloadLink`
                  ) as HTMLAnchorElement;
                  if (downloadLink) {
                    downloadLink.href = objectUrl;
                    downloadLink.style.display = "block";
                    downloadLink.download = `${docKey}.pdf`;
                  }

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  if (imageElement) {
                    imageElement.src = pdf;
                  }
                } else if (isImage) {
                  const blob = new Blob([response.data], { type: contentType });
                  const imageUrl = URL.createObjectURL(blob);

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  if (imageElement) {
                    imageElement.src = imageUrl;
                    imageElement.onclick = () => {
                      setImageSource(imageUrl);
                      setShowImagePopupModal(true);
                    };
                  }

                  const imageSizeKB = response.data.byteLength / 1000;
                  console.log(`${docKey} Image Size:`, imageSizeKB, "KB");
                } else {
                  console.error(
                    `Unsupported content type for ${docKey}: ${contentType}`
                  );
                }
              } else {
                console.error(`Failed to fetch document for ${docKey}`);
              }
            })
            .catch((error) => {
              console.error(`Error fetching ${docKey}:`, error);
            });
        } else {
          console.warn(`Document ID for ${docKey} is null or undefined`);
        }
      });
    } else {
      console.warn("No customerDocs found in customer_details.");
    }
  }, [customerData]);

  useEffect(() => {
    if (customerData) {
      const {
        customer_details,
        kyc_and_bank_details,
        merchant_signup,
        shop_address,
        license_details,
        business_type,
      } = customerData || {};

      const { phone_no, email } = customer_details || {};
      const { address, state, country } = shop_address || {};
      const { business_name, number_of_fleets } = merchant_signup || {};
      const { licence_number, emirates_id, account_name } =
        kyc_and_bank_details || {};
      const { trade_license_num } = license_details || {};
      const businessTypes = business_type?.map((type) => type.type) || [];

      setValue("contactaddress", address);
      setValue("profilebusinessname", business_name);
      //setValue("profiletypesofbusiness", business_type);
      setValue("profiletypesofbusiness", businessTypes.join(", "));

      setValue("profilenumberoffleets", number_of_fleets);
      setValue("profilephonenumber", phone_no);
      setValue("profileemailaddress", email);
      setValue("tradelicensenumber", trade_license_num);
      setValue("accountname", account_name);
      setValue("contactcountry", country);
      setValue("contactstate", state);
      setCountry(String(country));
      const selectedCountry = countriesData?.find(
        (item) => String(item.countryName) === String(country)
      );

      if (selectedCountry) {
        setSelectedCountryId(selectedCountry.countryId.toString());

        axiosInstance
          .get(`${API_URL.STATE}${selectedCountry.countryId}`)
          .then((response) => {
            setStateData(response.data);
            setState1(String(state));
          })
          .catch((error) => {
            console.error("Error fetching state data:", error);
          });
      } else {
        setState1(String(state));
      }
      setValue("licensenumber", licence_number);
      setValue("emiratesidnumber", emirates_id);
    }
  }, [isLoading, customerData, countriesData]);
  const handleUpdate = () => {
    const formValues = getValues();
    let body = {
      businessName: formValues.profilebusinessname,
      siteLogo: uploadedImageUrl || profileImage,
      phone: formValues.profilephonenumber,
      numberOfFleets: formValues.profilenumberoffleets,

      // email: formValues.profileemailaddress,
      // country: country,
      // state: state1,
      // address: formValues.contactaddress,
    };
    mutate(body);
  };

  const { mutate } = useMutation({
    mutationFn: (userData: any) => axiosInstance.put(API_URL.PROFILE, userData),

    onSuccess: (successResponse) => {
      refetch();
      if (successResponse.status && successResponse?.data?.message) {
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        console.log("error", error);
      }
    },
  });
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("product_image", file);

      try {
        const response = await axiosInstance.post(
          API_URL.PRODUCT_IMAGE_UPLOAD,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data) {
          setNewImage(response.data);
          setUploadedImageUrl(response.data);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById("profileImageInput");
    if (fileInput) {
      (fileInput as HTMLInputElement).click();
    }
  };

  return (
    <div className="row ">
      <div className="col-md-12 pt-4  ">
        <div className="row">
          <div className="col-md-7 merch_my_profile_top_text text-start">
            Manage your Details, View your status, Edit your details , change
            your password....
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-4  text-center">
            <div className="card merch_my_profile_box">
              <div className="card-body align-content-center">
                <div className="col-md-12">
                  <div className="d-flex justify-content-center">
                    <div className="profile-img-div-merchant">
                      <img
                        src={
                          newImage || profileImage || kirkos_merchant_profile
                        }
                        className="img-profile-merchant"
                        alt="Profile"
                      />
                      <input
                        type="file"
                        id="profileImageInput"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                      <div
                        className="plus-icon-div-merchant"
                        onClick={triggerFileInput}
                      >
                        {/* <PencilFill className="plus-style" /> */}
                        <div className="plus-style-merchant">
                          <img src={edit_icon_profile} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 merch_my_profile_name pt-3">
                  {customerData?.merchant_signup?.business_name}
                </div>
                <div className="col-md-12 d-flex justify-content-center merch_my_profile_box_one">
                  <div className="merch_my_profile_number ">
                    {customerData?.customer_details?.phone_no}
                  </div>
                  <div className="ps-2 ">
                    <img src={manage_my_profile_tick} width="16" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8 merch_my_profile_box_two text-start">
            <div className="card merch_my_profile_box">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="pt-1">
                      <img src={manage_my_profile_gen_info_icon} width="23" />
                    </div>
                    <div className="merch_my_profile_geninfo ps-2">
                      General Information
                    </div>
                  </div>
                  <div className="col-md-6 d-flex  justify-content-md-end">
                    <div className="merch_my_profile_infoedit text-end">
                      Information Editable
                    </div>
                    <div className="ps-2">
                      <img src={manage_my_info_edit_logo} width="18" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-3">
                    <div className="my_profile_input_text-lable ">
                      Business Name <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="profilebusinessname"
                      inputType="profilebusinessname"
                      className="merch_my_profile_input_field pt-1"
                      placeholder="Skyline"
                      editable={true}
                      onInputChange={(value: string) => {}}
                      control={control}
                      // rules={rules.AuthRules.bank_name}
                    />
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="my_profile_input_text-lable">
                      Email Address <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="profileemailaddress"
                      inputType="profileemailaddress"
                      className="merch_my_profile_input_field pt-1"
                      placeholder="abc@gmail.com"
                      editable={false}
                      onInputChange={(value: string) => {
                        setBussinessName(value);
                      }}
                      control={control}
                      // rules={rules.AuthRules.bank_name}
                    />
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="my_profile_input_text-lable">
                      Phone Number <span className="merchantStar">*</span>
                    </div>
                    <div className="inputContainerStyle">
                      <span className="profile_new_country_codestyle ">
                        +971
                      </span>
                      <CustomInput
                        inputName="profilephonenumber"
                        inputType="profilephonenumber"
                        className="merch_my_profile_input_field pt-1"
                        placeholder="enter your phone number"
                        editable={true}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.bank_name}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="my_profile_input_text-lable">
                      Types of Business <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="profiletypesofbusiness"
                      inputType="profiletypesofbusiness"
                      className="merch_my_profile_input_field pt-1"
                      placeholder="car rental"
                      editable={false}
                      onInputChange={(value: string) => {}}
                      control={control}
                      // rules={rules.AuthRules.bank_name}
                    />
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="my_profile_input_text-lable">
                      Number of Fleets <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="profilenumberoffleets"
                      inputType="profilenumberoffleets"
                      className="merch_my_profile_input_field pt-1"
                      placeholder="number of fleets"
                      editable={true}
                      onInputChange={(value: string) => {}}
                      control={control}
                      // rules={rules.AuthRules.bank_name}
                    />
                  </div>
                </div>
                <div className="row float-end">
                  <div className="col-md-4 pt-3 pb-2">
                    <button
                      className=" btn all_button_style text-black"
                      onClick={handleUpdate}
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12 text-start pt-5">
            <div className="card merch_my_profile_box">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 d-flex">
                    <div className="pt-1">
                      <img src={manage_my_profile_license_logo} width="23" />
                    </div>
                    <div className="merch_my_profile_geninfo ps-2">
                      License And KYC Details / Documents
                    </div>
                    <div className="ps-4">
                      {/* <div className="merch_my_profile_license_box  d-flex">
                        <div className="ps-2 pt-1">
                          <img
                            src={manage_my_profile_incomplete_logo}
                            width="23"
                          />
                        </div>
                        <div className="merch_my_profile_incomplete_text px-2">
                          Currently your License And KYC Details / Documents are
                          incomplete
                          <br />
                          Please update the ID and Number
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="row pt-4">
                  <div className="col-md-3">
                    <div className="pt-3">
                      <div className="my_profile_input_text-lable ">
                        Trade License Number{" "}
                        <span className="merchantStar">*</span>
                      </div>
                      <CustomInput
                        inputName="tradelicensenumber"
                        inputType="tradelicensenumber"
                        className="merch_my_profile_input_field pt-1"
                        placeholder="Enter your License Number"
                        editable={false}
                        onInputChange={(value: string) => {}}
                        control={control}
                        rules={rules.AuthRules.trade_license_number}
                      />
                    </div>
                  </div>

                  <div className="col-md-3 pt-3">
                    {customerData?.license_details?.trade_license_num ? (
                      <>
                        <div className="row">
                          {customerData?.customerDocs &&
                            Object.keys(customerData?.customerDocs)
                              .filter((key) => key.startsWith("TRADE_LICENSE"))
                              .map((key) => (
                                <div
                                  key={key}
                                  id={`${key}_container`}
                                  className="col-md-6 mb-2"
                                >
                                  <p className="my_profile_input_text-lable">
                                    TRADE LICENSE:{" "}
                                  </p>

                                  <img
                                    id={`${key}_IMAGE`}
                                    src={customerData.customerDocs[key]}
                                    style={{ width: 75, height: 75 }}
                                    alt={`${key} document`}
                                  />

                                  <a
                                    className="download-img"
                                    id={`${key}DownloadLink`}
                                    href={customerData.customerDocs[key]}
                                    title={`Download ${key} PDF`}
                                    style={{ display: "none" }}
                                  >
                                    Download {key} PDF
                                  </a>
                                </div>
                              ))}
                        </div>
                      </>
                    ) : (
                      <>
                        {/* <button className="my_profile_upload_button text-center">
                          Remove / Upload
                        </button> */}
                      </>
                    )}
                  </div>
                  <div className="col-md-3 pt-3">
                    <div className="">
                      <div className="my_profile_input_text-lable ">
                        Account Name <span className="merchantStar">*</span>
                      </div>
                      <CustomInput
                        inputName="accountname"
                        inputType="accountname"
                        className="merch_my_profile_input_field pt-1"
                        placeholder="Bank of Baroda"
                        editable={false}
                        onInputChange={(value: string) => {}}
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 pt-3">
                    {customerData?.license_details?.trade_license_num ? (
                      <>
                        <div className="row">
                          {customerData?.customerDocs &&
                            Object.keys(customerData?.customerDocs)
                              .filter((key) => key.startsWith("KYC_DETAILS"))
                              .map((key) => (
                                <div
                                  key={key}
                                  id={`${key}_container`}
                                  className="col-md-6 mb-2"
                                >
                                  <p className="my_profile_input_text-lable">
                                    KYC :{" "}
                                  </p>

                                  <img
                                    id={`${key}_IMAGE`}
                                    src={customerData.customerDocs[key]}
                                    style={{ width: 75, height: 75 }}
                                    alt={`${key} document`}
                                  />

                                  <a
                                    className="download-img"
                                    id={`${key}DownloadLink`}
                                    href={customerData.customerDocs[key]}
                                    title={`Download ${key} PDF`}
                                    style={{ display: "none" }}
                                  >
                                    Download {key} PDF
                                  </a>
                                </div>
                              ))}
                        </div>
                      </>
                    ) : (
                      <>
                        {/* <button className="my_profile_upload_button text-center">
                          Remove / Upload
                        </button> */}
                      </>
                    )}
                  </div>

                  <div className="col-md-3"></div>
                </div>
                <div className="row pt-4"></div>

                <div className="row float-end"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12 text-start pt-5">
            <div className="card merch_my_profile_box">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="pt-1">
                      <img src={manage_my_profile_location_logo} width="23" />
                    </div>
                    <div className="merch_my_profile_geninfo ps-2">
                      Contact Address
                    </div>
                  </div>
                  <div className="col-md-6 d-flex  justify-content-md-end"></div>
                </div>
                <div className="row pt-4  pb-3">
                  <div className="col-md-4">
                    <div className="my_profile_input_text-lable">
                      Company Address <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="contactaddress"
                      inputType="contactaddress"
                      className="merch_my_profile_input_field pt-1"
                      placeholder="E-70, Karama , Dubai"
                      editable={false}
                      onInputChange={(value: string) => {}}
                      control={control}
                    />
                  </div>
                  {/* <div className="col-md-4">
                    <div className="merch_my_profile_input_text">
                      Country <span className="merchantStar">*</span>
                    </div>
                    <select
                      className="merchantinputStylex customWidths bg-black"
                      value={country}
                      onChange={handleCountryChange}
                    >
                      <option
                        value=""
                        key="select"
                        className="business_page_right_side_font"
                      >
                        Select country
                      </option>
                      {countriesData &&
                        countriesData.map((country) => (
                          <option
                            key={country.countryId}
                            value={country.countryName}
                          >
                            {country.countryName}
                          </option>
                        ))}
                    </select>
                  </div> */}
                  <div className="col-md-4">
                    <div className="my_profile_input_text-lable">
                      Country <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="contactcountry"
                      inputType="contactcountry"
                      className="merch_my_profile_input_field pt-1"
                      placeholder="UAE"
                      editable={false}
                      onInputChange={(value: string) => {}}
                      control={control}
                    />
                  </div>
                  <div className="col-md-4">
                    <div className="my_profile_input_text-lable">
                      State <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="contactstate"
                      inputType="contactstate"
                      className="merch_my_profile_input_field pt-1"
                      placeholder="Dubai"
                      editable={false}
                      onInputChange={(value: string) => {}}
                      control={control}
                    />
                    {/* <select
                      className="merchantinputStylex customWidths bg-black"
                      value={state1}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setState1(e.target.value)
                      }
                    >
                      <option
                        value=""
                        key="select"
                        className="business_page_right_side_font"
                      >
                        Select state
                      </option>
                      {stateData &&
                        stateData.map((state) => (
                          <option key={state.id} value={state.state_name}>
                            {state.state_name}
                          </option>
                        ))}
                    </select> */}
                    {/* </div> */}
                  </div>
                </div>
                <div className="row float-end"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/merchant/home");
          setShowSuccessModal(false);
        }}
        message={"Updated Succesfully"}
      />
      <ImagePopupModal
        isOpen={showImagePopupModal}
        src={imageSource}
        onClose={() => setShowImagePopupModal(false)}
        message={""}
      />
    </div>
  );
};

export default Merchantmanagemyprofile;

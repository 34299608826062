import car_sign_in from "./car_sign_in.svg";
import icon_black from "./icon_black.svg";
import icon_normal from "./icon_normal.svg";
import kirkos_logo from "./kirkos_logo.svg";
import car_sign_up from "./car_signup.svg";
import welcome_icon from "./welcome_icon.svg";
import car_home from "./car_home.png";
import kirkos_home from "./kirkos_home_logo.svg";
import home_backdrop from "./home_backdrop.svg";

import time_icon from "./time_icon.svg";
import calender_icon from "./calender_icon.svg";
import arrow_down from "./arrow_down.svg";
import pickup_icon from "./pickup_icon.svg";
import thumb_up from "./thumb_up.svg";
import vector from "./vector.svg";
import white_star_icon from "./white_star_icon.svg";
import file_browse_icon from "./file_browse_icon.svg";
import group_icon from "./group_icon.svg";
import petrol_pump from "./petrol_pump.svg";
import pdf from "./pdf.png";

//temp images
import qr_code from "./qr_code.svg";
import stores from "./stores.svg";
import kirkos_merchant_logo from "./kirkos_merchant_logo.svg";
import kirkos_merchant_red_car from "./kirkos_merchant_red_car.svg";
import kirkos_merchant_profile from "./kirkos_merchant_profile.svg";
import kirkos_merchant_white_car from "./kirkos_merchant_white_car.svg";
import kirkos_merchant_arrow from "./kirkos_merchant_arrow.svg";
import kirkos_merchant_yellow_car from "./kirkos_merchant_yellow_car.svg";

//Dashboard Images

import dashboard_icon from "./dashboard_icon.svg";
import dashboard_car from "./dashboard_car.svg";
import dashboard_list_my_car from "./dashboard_list_my_car.svg";
import dashboard_notification from "./dashboard_notification.svg";
import dashboard_car_status from "./dashboard_car_status.svg";
import dashboard_trip_status from "./dashboard_trip_status.svg";
import dashboard_settings from "./dashboard_settings.svg";
import dashboard_monthly_income from "./dashboard_monthly_income.svg";
import dashboard_transactions_status from "./dashboard_transactions_status.svg";
import dashboard_customer_invoices from "./dashboard_customer_invoices.svg";
import dashboard_upgrade from "./dashboard_upgrade.svg";
import dashboard_logout from "./dashboard_logout.svg";
import dashboard_profile from "./dashboard_profile.svg";
import dashboard_vertical_three_dots from "./dashboard_vertical_three_dots.svg";
import active_car_logo from "./active_car_logo.svg";
import merchant_dashboard_location_icon from "./merchant_dashboard_location_icon.svg";
import merchant_dashboard_running_in_route_icon from "./merchant_dashboard_running_in_route_icon.svg";
import merchant_dashboard_review_icon from "./merchant_dashboard_review_icon.svg";
import merchant_dashboard_review_arrow from "./merchant_dashboard_review_arrow.svg";
import merchant_dashboard_comment_box_tail from "./merchant_dashboard_comment_box_tail.svg";
import dashboard_red_car from "./dashboard_red_car.svg";
import dashboard_yellow_car from "./dashboard_yellow_car.svg";
import my_income_logo from "./my_income_logo.svg";
import info_details_sample_car from "./info_details_sample_car.svg";
import merchant_lock_icon from "./merchant_lock_icon.svg";
import customer_lock_icon from "./customer_lock_icon.svg";
import sidebar_collapse_icon from "./sidebar_collapse_icon.svg";
import dashboard_search_normal from "./dashboard-search-normal.svg";
import dashboard_location_yellow_icon from "./dashboard_location_yellow_icon.svg";
import dashboard_calendar_yellow_icon from "./dashboard_calendar_yellow_icon.svg";
import dashboard_car_yellow_icon from "./dashboard_car_yellow_icon.svg";
import dashboard_my_purchased_booking_icon from "./dashboard_my_purchased_booking_icon.svg";
import dashboard_saved_details_icon from "./dashboard_saved_details_icon.svg";
import dashboard_cancelled_details from "./dashboard_cancelled_details.svg";
import dashboard_booking_calendar from "./dashboard_booking_calendar.svg";
import dashboard_money_in_wallet from "./dashboard_money_in_wallet.svg";
import dashboard_money_history_icon from "./dashboard_money_history_icon.svg";
import dashboard_customer_arrow from "./dashboard_customer_arrow.svg";
import dashboard_mini_cooper_img from "./dashboard_mini_cooper_img.svg";
import dashboard_toyota_yellow_car_img from "./dashboard_toyota_yellow_car_img.svg";
import dashboard_hire_and_cancel from "./dashboard_hire_and_cancel.svg";
import dashboard_special_deal from "./dashboard_special_deal.svg";
import dashboard_mdi_car_insurance from "./dashboard_mdi_car_insurance.svg";
import dashboard_logo_kirkos_collapse from "./dashboard_logo_kirkos_collapse.svg";
import dashboard_logo_kirkos from "./dashboard_logo_kirkos.svg";
import onboard_full_logo_kirkos from "./onboard_full_logo_kirkos.svg";

import vector_vv from "./vector_vv.svg";
import car_key from "./car_key.svg";
import location_icon from "./location_icon.svg";
import icons_armour from "./icons_armour.svg";
import rental_car from "./rental_car.svg";
import vehicle_inspection_car from "./vehicle_inspection_car.svg";
import vehicle_inspection_img from "./vehicle_inspection_img.svg";
import yellow_bullet_star from "./yellow_bullet_star.svg";
import star from "./star.svg";
import stars from "./stars.svg";
import mileage from "./mileage.svg";
import payment_option_logo from "./payment_option_logo.svg";
import visa_card_logo from "./visa_card_logo.svg";
import master_card_logo from "./master_card_logo.svg";
import american_express_logo from "./american_express_logo.svg";
import light_mode_icon from "./light_mode_icon.svg";
import dark_mode_icon from "./dark_mode_icon.svg";
import customer_dashboard_three_dots from "./customer_dashboard_three_dots.svg";
import address_delivery_icon from "./address_delivery_icon.svg";
import registered_contact_logo from "./registered_contact_logo.svg";
import line_arrow from "./line_arrow.svg";
import material_symbols_info_outline from "./material_symbols_info_outline.svg";
import rental_charges from "./rental_charges.svg";
import baby_seat from "./baby_seat.svg";
import mdi_car_insurance from "./mdi_car_insurance.svg";
import steering_wheel from "./steering_wheel.svg";
import material_outline from "./material_outline.svg";
import modify_search_icon from "./modify_search_icon.svg";
import manage_my_profile from "./manage_my_profile.svg";
import manage_my_profile_tick from "./manage_my_profile_tick.svg";
import manage_my_profile_gen_info_icon from "./manage_my_profile_gen_info_icon.svg";
import manage_my_info_edit_logo from "./manage_my_info_edit_logo.svg";
import manage_my_profile_security_logo from "./manage_my_profile_security_logo.svg";
import manage_my_profile_license_logo from "./manage_my_profile_license_logo.svg";
import manage_my_profile_incomplete_logo from "./manage_my_profile_incomplete_logo.svg";
import manage_my_profile_close_icon from "./manage_my_profile_close_icon.svg";
import manage_my_profile_location_logo from "./manage_my_profile_location_logo.svg";
import green_tick from "./green_tick.svg";
import x_close from "./x_close.svg";
import return_tick from "./return_tick.svg";
import pencil_info_icon from "./pencil_info_icon.svg";
import mastercard_logo from "./mastercard_logo.svg";
import dashboard_invoice_approve from "./dashboard_invoice_approve.svg";
import dashboard_invoice_order_history from "./dashboard_invoice_order_history.svg";
import dashboard_invoice_green_tick from "./dashboard_invoice_green_tick.svg";
import dashboard_invoice_payment_done from "./dashboard_invoice_payment_done.svg";
import dashboard_invoice_order_date from "./dashboard_invoice_order_date.svg";
import dashboard_logo_merchant from "./dashboard_logo_merchant.svg";
import arrow_rounded from "./arrow_rounded.svg";
import dashboard_carousel_2 from "./dashboard_carousel_2.svg";
import dashboard_deposit from "./dashboard_deposit.svg";
import kirkos_merchant_new_red_car from "./kirkos_merchant_new_red_car.svg";
import dashboard_yellow_thumb_like from "./dashboard_yellow_thumb_like.svg";
import dashboard_insurance_member_icon from "./dashboard_insurance_member_icon.svg";

// garage
import garage_assigned_orders_icon from "./garage_assigned_orders_icon.svg";
import garage_booked_orders_icon from "./garage_booked_orders_icon.svg";
import garage_commission_icon from "./garage_commission_icon.svg";
import garage_sub_category_icon from "./garage_sub_category_icon.svg";
import garage_category_icon from "./garage_category_icon.svg";
import garage_dashboard_icon_one from "./garage_dashboard_icon_one.svg";
import garage_div_icon from "./garage_div_icon.svg";

//new logo change
import kirkos_logo_white_yellow from "./kirkos_logo_white_yellow.svg";
import kirkos_logo_black_yellow from "./kirkos_logo_black_yellow.svg";

//---------
import noun_no_image from "./noun-no-image.png";
import wind_shield_protection_icon from "./wind_shield_protection_icon.svg";
import deliveredIcon from "./deliveredIcon.svg";
import recollectedIcon from "./recollectedIcon.svg";
import edit_icon_profile from "./edit_icon_profile.svg";

//carousel image ----------------------------
import carousel_image_one from "./carousel_image_one.svg";

//afl---------------------------------------
import afl_logo_white from "./alfuttaim_logo.png";
import afl_logo_white_two from "./al_futtaim_logo1.png";

export {
  car_sign_in,
  icon_black,
  icon_normal,
  kirkos_logo,
  car_sign_up,
  welcome_icon,
  stores,
  qr_code,
  car_home,
  kirkos_home,
  home_backdrop,
  pickup_icon,
  time_icon,
  calender_icon,
  arrow_down,
  thumb_up,
  vector,
  file_browse_icon,
  kirkos_merchant_logo,
  kirkos_merchant_red_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_white_car,
  kirkos_merchant_arrow,
  kirkos_merchant_profile,
  white_star_icon,
  vector_vv,
  car_key,
  location_icon,
  icons_armour,
  merchant_dashboard_comment_box_tail,
  merchant_dashboard_location_icon,
  merchant_dashboard_review_arrow,
  merchant_dashboard_review_icon,
  merchant_dashboard_running_in_route_icon,
  active_car_logo,
  dashboard_red_car,
  dashboard_yellow_car,
  dashboard_profile,
  dashboard_icon,
  dashboard_car,
  dashboard_list_my_car,
  dashboard_notification,
  dashboard_car_status,
  dashboard_trip_status,
  dashboard_settings,
  dashboard_monthly_income,
  dashboard_transactions_status,
  dashboard_customer_invoices,
  dashboard_upgrade,
  dashboard_logout,
  dashboard_vertical_three_dots,
  rental_car,
  group_icon,
  petrol_pump,
  my_income_logo,
  info_details_sample_car,
  merchant_lock_icon,
  customer_lock_icon,
  sidebar_collapse_icon,
  dashboard_search_normal,
  vehicle_inspection_car,
  vehicle_inspection_img,
  yellow_bullet_star,
  star,
  stars,
  mileage,
  payment_option_logo,
  visa_card_logo,
  master_card_logo,
  american_express_logo,
  dashboard_location_yellow_icon,
  dashboard_calendar_yellow_icon,
  dashboard_car_yellow_icon,
  dashboard_my_purchased_booking_icon,
  dashboard_saved_details_icon,
  dashboard_cancelled_details,
  dashboard_booking_calendar,
  dashboard_money_in_wallet,
  dashboard_money_history_icon,
  dashboard_customer_arrow,
  dashboard_mini_cooper_img,
  dashboard_toyota_yellow_car_img,
  dashboard_hire_and_cancel,
  dashboard_special_deal,
  dashboard_mdi_car_insurance,
  light_mode_icon,
  dark_mode_icon,
  customer_dashboard_three_dots,
  address_delivery_icon,
  registered_contact_logo,
  line_arrow,
  material_symbols_info_outline,
  rental_charges,
  baby_seat,
  mdi_car_insurance,
  steering_wheel,
  material_outline,
  modify_search_icon,
  dashboard_logo_kirkos_collapse,
  dashboard_logo_kirkos,
  onboard_full_logo_kirkos,
  manage_my_profile,
  manage_my_profile_tick,
  manage_my_profile_gen_info_icon,
  manage_my_info_edit_logo,
  manage_my_profile_security_logo,
  manage_my_profile_license_logo,
  manage_my_profile_incomplete_logo,
  manage_my_profile_close_icon,
  manage_my_profile_location_logo,
  green_tick,
  x_close,
  return_tick,
  pencil_info_icon,
  mastercard_logo,
  dashboard_invoice_approve,
  dashboard_invoice_order_history,
  dashboard_invoice_green_tick,
  dashboard_invoice_payment_done,
  dashboard_invoice_order_date,
  dashboard_logo_merchant,
  dashboard_deposit,
  arrow_rounded,
  dashboard_carousel_2,
  pdf,
  kirkos_merchant_new_red_car,
  dashboard_yellow_thumb_like,
  dashboard_insurance_member_icon,
  garage_assigned_orders_icon,
  garage_booked_orders_icon,
  garage_commission_icon,
  garage_sub_category_icon,
  garage_category_icon,
  garage_dashboard_icon_one,
  kirkos_logo_white_yellow,
  kirkos_logo_black_yellow,
  noun_no_image,
  wind_shield_protection_icon,
  garage_div_icon,
  deliveredIcon,
  recollectedIcon,
  edit_icon_profile,
  carousel_image_one,
  afl_logo_white,
  afl_logo_white_two,
};

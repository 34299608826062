import {
    kirkos_logo_black_yellow,
    kirkos_merchant_new_red_car,
    kirkos_merchant_yellow_car,
    kirkos_merchant_white_car,
    afl_logo_white
} from "../../../assets/images";


const AflMerchantOnboardLeftSide = () => {
    return (
        <div className="row">
            <div className="col-md-12 ps-4 pt-3">
                <div className="row">
                    <div className="col-md-5 text-start pt-2">
                        <img
                            src={afl_logo_white}
                            // width="100"
                            // height="100"
                            alt="Logo"
                            className="logo-mechant-left"
                        />
                    </div>
                    <div className="col-md-12 pt-3">
                        <div className="left-textx text-white">
                            Let’s Set Up your <br />
                            Dashboard and Ease
                            <br />
                            your Business
                        </div>
                    </div>
                    <div className="col-md-12 pt-1">
                        <p className="left-subtextx text-white">
                            Get ready to hit the road with confidence. Your next generation
                            adventure begins here
                        </p>
                    </div>
                </div>
            </div>
            <div className="left-redcarStylex ">
                <img
                    src={kirkos_merchant_new_red_car}
                    // src={kirkos_merchant_red_car}
                    alt="car image"
                    className="img-fluid"
                />
            </div>
            <div className="left-yellowcarStylex">
                <img
                    src={kirkos_merchant_yellow_car}
                    alt="car image"
                    className=" img-fluid left-yellowcarStylex-img"
                />
            </div>
            <div className="left-whitecarStylex">
                <img
                    src={kirkos_merchant_white_car}
                    alt="car image"
                    className=" img-fluid"
                />
            </div>
        </div>
    );
};

export default AflMerchantOnboardLeftSide;

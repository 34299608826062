import React, { useEffect, useState } from "react";
import CustomInput from "../../../components/CustomInput";
import { useForm } from "react-hook-form";
import { dashboard_invoice_approve } from "../../../assets/images";
//import "./orderedit.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { ServiceData, ServiceType } from "../../../types";
import SuccessModal from "../../../components/SuccessModal";
import AuthRules from "../../../rules/AuthRules";
import rules from "../../../rules";

interface LocationData {
  latitude: number;
  longitude: number;
}

interface RecollectionLocationData {
  Recollectionlatitude: number;
  Recollectionlongitude: number;
}

const ServiceCreation = () => {
  const location = useLocation();

  //----------------------------
  const [selectedService, setSelectedService] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState("");

  const [selectedPlateNum, setSelectedPlateNum] = useState("");
  const [selectedMake, setSelectedMake] = useState<string>("");
  const [selectedModel, setSelectedModel] = useState<string>("");
  const [comments, setComments] = useState("");

  //--------------------------------

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [suggestionClicked, setSuggestionClicked] = useState(false);
  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationData>({
    latitude: 0,
    longitude: 0,
  });
  const [selectedRecollectionLocation, setSelectedRecollectionLocation] =
    useState<RecollectionLocationData>({
      Recollectionlatitude: 0,
      Recollectionlongitude: 0,
    });

  const [recollectionSearchQuery, setRecollectionSearchQuery] =
    useState<string>("");
  const [recollectionAddressSuggestions, setRecollectionAddressSuggestions] =
    useState<string[]>([]);
  const [recollectionSuggestionClicked, setRecollectionSuggestionClicked] =
    useState(false);

  const [isChecked, setIsChecked] = useState(true);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  //----------------------------------------

  const { data: serviceType } = useQuery<ServiceType[], Error>({
    queryKey: ["serviceType"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.SERVICE_TYPES);
      return response.data;
    },
  });

  //   const handleServiceTypeChange = (event: any) => {
  //     const selectedValue = event.target.value;
  //     setSelectedService(selectedValue);
  //     console.log("SER", selectedValue);
  //   };
  const handleServiceTypeChange = (event: any) => {
    const selectedValue = event.target.value;
    const selectedServiceData = serviceType?.find(
      (type) => type?.serviceName === selectedValue
    );

    if (selectedServiceData) {
      setSelectedService(selectedServiceData.serviceName);
      setSelectedServiceType(selectedServiceData.serviceType);
      console.log("Selected Service Name:", selectedServiceData.serviceName);
      console.log("Selected Service Type:", selectedServiceData.serviceType);
    }
  };

  //---------------------------------------------

  const { data: emiratesCode } = useQuery<any[], Error>({
    queryKey: ["emiratesCode"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.EMIRATES_CODE);
      return response.data;
    },
  });

  const handlePlateNumChange = (event: any) => {
    const selectedPlatenumValue = event.target.value;
    setSelectedPlateNum(selectedPlatenumValue);
    console.log("EM CODE", selectedPlatenumValue);
  };

  //------------------------------------------------

  //------------------------------------

  const {
    data: makeModelList,
    isLoading,
    error,
  } = useQuery<Record<string, string[]>, Error>({
    queryKey: ["makeModelList"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.MAKE_MODEL_LIST);
      return response.data;
    },
  });

  const handleMakeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedMake(selectedValue);
    setSelectedModel("");
  };

  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedModel(event.target.value);
  };

  const handleCommentsChange = (event: any) => {
    setComments(event.target.value);
  };

  //------------------------------------
  const [dateTime, setDateTime] = useState("");
  const [minDateTime, setMinDateTime] = useState("");

  useEffect(() => {
    const now = new Date();
    const isoString = now.toISOString().substring(0, 16);
    setMinDateTime(isoString);
  }, []);

  const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateTime(event.target.value);
  };

  //--------------------------------------------------------

  //-------------------------------------------

  const handleInputChange = (value: string) => {
    setSearchQuery(value);
    setSuggestionClicked(false);
  };

  const fetchLocationName = async (searchQuery: string) => {
    const response = await fetch(
      `https://nominatim.kirkos.ae/search.php?q=${searchQuery}&limit=5&format=json&addressdetails=1`
    );
    console.log(response, "wowww");
    const data = await response.json();
    const suggestions = data.map((item: any) => item.display_name);
    setAddressSuggestions(suggestions);
    const locationName = data?.display_name;
    return locationName;
  };

  const { data: locationName } = useQuery<string>({
    queryKey: ["locationName", searchQuery],
    queryFn: () => fetchLocationName(searchQuery),
  });
  const handleSuggestionClick = async (selectedSuggestion: string) => {
    const response = await fetch(
      `https://nominatim.kirkos.ae/search.php?q=${selectedSuggestion}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      setSelectedLocation({
        latitude: parseFloat(lat),
        longitude: parseFloat(lon),
      });
    }
    setValue("Location", selectedSuggestion);
    setAddressSuggestions([]);
    setSuggestionClicked(true);
  };

  //--------------------------------------------------------------------
  //===========================================================================

  const handleRecollectionInputChange = (value: string) => {
    setRecollectionSearchQuery(value);
    setRecollectionSuggestionClicked(false);
  };

  const fetchRecollectionLocationName = async (
    recollectionSearchQuery: string
  ) => {
    const response = await fetch(
      `https://nominatim.kirkos.ae/search.php?q=${recollectionSearchQuery}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    const suggestions = data.map((item: any) => item.display_name);
    setRecollectionAddressSuggestions(suggestions);
    return data[0]?.display_name;
  };

  const { data: recollectionLocationName } = useQuery<string>({
    queryKey: ["recollectionLocationName", recollectionSearchQuery],
    queryFn: () => fetchRecollectionLocationName(recollectionSearchQuery),
  });

  const handleRecollectionSuggestionClick = async (
    selectedRecollectionSuggestion: string
  ) => {
    const response = await fetch(
      `https://nominatim.kirkos.ae/search.php?q=${selectedRecollectionSuggestion}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      setSelectedRecollectionLocation({
        Recollectionlatitude: parseFloat(lat),
        Recollectionlongitude: parseFloat(lon),
      });
    }
    setValue("RecollectionLocation", selectedRecollectionSuggestion);
    setRecollectionAddressSuggestions([]);
    setRecollectionSuggestionClicked(true);
  };

  //===========================================================================

  //------------------------------------------------------------------------------
  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };
  const decodedToken = decodeToken(accessToken);

  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  const handleUpdate = () => {
    const formData = getValues();
    console.log("SERVICE REQ:", formData);
    console.log("button clicked");
    const recollectionLatitude =
      selectedRecollectionLocation?.Recollectionlatitude;
    const recollectionLongitude =
      selectedRecollectionLocation?.Recollectionlongitude;

    const userData: ServiceData = {
      reference_number: formData?.RefNumber,
      vehicle_make: selectedMake,
      vehicle_model: selectedModel,
      plate_number: formData?.RegNumber,
      reg_number: "",
      person_name: formData?.PersonName,
      mobile_number: formData?.Mobile,
      service_name: selectedService,
      service_based: selectedServiceType,

      location_from: {
        address: getValues("RecollectionLocation"),
        city: "",
        street: "",
        district: "",
        state: "",
        country: "",
        pinCode: "",
        latitude: recollectionLatitude?.toString() || "",
        longitude: recollectionLongitude?.toString() || "",
        landMark: "",
      },

      scheduled_for: dateTime,
      afl_comments: comments,
      emirate: selectedPlateNum,
    };

    if (
      selectedLocation &&
      (selectedLocation.latitude || selectedLocation.longitude)
    ) {
      userData.location_from = {
        address: getValues("Location"),
        city: "",
        street: "",
        district: "",
        state: "",
        country: "",
        pinCode: "",
        latitude: selectedLocation.latitude?.toString() || "",
        longitude: selectedLocation.longitude?.toString() || "",
        landMark: "",
      };
      userData.location_to = {
        address: getValues("RecollectionLocation"),
        city: "",
        type: "",
        street: "",
        district: "",
        state: "",
        country: "",
        pinCode: "",
        latitude: recollectionLatitude?.toString() || "",
        longitude: recollectionLongitude?.toString() || "",
        landMark: "",
      };
    }

    console.log("userData:", userData);

    handleServiceCreation.mutate(userData);
  };

  const handleServiceCreation = useMutation({
    mutationFn: (userData: ServiceData) =>
      axiosInstance.post(`${API_URL.CREATE_SERVICE}`, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    onSuccess: (successResponse) => {
      console.log("successResponseproduct", successResponse);
      if (successResponse.status && successResponse?.data) {
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
      }
    },
  });

  const isButtonDisabled = (): boolean => {
    if (!isValid) {
      return true;
    }
    if (selectedServiceType === "LOCATION_BASED") {
      return (
        !selectedRecollectionLocation ||
        !selectedRecollectionLocation.Recollectionlatitude ||
        !dateTime ||
        !selectedPlateNum ||
        !selectedMake ||
        !selectedModel ||
        !selectedService ||
        !selectedServiceType
      );
    }
    if (selectedServiceType === "DISTANCE_BASED") {
      return (
        !selectedLocation ||
        !selectedLocation.latitude ||
        !selectedRecollectionLocation ||
        !selectedRecollectionLocation.Recollectionlatitude ||
        !dateTime ||
        !selectedPlateNum ||
        !selectedMake ||
        !selectedModel ||
        !selectedService ||
        !selectedServiceType
      );
    }
    return true;
  };
  //-------------------------------------------------------------------------

  return (
    <div className="row afl-custom">
      <div className="col-md-12 text-start div-bg-invoice-details">
        <div className="card card-bg-invoice-details">
          <div className="card-body px-5">
            <div className="row   pb-3">
              <div className="col-md-12 pt-4">
                <div className="row">
                  <div className="row pt-2">
                    <div className="col-md-6 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Ref.No<span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="RefNumber"
                        inputType="RefNumber"
                        className="merchant-input-style-create-product"
                        placeholder=""
                        editable={true}
                        onInputChange={(value: string) => {}}
                        control={control}
                        rules={rules.AuthRules.productDescription}
                      />
                    </div>

                    <div className="col-md-3 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Make <span className="merchantStar">*</span>
                      </label>
                      <select
                        className="select-invoice-bg-black-edit dashboard-poppins-font-style"
                        onChange={handleMakeChange}
                        value={selectedMake}
                      >
                        <option value="" key="select-make">
                          Select
                        </option>
                        {makeModelList &&
                          Object.keys(makeModelList).map((make) => (
                            <option value={make} key={make}>
                              {make}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-3 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Model <span className="merchantStar">*</span>
                      </label>
                      <select
                        className="select-invoice-bg-black-edit dashboard-poppins-font-style"
                        onChange={handleModelChange}
                        value={selectedModel}
                        disabled={!selectedMake}
                      >
                        <option value="" key="select-model">
                          Select
                        </option>
                        {selectedMake &&
                          makeModelList?.[selectedMake]?.map((model, index) => (
                            <option value={model} key={index}>
                              {model}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-md-3 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Plate No<span className="merchantStar">*</span>
                      </label>

                      <select
                        className="select-invoice-bg-black-edit dashboard-poppins-font-style"
                        value={selectedPlateNum}
                        onChange={handlePlateNumChange}
                      >
                        <option value="">Select</option>
                        {emiratesCode?.map((code, index) => (
                          <option key={index} value={code}>
                            {code}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Reg.No<span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="RegNumber"
                        inputType="RegNumber"
                        className="merchant-input-style-create-product"
                        placeholder=""
                        editable={true}
                        onInputChange={(value: string) => {}}
                        control={control}
                        rules={rules.AuthRules.productDescription}
                      />
                    </div>
                    <div className="col-md-6 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Person Name<span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="PersonName"
                        inputType="PersonName"
                        className="merchant-input-style-create-product"
                        placeholder=""
                        editable={true}
                        onInputChange={(value: string) => {}}
                        control={control}
                        rules={rules.AuthRules.productDescription}
                      />
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-md-6 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Mobile<span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="Mobile"
                        inputType="Mobile"
                        className="merchant-input-style-create-product"
                        placeholder=""
                        editable={true}
                        onInputChange={(value: string) => {}}
                        control={control}
                        rules={rules.AuthRules.phone_number}
                      />
                    </div>
                    <div className="col-md-6 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Service Type<span className="merchantStar">*</span>
                      </label>

                      <select
                        className="select-invoice-bg-black-edit dashboard-poppins-font-style"
                        value={selectedService}
                        onChange={handleServiceTypeChange}
                      >
                        <option value="">Select</option>
                        {serviceType?.map((type) => (
                          <option key={type.id} value={type.serviceName}>
                            {type.serviceName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {selectedServiceType === "DISTANCE_BASED" && (
                      <div className="col-md-6 pt-3 relative-div">
                        <label className="form-label dashboard-poppins-font-style">
                          Location From<span className="merchantStar">*</span>
                        </label>
                        <CustomInput
                          inputName="Location"
                          inputType="Location"
                          className="merchant-input-style-create-product"
                          placeholder="From Location"
                          editable={true}
                          onInputChange={(value: string) =>
                            handleInputChange(value)
                          }
                          control={control}
                        />
                        {addressSuggestions?.length > 0 && (
                          <div className="absolute-div">
                            <ul
                              className="text-start"
                              style={{ color: "#333132" }}
                            >
                              {addressSuggestions?.map((suggestion, index) => (
                                <li
                                  key={index}
                                  onClick={() =>
                                    handleSuggestionClick(suggestion)
                                  }
                                >
                                  {suggestion}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                    {/* =================================================== */}
                    <div className="col-md-6 pt-3 relative-div">
                      <label className="form-label dashboard-poppins-font-style">
                        Location To<span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="RecollectionLocation"
                        inputType="RecollectionLocation"
                        className="merchant-input-style-create-product"
                        placeholder="To Location"
                        editable={true}
                        onInputChange={(value: string) =>
                          handleRecollectionInputChange(value)
                        }
                        control={control}
                      />
                      {recollectionAddressSuggestions?.length > 0 && (
                        <div className="absolute-div">
                          <ul
                            className="text-start"
                            style={{ color: "#333132" }}
                          >
                            {recollectionAddressSuggestions?.map(
                              (suggestion, index) => (
                                <li
                                  key={index}
                                  onClick={() =>
                                    handleRecollectionSuggestionClick(
                                      suggestion
                                    )
                                  }
                                >
                                  {suggestion}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
                    </div>

                    {/* ================================================== */}
                    <div className="col-md-6 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Sceduled for<span className="merchantStar">*</span>
                      </label>

                      <div className="input-date-container">
                        <input
                          type="datetime-local"
                          id="datetime"
                          value={dateTime}
                          onChange={handleDateTimeChange}
                          min={minDateTime}
                          className="merchant-input-style-create-product"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-3">
                  <label className="form-label dashboard-poppins-font-style">
                    Comments
                  </label>
                  <textarea
                    name="comments"
                    id="comments"
                    rows={5}
                    value={comments}
                    onChange={handleCommentsChange}
                    className="select-invoice-bg-black-textarea"
                  ></textarea>
                </div>
              </div>

              <div className="col-12 pt-4 pb-3 text-end">
                <button
                  className="invoice-approve-btn px-4"
                  disabled={isButtonDisabled()}
                  onClick={handleUpdate}
                >
                  +{" "}
                  <img src={dashboard_invoice_approve} height={"14px"} alt="" />
                  &nbsp;Create Request
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/dashboard/servicelist");
          setShowSuccessModal(false);
        }}
        message={"Created Succesfully"}
      />
    </div>
  );
};

export default ServiceCreation;

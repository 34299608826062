import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormControl,
  Pagination,
  Row,
} from "react-bootstrap";
import {
  dashboard_invoice_approve,
  dashboard_vertical_three_dots,
} from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ApiResponse,
  BulkApproval,
  DeliverySchedule,
  Invoice,
  NotificationState,
} from "../../../types";
import CustomCheckBox from "../../customCheckbox";
import "./style.css";
import SuccessModal from "../../SuccessModal";

interface SelectedInvoice {
  productId: string;
  deliveryStartTime: string;
  deliveryEndTime: string;
}

const CoustomerInvoiceList = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      return decoded;
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);
  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  const roles =
    decodedToken && decodedToken["roles"] ? decodedToken["roles"] : null;
  const showBranchColumn = roles?.includes("ROLE_MERCHANT_OPERATION");

  const [currentPage, setCurrentPage] = useState(0);
  const [currentSearchPage, setCurrentSearchPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("");
  const [searchResults, setSearchResults] = useState<ApiResponse | null>(null);
  const [selectedInvoices, setSelectedInvoices] = useState<{
    [key: string]: SelectedInvoice;
  }>({});
  const [isBatchApproveDisabled, setIsBatchApproveDisabled] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const fetchCustomerInvoices = async (page: number) => {
    const response = await axiosInstance.get(
      `${API_URL.PURCHASE_ORDER}/${merchantId}?status=ORDER_ACCEPT&page=${page}`
    );
    return response.data;
  };

  const { data: invoiceList } = useQuery<ApiResponse>({
    queryKey: ["invoiceList", currentPage],
    queryFn: () => fetchCustomerInvoices(currentPage),
    // refetchInterval: 30000,
  });
  //----------------------------------------------------------
  const [visiblePages, setVisiblePages] = useState<number[]>(() => {
    const totalPages = invoiceList?.totalPages || 0;

    return totalPages >= 3
      ? [0, 1, 2]
      : Array.from({ length: totalPages }, (_, i) => i);
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);

    const totalPages = invoiceList?.totalPages || 0;

    if (totalPages >= 3) {
      const startPage = Math.floor(page / 3) * 3;
      const endPage = Math.min(startPage + 2, totalPages - 1);
      setVisiblePages(
        Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
      );
    } else {
      setVisiblePages(Array.from({ length: totalPages }, (_, i) => i));
    }
  };

  useEffect(() => {
    if (invoiceList?.totalPages) {
      const totalPages = invoiceList?.totalPages || 0;

      if (totalPages >= 3) {
        const startPage = Math.floor(currentPage / 3) * 3;
        const endPage = Math.min(startPage + 2, totalPages - 1);
        setVisiblePages(
          Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i
          )
        );
      } else {
        setVisiblePages(Array.from({ length: totalPages }, (_, i) => i));
      }
    }
  }, [invoiceList, currentPage]);

  //==========================================

  // const handlePageChange = (page: number) => {
  //   setCurrentPage(page);
  // };

  // const handleSearchPageChange = (page: number) => {
  //   setCurrentSearchPage(page);
  //   handleSearch(page);
  // };
  const [searchVisiblePages, setSearchVisiblePages] = useState<number[]>(() => {
    const totalPages = searchResults?.totalPages || 0;

    return totalPages >= 3
      ? [0, 1, 2]
      : Array.from({ length: totalPages }, (_, i) => i);
  });
  const handlePageSearchChange = (page: number) => {
    setCurrentSearchPage(page);
    handleSearch(page);
  };
  //-----------------------

  useEffect(() => {
    if (searchResults?.totalPages) {
      const totalPages = searchResults?.totalPages;
      const startPage = Math.floor(currentSearchPage / 3) * 3;
      const endPage = Math.min(startPage + 2, totalPages - 1);

      setSearchVisiblePages(
        totalPages >= 3
          ? Array.from(
              { length: endPage - startPage + 1 },
              (_, i) => startPage + i
            )
          : Array.from({ length: totalPages }, (_, i) => i)
      );
    }
  }, [searchResults, currentSearchPage]);

  const formatDate = (isoDate: string) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  useEffect(() => {
    setSearchQuery("");
    setCurrentSearchPage(0);
  }, [searchCriteria]);

  const handleSearch = async (currentSearchPage: number) => {
    let date_filter_key = "";
    if (searchCriteria === "order_accepted_date") {
      date_filter_key = searchCriteria.toLowerCase().replace(" ", "_");
    }

    const payload = {
      order_number: searchCriteria === "order_number" ? searchQuery : "",
      date_filter_key: date_filter_key,
      date: searchCriteria === "order_accepted_date" ? searchQuery : "",
      insurance_claim: searchCriteria === "insurance_claim" ? searchQuery : "",
      count: 10,
      page: currentSearchPage,
      status: "ORDER_ACCEPT",
    };

    try {
      const response = await axiosInstance.post(
        API_URL.SEARCH_RESULTS,
        payload
      );
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching search results", error);
    }
  };

  const ordersToDisplay = searchResults?.content || invoiceList?.content || [];

  // const updateBatchApproveButtonState = (newSelectedInvoices: {
  //   [key: string]: SelectedInvoice;
  // }) => {
  //   const allSlotsSelected = Object.keys(newSelectedInvoices).every(
  //     (orderId) =>
  //       newSelectedInvoices[orderId].deliveryStartTime &&
  //       newSelectedInvoices[orderId].deliveryEndTime
  //   );
  //   setIsBatchApproveDisabled(!allSlotsSelected);

  // };

  //----------------------------------------------------------

  const handleNotificationUpdate = useMutation({
    mutationFn: (notificationStates: NotificationState) =>
      axiosInstance.put(API_URL.NOTIFICATION_UPDATE, notificationStates),
    onSuccess: (successResponse) => {
      console.log("Notification updated successfully:", successResponse);
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        console.error(
          "Error updating notification:",
          error.response.data.message
        );
      }
    },
  });

  //-----------------------------------------------------------------

  const handleCheckBoxChange = (orderId: string) => {
    setSelectedInvoices((prevSelectedInvoices) => {
      const newSelectedInvoices = { ...prevSelectedInvoices };
      if (newSelectedInvoices[orderId]) {
        delete newSelectedInvoices[orderId];
      } else {
        newSelectedInvoices[orderId] = {
          productId: "",
          deliveryStartTime: "",
          deliveryEndTime: "",
        };
      }
      console.log("Updated selectedInvoices:", newSelectedInvoices);
      updateBatchApproveButtonState(newSelectedInvoices);
      if (newSelectedInvoices[orderId]) {
        const notificationPayload = {
          orderNumber: orderId,
          sentScenario: "BACKOFFICE_ORDER_ACCEPT",
        };
        handleNotificationUpdate.mutate(notificationPayload);
      }
      return newSelectedInvoices;
    });
  };

  const updateBatchApproveButtonState = (newSelectedInvoices: {
    [key: string]: SelectedInvoice;
  }) => {
    const allSlotsSelected = Object.keys(newSelectedInvoices).every(
      (orderId) =>
        newSelectedInvoices[orderId].deliveryStartTime &&
        newSelectedInvoices[orderId].deliveryEndTime
    );
    console.log("All slots selected:", allSlotsSelected);
    setIsBatchApproveDisabled(!allSlotsSelected);
  };

  const handleDeliverySlotChange = (
    orderId: string,
    deliveryStartTime: string,
    deliveryEndTime: string
  ) => {
    setSelectedInvoices((prevSelectedInvoices) => {
      const newSelectedInvoices = {
        ...prevSelectedInvoices,
        [orderId]: {
          ...prevSelectedInvoices[orderId],
          deliveryStartTime: deliveryStartTime,
          deliveryEndTime: deliveryEndTime,
        },
      };
      updateBatchApproveButtonState(newSelectedInvoices);
      if (newSelectedInvoices[orderId]) {
        const notificationPayload = {
          orderNumber: orderId,
          sentScenario: "BACKOFFICE_ORDER_ACCEPT",
        };
        handleNotificationUpdate.mutate(notificationPayload);
      }
      return newSelectedInvoices;
    });
  };

  const handleProductChange = (orderId: string, productId: string) => {
    setSelectedInvoices((prevSelectedInvoices) => {
      const newSelectedInvoices = {
        ...prevSelectedInvoices,
        [orderId]: {
          ...prevSelectedInvoices[orderId],
          productId: productId,
        },
      };
      updateBatchApproveButtonState(newSelectedInvoices);
      if (newSelectedInvoices[orderId]) {
        const notificationPayload = {
          orderNumber: orderId,
          sentScenario: "BACKOFFICE_ORDER_ACCEPT",
        };
        handleNotificationUpdate.mutate(notificationPayload);
      }
      return newSelectedInvoices;
    });
  };

  const {
    data: vehicleReassign,
    isLoading: isLoadingVehicle,
    isError: isErrorVehicle,
  } = useQuery({
    queryKey: ["vehicleList"],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.VEHICLE_REASSIGN}/true`
      );
      return response.data;
    },
  });

  const {
    data: deliverySlot,
    isLoading: isLoadingSlot,
    isError: isErrorSlot,
  } = useQuery<DeliverySchedule[], Error>({
    queryKey: ["slot"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.SLOT);
      return response.data;
    },
  });

  const handleBulkApproval = useMutation({
    mutationFn: (order_batch_models: BulkApproval[]) =>
      axiosInstance.put(API_URL.BULK_APPROVAL, { order_batch_models }),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      setShowSuccessModal(true);
      if (successResponse.status && successResponse?.data?.message) {
        console.log("success");
      }
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        console.error("Error:", error.response.data.message);
      }
    },
  });

  const batchApprove = () => {
    const selectedOrderIds = Object.keys(selectedInvoices);

    const order_batch_models: BulkApproval[] = selectedOrderIds
      .map((orderId) => {
        const invoice = ordersToDisplay.find(
          (invoice) => invoice.orderId === orderId
        );

        if (!invoice) {
          console.error(`Invoice not found for orderId: ${orderId}`);
          return null;
        }

        const deliveryDate = invoice.delivery_date || "";
        const deliveryStartTime =
          selectedInvoices[orderId]?.deliveryStartTime || "";
        const deliveryEndTime =
          selectedInvoices[orderId]?.deliveryEndTime || "";

        const updatedDeliveryStartTime = deliveryStartTime
          ? deliveryDate.split("T")[0] + "T" + deliveryStartTime
          : deliveryDate;

        const updatedDeliveryEndTime = deliveryEndTime
          ? deliveryDate.split("T")[0] + "T" + deliveryEndTime
          : deliveryDate;

        const productId =
          selectedInvoices[orderId]?.productId ||
          (invoice.products && invoice.products[0]?.productId);

        if (!productId) {
          console.error(
            `Product ID not found for orderId: ${orderId}, invoice:`,
            invoice
          );
          return null;
        }

        return {
          orderNumber: invoice.orderId,
          orderStatus: "MERCHANT_ORDER_ACCEPT",
          deliveryStartTime: updatedDeliveryStartTime,
          deliveryEndTime: updatedDeliveryEndTime,
          productId,
        };
      })
      .filter((approval): approval is BulkApproval => approval !== null);

    handleBulkApproval.mutate(order_batch_models);
  };

  return (
    <div className="row">
      <div className="col-md-12 mb-3 mt-2">
        <Form.Group as={Row} className="align-items-center">
          <Col sm="3">
            <Form.Select
              className="custom-select"
              aria-label="Select search criteria"
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
            >
              <option value="">Search by</option>
              <option value="order_number">Order Number</option>
              <option value="order_accepted_date">Date</option>
              <option value="insurance_claim">Insurance Claim</option>
            </Form.Select>
          </Col>
          {searchCriteria && (
            <>
              <Col sm="5">
                <FormControl
                  className="search-bar"
                  aria-label="Text input with select"
                  placeholder={
                    searchCriteria === "order_accepted_date"
                      ? "DD-MM-YYYY"
                      : "Enter your Search"
                  }
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Col>
              <Col sm="2">
                <Button
                  className="search-button"
                  onClick={() => handleSearch(currentSearchPage)}
                >
                  Search
                </Button>
              </Col>
            </>
          )}
        </Form.Group>
      </div>
      <div className="col-12 text-end">
        <button
          className="invoice-approve-btn"
          disabled={
            isBatchApproveDisabled ||
            (!isBatchApproveDisabled &&
              Object.keys(selectedInvoices).length === 0)
          }
          onClick={batchApprove}
        >
          <img src={dashboard_invoice_approve} height={"14px"} alt="" />
          &nbsp;Batch Approve
        </button>
      </div>
      <div className="col-md-12 dashboard-table-div pt-3">
        <table className="table table-hover dashboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Order Number</th>
              {showBranchColumn && <th>Branch</th>}
              <th>Number of Days</th>
              <th>Customer Name</th>
              <th>Phone Number</th>
              <th>Product Name</th>
              <th>Date</th>
              <th>Delivery Slot/Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {ordersToDisplay?.map((invoice: Invoice, index: number) => (
              <tr key={index}>
                <td>
                  <div className="d-flex checkbox-order">
                    <CustomCheckBox
                      isChecked={!!selectedInvoices[invoice.orderId]}
                      onChange={() => handleCheckBoxChange(invoice.orderId)}
                    />
                    <span className="upgrade-text"> {index + 1}</span>
                  </div>
                </td>
                <td>{invoice?.orderId}</td>
                {showBranchColumn && <td>{invoice?.merchantName}</td>}
                <td>{invoice?.rental_days}</td>
                <td>{invoice?.customer_name}</td>
                <td>{invoice?.customer_phone_number}</td>
                <td>
                  {invoice?.insurance_order ? (
                    <select
                      className="table-select-box"
                      value={
                        selectedInvoices[invoice.orderId]?.productId ||
                        invoice?.products?.[0]?.productId
                      }
                      onChange={(e) =>
                        handleProductChange(invoice.orderId, e.target.value)
                      }
                    >
                      <option>{invoice?.products?.[0]?.productName}</option>
                      {isLoadingVehicle && <option>Loading...</option>}
                      {isErrorVehicle && <option>Error...</option>}
                      {vehicleReassign &&
                        vehicleReassign
                          .filter(
                            (vehicle: any) =>
                              vehicle.product_name !==
                              invoice?.products?.[0]?.productName
                          )
                          .map((vehicle: any) => (
                            <option
                              key={vehicle.product_id}
                              value={vehicle.product_id}
                            >
                              {vehicle.product_name}
                            </option>
                          ))}
                    </select>
                  ) : (
                    invoice?.products?.[0]?.productName
                  )}
                </td>

                <td>
                  <span className="invoice-date-td">
                    {formatDate(invoice?.createdAt)}
                  </span>
                </td>
                <td>
                  <select
                    className="table-select-box"
                    value={
                      `${
                        selectedInvoices[invoice.orderId]?.deliveryStartTime
                      }-${
                        selectedInvoices[invoice.orderId]?.deliveryEndTime
                      }` || ""
                    }
                    onChange={(e) => {
                      const [start, end] = e.target.value.split("-");
                      handleDeliverySlotChange(invoice.orderId, start, end);
                    }}
                  >
                    <option value="">Delivery Slot/Time</option>
                    {isLoadingSlot && <option>Loading...</option>}
                    {isErrorSlot && <option>Error...</option>}
                    {deliverySlot &&
                      deliverySlot.map((slot) => (
                        <option
                          key={`${slot.delivery_start_time}-${slot.delivery_end_time}`}
                          value={`${slot.delivery_start_time}-${slot.delivery_end_time}`}
                        >
                          {`${slot.delivery_start_time}-${slot.delivery_end_time}`}
                        </option>
                      ))}
                  </select>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="Dropdown-table"
                    >
                      <img
                        src={dashboard_vertical_three_dots}
                        width="15"
                        height="15"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="Dropdown-table-item">
                      <Dropdown.Item
                        className="Dropdown-table-list Dropdown-table-border-bottom"
                        onClick={() =>
                          handleNotificationUpdate.mutate({
                            orderNumber: invoice.orderId,
                            sentScenario: "BACKOFFICE_ORDER_ACCEPT",
                          })
                        }
                      >
                        <Link
                          to={`/merchant/invoice/details?OrderId=${invoice.orderId}`}
                          className="nav-link Dropdown-table-item-link"
                        >
                          View Details
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {searchResults === null && invoiceList ? (
          <Pagination className="pagination-align">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
            />
            {visiblePages.map((page) => (
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => handlePageChange(page)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === (invoiceList?.totalPages || 0) - 1}
            />
          </Pagination>
        ) : searchResults ? (
          <Pagination className="pagination-align">
            <Pagination.Prev
              onClick={() => handlePageSearchChange(currentSearchPage - 1)}
              disabled={currentSearchPage === 0}
            />
            {searchVisiblePages?.map((page) => (
              <Pagination.Item
                key={page}
                active={page === currentSearchPage}
                onClick={() => handlePageSearchChange(page)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageSearchChange(currentSearchPage + 1)}
              disabled={
                currentSearchPage === (searchResults?.totalPages || 0) - 1
              }
            />
          </Pagination>
        ) : null}
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/merchant/approvedorders");
          setShowSuccessModal(false);
        }}
        message={"Accepted Succesfully"}
      />
    </div>
  );
};

export default CoustomerInvoiceList;

import React, { useEffect, useRef, useState } from "react";
import {
  dashboard_invoice_approve,
  dashboard_invoice_green_tick,
  dashboard_invoice_order_date,
  dashboard_invoice_order_history,
  dashboard_invoice_payment_done,
  dashboard_logo_merchant,
  calender_icon,
  info_details_sample_car,
  merchant_dashboard_location_icon,
  pdf,
  manage_my_info_edit_logo,
} from "../../../assets/images";
import { MerchantInvoiceApprove } from "../../../types";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import { FieldValues, useForm } from "react-hook-form";
import ErrorModal from "../../ErrorModal";
import SuccessModal from "../../SuccessModal";
import ConfirmationModal from "../../ConfirmationModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ImagePopupModal from "../ImagePopupModal";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Carousel,
} from "react-bootstrap";
import CustomInput from "../../CustomInput";
import AuthRules from "../../../rules/AuthRules";
import { ToastContainer, toast } from "react-toastify";
import CustomRadio from "../../customRadio";
import DateTimeComponent from "../../DateTimeComponent";
import TimePicker from "../../TimePicker/components/TimePicker";
import { OverlayTrigger, Popover } from "react-bootstrap";

const RecollectedOrderDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("OrderId");
  //const OrderId = state && state.OrderId;
  console.log("new", OrderId);
  const {
    data: invoiceDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["invoiceDetails", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.ORDER_DETAILS}/${OrderId}/details`,
        {
          headers: {
            location: "0,0",
            language: "english",
          },
        }
      );
      return response.data;
    },
  });
  const { data: commentMessage } = useQuery({
    queryKey: ["commentMessage", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.GET_COMMENTS}/${OrderId}`
      );
      console.log("commentMessageRecollected", commentMessage);
      return response.data;
    },
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] =
    useState(false);
  const [showRejectConfirmationModal, setShowRejectConfirmationModal] =
    useState(false);
  const [acceptConfirmationMessage, setAcceptConfirmationMessage] =
    useState("");
  const [rejectConfirmationMessage, setRejectConfirmationMessage] =
    useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const {
    control,
    formState: { isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({ mode: "onChange" });

  //------------------------------------------------------------------------

  //--------------------------------------------------------------------------

  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const [showImagePopupModal, setShowImagePopupModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const handleimagepopover = () => {
    setShowImagePopupModal(true);
  };

  const handleClose = () => {
    setLoadModal(false);
  };

  const [loadModal, setLoadModal] = useState(false);
  const [imageSource, setImageSource] = useState("");

  useEffect(() => {
    if (invoiceDetails?.customer_details?.customerDocs) {
      const customerDocs = invoiceDetails?.customer_details?.customerDocs;

      const emiritiesDocs = Object.keys(customerDocs).filter((key) =>
        key.startsWith("EMIRITIES")
      );

      emiritiesDocs.forEach((docKey) => {
        const documentId = customerDocs[docKey];

        if (documentId !== undefined && documentId !== null) {
          axiosInstance
            .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              if (response && response.data) {
                const contentType = response.headers["content-type"];
                const isPdf = contentType === "application/pdf";
                const isImage = contentType.startsWith("image/");

                if (isPdf) {
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  const objectUrl = URL.createObjectURL(blob);

                  const downloadLink = document.getElementById(
                    `${docKey}DownloadLink`
                  ) as HTMLAnchorElement;
                  downloadLink.href = objectUrl;
                  downloadLink.style.display = "block";
                  downloadLink.download = `${docKey}.pdf`;

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = pdf;
                } else if (isImage) {
                  const blob = new Blob([response.data], { type: contentType });
                  const imageUrl = URL.createObjectURL(blob);

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = imageUrl;
                  imageElement.onclick = () => {
                    setImageSource(imageUrl);
                    setShowImagePopupModal(true);
                  };

                  const imageSizeKB = response.data.byteLength / 1000;
                  console.log(`${docKey} Image Size:`, imageSizeKB, "KB");
                }
              } else {
                console.error(`Failed to fetch document for ${docKey}`);
              }
            })
            .catch((error) => {
              console.error(`Error fetching ${docKey}:`, error);
            });
        } else {
          console.warn(`Document ID for ${docKey} is null or undefined`);
        }
      });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (invoiceDetails?.customer_details?.customerDocs) {
      const customerDocs = invoiceDetails?.customer_details?.customerDocs;

      const licenseDocs = Object.keys(customerDocs)?.filter((key) =>
        key.startsWith("LICENSE")
      );

      licenseDocs?.forEach((docKey) => {
        const documentId = customerDocs[docKey];

        if (documentId !== undefined && documentId !== null) {
          axiosInstance
            .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              if (response && response.data) {
                const contentType = response.headers["content-type"];
                const isPdf = contentType === "application/pdf";
                const isImage = contentType.startsWith("image/");

                if (isPdf) {
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  const objectUrl = URL.createObjectURL(blob);

                  const downloadLink = document.getElementById(
                    `${docKey}DownloadLink`
                  ) as HTMLAnchorElement;
                  downloadLink.href = objectUrl;
                  downloadLink.style.display = "block";
                  downloadLink.download = `${docKey}.pdf`;

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = pdf;
                } else if (isImage) {
                  const blob = new Blob([response.data], { type: contentType });
                  const imageUrl = URL.createObjectURL(blob);

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = imageUrl;
                  imageElement.onclick = () => {
                    setImageSource(imageUrl);
                    setShowImagePopupModal(true);
                  };

                  const imageSizeKB = response.data.byteLength / 1000;
                  console.log(`${docKey} Image Size:`, imageSizeKB, "KB");
                }
              } else {
                console.error(`Failed to fetch document for ${docKey}`);
              }
            })
            .catch((error) => {
              console.error(`Error fetching ${docKey}:`, error);
            });
        } else {
          console.warn(`Document ID for ${docKey} is null or undefined`);
        }
      });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (invoiceDetails?.customer_details?.customerDocs) {
      const customerDocs = invoiceDetails?.customer_details?.customerDocs;

      const VisaDocs = Object.keys(customerDocs)?.filter((key) =>
        key.startsWith("VisitVisa")
      );

      VisaDocs?.forEach((docKey) => {
        const documentId = customerDocs[docKey];

        if (documentId !== undefined && documentId !== null) {
          axiosInstance
            .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              if (response && response.data) {
                const contentType = response.headers["content-type"];
                const isPdf = contentType === "application/pdf";
                const isImage = contentType.startsWith("image/");

                if (isPdf) {
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  const objectUrl = URL.createObjectURL(blob);

                  const downloadLink = document.getElementById(
                    `${docKey}DownloadLink`
                  ) as HTMLAnchorElement;
                  downloadLink.href = objectUrl;
                  downloadLink.style.display = "block";
                  downloadLink.download = `${docKey}.pdf`;

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = pdf;
                } else if (isImage) {
                  const blob = new Blob([response.data], { type: contentType });
                  const imageUrl = URL.createObjectURL(blob);

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = imageUrl;
                  imageElement.onclick = () => {
                    setImageSource(imageUrl);
                    setShowImagePopupModal(true);
                  };

                  const imageSizeKB = response.data.byteLength / 1000;
                  console.log(`${docKey} Image Size:`, imageSizeKB, "KB");
                }
              } else {
                console.error(`Failed to fetch document for ${docKey}`);
              }
            })
            .catch((error) => {
              console.error(`Error fetching ${docKey}:`, error);
            });
        } else {
          console.warn(`Document ID for ${docKey} is null or undefined`);
        }
      });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (invoiceDetails?.customer_details?.customerDocs) {
      const customerDocs = invoiceDetails?.customer_details?.customerDocs;

      const PassportDocs = Object.keys(customerDocs)?.filter((key) =>
        key.startsWith("Passport")
      );

      PassportDocs?.forEach((docKey) => {
        const documentId = customerDocs[docKey];

        if (documentId !== undefined && documentId !== null) {
          axiosInstance
            .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              if (response && response.data) {
                const contentType = response.headers["content-type"];
                const isPdf = contentType === "application/pdf";
                const isImage = contentType.startsWith("image/");

                if (isPdf) {
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  const objectUrl = URL.createObjectURL(blob);

                  const downloadLink = document.getElementById(
                    `${docKey}DownloadLink`
                  ) as HTMLAnchorElement;
                  downloadLink.href = objectUrl;
                  downloadLink.style.display = "block";
                  downloadLink.download = `${docKey}.pdf`;

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = pdf;
                } else if (isImage) {
                  const blob = new Blob([response.data], { type: contentType });
                  const imageUrl = URL.createObjectURL(blob);

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = imageUrl;
                  imageElement.onclick = () => {
                    setImageSource(imageUrl);
                    setShowImagePopupModal(true);
                  };

                  const imageSizeKB = response.data.byteLength / 1000;
                  console.log(`${docKey} Image Size:`, imageSizeKB, "KB");
                }
              } else {
                console.error(`Failed to fetch document for ${docKey}`);
              }
            })
            .catch((error) => {
              console.error(`Error fetching ${docKey}:`, error);
            });
        } else {
          console.warn(`Document ID for ${docKey} is null or undefined`);
        }
      });
    }
  }, [invoiceDetails]);

  const formatTime = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h23",
    });
  };

  const onClickVdrDocument = (imageUrl: string) => {
    setImageSource(imageUrl);
    setShowImagePopupModal(true);
  };

  const refpopup = useRef(null);

  const popoverHoverFocus = (
    <Popover id="popover-trigger-hover-focus" className="popover-custom-edit">
      <Popover.Body className="d-flex align-items-center">
        <span className="invoice-div-data text-light">
          Edited by Kirkos backoffice
        </span>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="row">
      <div className="col-md-12 d-flex justify-content-center pt-5 pb-2">
        <p className="info-text mt-1" id="license"></p>
        <div className="invoice-details-btn-div text-end"></div>
      </div>
      <div className="col-md-12 text-start div-bg-invoice-details">
        <div className="card card-bg-invoice-details" ref={refpopup}>
          <div className="card-body px-5">
            <div className="row  pt-4 pb-3">
              <div className="col-md-6">
                <table className="invoice-table-width">
                  <tr className="invoice-table-tr-first">
                    <td className="invoice-table-td">Purchase order #</td>
                    <td>Order Created At</td>
                  </tr>
                  <tr>
                    <td>{invoiceDetails?.orderId}</td>
                    <td>{formatDate(invoiceDetails?.createdAt)}</td>
                  </tr>
                </table>
                <div className="col-md-12 pt-3">
                  {/* <div className="invoice-div-head">Invoice #</div>
                  <div className="invoice-text-yellow">DB 6001 2024 </div> */}
                  <div className="col-md-6 pt-2 pb-2">
                    {/* <img
                      src={invoiceDetails?.products[0]?.productImageUrls}
                      alt=""
                      className="img-fluid"
                    /> */}
                    <Carousel className="managemycarlist" controls={false}>
                      {invoiceDetails?.products?.[0]?.productImageUrls?.map(
                        (imageUrl: any, index: number) => (
                          <Carousel.Item
                            className="image-managemycar-height"
                            key={index}
                          >
                            <img
                              className="img-merchant-managecar"
                              src={imageUrl}
                              alt={`Product Image ${index + 1}`}
                            />
                          </Carousel.Item>
                        )
                      )}
                    </Carousel>
                  </div>
                  <div
                    className={`invoice-text-yellow d-flex align-items-center ${
                      invoiceDetails?.latest_modified_field_flags?.productId
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    {invoiceDetails?.products[0]?.productName}
                    {invoiceDetails?.modified_field_flags?.productId && (
                      <OverlayTrigger
                        container={refpopup}
                        trigger={["hover"]}
                        placement="top"
                        overlay={popoverHoverFocus}
                      >
                        <div className="ps-2">
                          <img
                            src={manage_my_info_edit_logo}
                            alt="Edit"
                            width="18"
                          />
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
                <div
                  className={`col-md-12 invoice-div-head pt-3 d-flex align-items-center ${
                    invoiceDetails?.latest_modified_field_flags
                      ?.delivery_location
                      ? "text-danger"
                      : ""
                  }`}
                >
                  Location
                  {invoiceDetails?.modified_field_flags?.delivery_location && (
                    <OverlayTrigger
                      container={refpopup}
                      trigger={["hover"]}
                      placement="top"
                      overlay={popoverHoverFocus}
                    >
                      <div className="ps-2">
                        <img
                          src={manage_my_info_edit_logo}
                          alt="Edit"
                          width="18"
                        />
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
                <div className="col-md-12 invoice-div-data">
                  {" "}
                  {invoiceDetails?.delivery_location?.address}{" "}
                </div>
                <div
                  className={`col-md-12 invoice-div-head pt-3 d-flex align-items-center ${
                    invoiceDetails?.latest_modified_field_flags?.rental_days
                      ? "text-danger"
                      : ""
                  }`}
                >
                  Number of Days
                  {invoiceDetails?.modified_field_flags?.rental_days && (
                    <OverlayTrigger
                      container={refpopup}
                      trigger={["hover"]}
                      placement="top"
                      overlay={popoverHoverFocus}
                    >
                      <div className="ps-2">
                        <img
                          src={manage_my_info_edit_logo}
                          alt="Edit"
                          width="18"
                        />
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.rental_days -
                    (invoiceDetails?.extended_rental_days || 0)}
                </div>
                {invoiceDetails?.extended_rental_days !== null &&
                  invoiceDetails?.extended_rental_days !== 0 && (
                    <>
                      <div className="col-md-12 invoice-div-head pt-3">
                        Extended Rental Days
                      </div>
                      <div className="col-md-12 invoice-div-data">
                        {invoiceDetails?.extended_rental_days}
                      </div>
                      <div className="col-md-12 invoice-div-head pt-3">
                        Total Rental Days
                      </div>
                      <div className="col-md-12 invoice-div-data">
                        {invoiceDetails?.rental_days}
                      </div>
                    </>
                  )}
                <div className="col-md-12 invoice-div-head pt-3">
                  Payment Method
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.payment_method}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  License Number
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.customer_details?.drivingLicenseNumber}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  License Expiry Date
                </div>
                <div className="col-md-12 invoice-div-data">
                  {formatDate(
                    invoiceDetails?.customer_details?.licenceExpiryDate
                  )}
                </div>
              </div>
              <div className="col-md-6 border-start-invoice-details">
                <div className="col-md-12 invoice-div-head pt-3">
                  Customer Name
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.customer_details?.firstname}&nbsp;
                  {invoiceDetails?.customer_details?.lastname}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  Customer email
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.customer_details?.email}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  Customer Phone Number
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.customer_phone_number}
                </div>
                <div
                  className={`col-md-12 invoice-div-head pt-3 d-flex align-items-center ${
                    invoiceDetails?.latest_modified_field_flags
                      ?.delivery_start_time
                      ? "text-danger"
                      : ""
                  }`}
                >
                  Slot Time
                  {invoiceDetails?.modified_field_flags
                    ?.delivery_start_time && (
                    <OverlayTrigger
                      container={refpopup}
                      trigger={["hover"]}
                      placement="top"
                      overlay={popoverHoverFocus}
                    >
                      <div className="ps-2">
                        <img
                          src={manage_my_info_edit_logo}
                          alt="Edit"
                          width="18"
                        />
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
                <div className="col-md-12 invoice-div-data">
                  {formatTime(invoiceDetails?.delivery_start_time)} to{" "}
                  {formatTime(invoiceDetails?.delivery_end_time)}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  Delivery Time
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.delivery_preferred_time}
                </div>
                {invoiceDetails?.delivered_by === "CUSTOMER" && (
                  <>
                    <div
                      className={`col-md-12 invoice-div-head pt-3 d-flex align-items-center ${
                        invoiceDetails?.latest_modified_field_flags
                          ?.delivered_by
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      Pickup By
                      {invoiceDetails?.modified_field_flags?.delivered_by && (
                        <OverlayTrigger
                          container={refpopup}
                          trigger={["hover"]}
                          placement="top"
                          overlay={popoverHoverFocus}
                        >
                          <div className="ps-2">
                            <img
                              src={manage_my_info_edit_logo}
                              alt="Edit"
                              width="18"
                            />
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {invoiceDetails?.delivered_by}
                    </div>
                  </>
                )}
                {invoiceDetails?.delivered_by === "KIRKOS_EXECUTIVE" && (
                  <>
                    <div
                      className={`col-md-12 invoice-div-head pt-3 d-flex align-items-center ${
                        invoiceDetails?.latest_modified_field_flags
                          ?.delivered_by
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      Delivery By
                      {invoiceDetails?.modified_field_flags?.delivered_by && (
                        <OverlayTrigger
                          container={refpopup}
                          trigger={["hover"]}
                          placement="top"
                          overlay={popoverHoverFocus}
                        >
                          <div className="ps-2">
                            <img
                              src={manage_my_info_edit_logo}
                              alt="Edit"
                              width="18"
                            />
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {invoiceDetails?.delivered_by}
                    </div>
                  </>
                )}

                {invoiceDetails && invoiceDetails?.insurance_order === true && (
                  <>
                    <div className="col-md-12 invoice-div-head pt-3">
                      Insurance Claim
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {invoiceDetails?.insurance_claim}
                    </div>
                    <div className="col-md-12 invoice-div-head pt-3">
                      Service Number
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {invoiceDetails?.erp_service_number}
                    </div>
                  </>
                )}

                <div
                  className={`col-md-12 invoice-div-head d-flex align-items-center ${
                    invoiceDetails?.latest_modified_field_flags?.babySeat ||
                    invoiceDetails?.latest_modified_field_flags
                      ?.additionalDriver ||
                    invoiceDetails?.latest_modified_field_flags?.cdw_amount ||
                    invoiceDetails?.latest_modified_field_flags
                      ?.windShieldProtection ||
                    invoiceDetails?.latest_modified_field_flags?.scdwAmount ||
                    invoiceDetails?.latest_modified_field_flags
                      ?.paiInsuranceAmount
                      ? "text-danger"
                      : ""
                  }`}
                >
                  Additional Options
                  {(invoiceDetails?.modified_field_flags?.babySeat ||
                    invoiceDetails?.modified_field_flags?.additionalDriver ||
                    invoiceDetails?.modified_field_flags?.cdw_amount ||
                    invoiceDetails?.modified_field_flags
                      ?.windShieldProtection ||
                    invoiceDetails?.modified_field_flags?.scdwAmount ||
                    invoiceDetails?.modified_field_flags
                      ?.paiInsuranceAmount) && (
                    <OverlayTrigger
                      container={refpopup}
                      trigger={["hover"]}
                      placement="top"
                      overlay={popoverHoverFocus}
                    >
                      <div className="ps-2">
                        <img
                          src={manage_my_info_edit_logo}
                          alt="Edit"
                          width="18"
                        />
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.products[0]?.babySeatAmount !== 0 &&
                    invoiceDetails?.products[0]?.babySeatAmount !== null && (
                      <li>Baby Seat</li>
                    )}{" "}
                  {invoiceDetails?.products[0]?.paiInsuranceAmount !== 0 &&
                    invoiceDetails?.products[0]?.paiInsuranceAmount !==
                      null && <li>PAI Insurance</li>}{" "}
                  {invoiceDetails?.products[0]?.windShieldProtection !== 0 &&
                    invoiceDetails?.products[0]?.windShieldProtection !==
                      null && <li>WindShield Protection</li>}{" "}
                  {invoiceDetails?.products[0]?.additionalDriverAmount !== 0 &&
                    invoiceDetails?.products[0]?.additionalDriverAmount !==
                      null && <li>Additional Driver</li>}{" "}
                  {invoiceDetails?.products[0]?.scdwAmount !== 0 &&
                    invoiceDetails?.products[0]?.scdwAmount !== null && (
                      <li>SCDW Insurance</li>
                    )}{" "}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  Free Upgrade By Merchant
                </div>
                {invoiceDetails?.free_upgrade_by_merchant === true ||
                invoiceDetails?.free_upgrade_by_customer ? (
                  <div className="col-md-12 invoice-div-data">Yes</div>
                ) : (
                  <div className="col-md-12 invoice-div-data">No</div>
                )}
              </div>
              <div className="col-12 pt-3">
                <div className="row bg-delivery-details pb-3">
                  <div className="col-md-12 invoice-div-head pt-3 fs-6 invoice-text-yellow">
                    Comments
                  </div>
                  <div className="col-md-6">
                    {commentMessage && commentMessage.length > 0 && (
                      <div className="invoice-div-head pt-3 ">
                        BackOffice Comments
                        {commentMessage
                          .filter(
                            (item: any) => item?.createdBy === "ROLE_MAKER"
                            //item.orderStatus === "ORDER_ACCEPT"
                          )
                          .map((item: any, index: number) => (
                            <li
                              key={index}
                              className="pt-2 col-md-12 invoice-div-data"
                            >
                              {item?.message}
                            </li>
                          ))}
                      </div>
                    )}
                    {/* <div className="invoice-div-data style-word-brk">
                      {invoiceDetails?.order_comment}
                    </div> */}
                  </div>
                  <div className="col-md-6">
                    {commentMessage && commentMessage.length > 0 && (
                      <div className="invoice-div-head pt-3 ">
                        Merchant Comments
                        {commentMessage
                          .filter(
                            (item: any) => item?.createdBy === "ROLE_MERCHANT"
                          )
                          .map((item: any, index: number) => (
                            <li
                              key={index}
                              className="pt-2 col-md-12 invoice-div-data"
                            >
                              {item?.message}
                            </li>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 ">
                {commentMessage && commentMessage.length > 0 && (
                  <div className="col-md-12 invoice-div-head pt-3">
                    BackOffice Comments
                    {commentMessage.map((item: any, index: number) => (
                      <div key={index} className="pt-2">
                        {item.message}
                      </div>
                    ))}
                  </div>
                )} */}
              {/* <div className="col-md-12 invoice-div-data style-word-brk">
                  {invoiceDetails?.order_comment}
                </div> */}
              {/* </div> */}
              {/* <div className="col-md-6 border-start-invoice-details">
                {commentMessage && commentMessage.length > 0 &&(
                 <div
                 className="col-md-12 invoice-div-head pt-3">
                 Merchant Comments
                 {commentMessage.map((item: any, index: number) => (
                     <div key={index} className="pt-2">
                     {item.message}
                   </div>
                  ))}
                  </div>
                )}
                <div className="col-md-12 invoice-div-data style-word-brk">
                  {invoiceDetails?.merchant_comment}
                </div>
              </div> */}
            </div>
            {/* {invoiceDetails && invoiceDetails?.insurance_order === true && (
              <div className="col-md-12 pt-3">
                <div className="row bg-delivery-details pb-3">
                  <div className="col-md-12 invoice-div-head pt-3 fs-6 invoice-text-yellow">
                    Insurance Details
                  </div>

                  <div className="col-md-6">
                    <div className="invoice-div-head pt-3">
                      Insurance company
                    </div>
                    <div className="invoice-div-data">
                      {invoiceDetails?.insurance_company}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="invoice-div-head pt-3">
                      Insurance Policy
                    </div>
                    <div className="invoice-div-data">
                      {invoiceDetails?.insurance_policy}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="invoice-div-head pt-3">Insurance Claim</div>
                    <div className="invoice-div-data">
                      {invoiceDetails?.insurance_claim}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="invoice-div-head pt-3">
                      Insurance Expiry Date
                    </div>
                    <div className="invoice-div-data">
                      {formatDate(invoiceDetails?.insurance_expiry_date)}
                    </div>
                  </div>

                  {invoiceDetails?.plate_number && (
                    <>
                      <div className="col-md-6">
                        <div className="invoice-div-head pt-3">
                          Customer Plate Number
                        </div>
                        <div className="invoice-div-data">
                          {invoiceDetails?.plate_number}
                        </div>
                      </div>
                    </>
                  )}
                  {invoiceDetails?.chassis_number && (
                    <>
                      <div className="col-md-6">
                        <div className=" invoice-div-head pt-3">
                          Customer Chassis Number
                        </div>
                        <div className=" invoice-div-data">
                          {invoiceDetails?.chassis_number}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )} */}

            <div className="col-12 pt-3">
              <div className="row bg-delivery-details pb-3">
                <div className="col-md-12 invoice-div-head pt-3">
                  Plate Number
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.products[0].plateNumber}
                </div>
                <div className="col-md-6">
                  <div className="col-md-12 invoice-div-head pt-3 fs-6 invoice-text-yellow">
                    Delivery Details
                  </div>

                  <div className="col-md-12 invoice-div-head pt-3">
                    Delivery Executive Name
                  </div>
                  <div className="col-md-12 invoice-div-data">
                    {invoiceDetails?.delivery_boy_details?.firstName}
                  </div>
                  <div className="col-md-12 invoice-div-head pt-3">
                    Delivery Executive Phone No
                  </div>
                  <div className="col-md-12 invoice-div-data">
                    +971 {invoiceDetails?.delivery_boy_details?.phoneNumber}
                  </div>
                  <div className="col-md-12 invoice-div-head pt-3">
                    Delivery Date and Time
                  </div>
                  <div className="col-md-12 invoice-div-data">
                    {new Date(invoiceDetails?.delivery_date).toLocaleDateString(
                      "en-GB"
                    )}{" "}
                    at{" "}
                    {new Date(invoiceDetails?.delivery_date).toLocaleTimeString(
                      [],
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                        hourCycle: "h23",
                      }
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-md-12 invoice-div-head pt-3 fs-6 invoice-text-yellow">
                    Recollection Details
                  </div>
                  <div className="col-md-12 invoice-div-head pt-3">
                    Delivery Executive Name
                  </div>
                  <div className="col-md-12 invoice-div-data">
                    {
                      invoiceDetails?.recollected_delivery_boy_details
                        ?.firstName
                    }
                  </div>
                  <div className="col-md-12 invoice-div-head pt-3">
                    Delivery Executive Phone No
                  </div>
                  <div className="col-md-12 invoice-div-data">
                    +971{" "}
                    {
                      invoiceDetails?.recollected_delivery_boy_details
                        ?.phoneNumber
                    }
                  </div>
                  <div className="col-md-12 invoice-div-head pt-3">
                    Recollection Date and Time
                  </div>
                  <div className="col-md-12 invoice-div-data">
                    {new Date(
                      invoiceDetails?.recollection_time
                    ).toLocaleDateString("en-GB")}{" "}
                    at{" "}
                    {new Date(
                      invoiceDetails?.recollection_time
                    ).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hourCycle: "h23",
                    })}{" "}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pt-5 align-content-center">
                  <div className="row">
                    {console.log("invoiceDetails:", invoiceDetails)}
                    {invoiceDetails?.customer_details?.customerDocs &&
                      Object.keys(
                        invoiceDetails?.customer_details?.customerDocs
                      )
                        .filter((key) => key.startsWith("EMIRITIES"))
                        .map((key) => (
                          <div
                            key={key}
                            id={`${key}_container`}
                            className="col-md-6 mb-2"
                          >
                            <p>EMIRATES: </p>

                            <img
                              id={`${key}_IMAGE`}
                              src={
                                invoiceDetails.customer_details.customerDocs[
                                  key
                                ]
                              }
                              style={{ width: 75, height: 75 }}
                              alt={`${key} document`}
                            />

                            <a
                              className="download-img"
                              id={`${key}DownloadLink`}
                              href={
                                invoiceDetails.customer_details.customerDocs[
                                  key
                                ]
                              }
                              title={`Download ${key} PDF`}
                              style={{ display: "none" }}
                            >
                              Download {key} PDF
                            </a>
                          </div>
                        ))}

                    {invoiceDetails?.customer_details?.customerDocs &&
                      Object.keys(
                        invoiceDetails?.customer_details?.customerDocs
                      )
                        .filter((key) => key.startsWith("LICENSE"))
                        .map((key) => (
                          <div
                            key={key}
                            id={`${key}_container`}
                            className="col-md-6 mb-2"
                          >
                            <p>LICENSE: </p>

                            <img
                              id={`${key}_IMAGE`}
                              src={
                                invoiceDetails.customer_details.customerDocs[
                                  key
                                ]
                              }
                              style={{ width: 75, height: 75 }}
                              alt={`${key} document`}
                            />

                            <a
                              className="download-img"
                              id={`${key}DownloadLink`}
                              href={
                                invoiceDetails.customer_details.customerDocs[
                                  key
                                ]
                              }
                              title={`Download ${key} PDF`}
                              style={{ display: "none" }}
                            >
                              Download {key} PDF
                            </a>
                          </div>
                        ))}

                    {invoiceDetails?.customer_details?.customerDocs &&
                      Object.keys(
                        invoiceDetails?.customer_details?.customerDocs
                      )
                        .filter((key) => key.startsWith("VisitVisa"))
                        .map((key) => (
                          <div
                            key={key}
                            id={`${key}_container`}
                            className="col-md-6 mb-2"
                          >
                            <p>VISA: </p>

                            <img
                              id={`${key}_IMAGE`}
                              src={
                                invoiceDetails.customer_details.customerDocs[
                                  key
                                ]
                              }
                              style={{ width: 75, height: 75 }}
                              alt={`${key} document`}
                            />

                            <a
                              className="download-img"
                              id={`${key}DownloadLink`}
                              href={
                                invoiceDetails.customer_details.customerDocs[
                                  key
                                ]
                              }
                              title={`Download ${key} PDF`}
                              style={{ display: "none" }}
                            >
                              Download {key} PDF
                            </a>
                          </div>
                        ))}

                    {invoiceDetails?.customer_details?.customerDocs &&
                      Object.keys(
                        invoiceDetails?.customer_details?.customerDocs
                      )
                        .filter((key) => key.startsWith("Passport"))
                        .map((key) => (
                          <div
                            key={key}
                            id={`${key}_container`}
                            className="col-md-6 mb-2"
                          >
                            <p>Passport: </p>

                            <img
                              id={`${key}_IMAGE`}
                              src={
                                invoiceDetails.customer_details.customerDocs[
                                  key
                                ]
                              }
                              style={{ width: 75, height: 75 }}
                              alt={`${key} document`}
                            />

                            <a
                              className="download-img"
                              id={`${key}DownloadLink`}
                              href={
                                invoiceDetails.customer_details.customerDocs[
                                  key
                                ]
                              }
                              title={`Download ${key} PDF`}
                              style={{ display: "none" }}
                            >
                              Download {key} PDF
                            </a>
                          </div>
                        ))}
                  </div>
                  <div className="col-md-12 invoice-div-data pt-3">
                    Documents(VDR)
                  </div>
                  <div className="row">
                    {invoiceDetails?.products[0]?.checkListImageUrls?.map(
                      (url: string | undefined, index: number) => (
                        <div className="col-md-6 pt-3" key={index as number}>
                          {url?.endsWith(".pdf") ? (
                            <a
                              href={url}
                              download={`Checklist ${
                                (index as number) + 1
                              }.pdf`}
                              style={{ display: "block", margin: "10px 0" }}
                            >
                              Download Document {(index as number) + 1}
                            </a>
                          ) : (
                            <img
                              src={url}
                              onClick={() => onClickVdrDocument(url!)}
                              alt={`Checklist ${(index as number) + 1}`}
                              style={{ width: 75, height: 75 }}
                            />
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="col-md-6 pt-5">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card invoice-card-inner">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-1  text-center">
                              <img
                                src={dashboard_invoice_order_history}
                                height={"16px"}
                                alt=""
                              />
                            </div>
                            <div className="col invoice-text-yellow">
                              Order History
                            </div>
                          </div>
                          <div className="col-md-11 pt-3">
                            <div className="d-flex ">
                              <div className="col-1  text-center">
                                <img
                                  src={dashboard_invoice_order_date}
                                  height={"18px"}
                                  alt=""
                                />
                              </div>
                              <div className="col">
                                <div className="invoice-div-head">
                                  Purchase order Date
                                </div>
                                <div className="invoice-inner-card-text">
                                  {formatDate(invoiceDetails?.createdAt)}{" "}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex ps-2">
                              <div className="col-1 text-center hor_line"></div>
                            </div>
                            <div className="d-flex pt-2">
                              <div className="col-1  text-center">
                                <img
                                  src={dashboard_invoice_green_tick}
                                  height={"18px"}
                                  alt=""
                                />
                              </div>
                              <div className="col">
                                <div className=" invoice-div-head">Status</div>
                                <div className=" invoice-text-green">
                                  {invoiceDetails?.orderStatus}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex ps-2">
                              <div className="col-1 text-center hor_line"></div>
                            </div>
                            <div className="d-flex pt-2">
                              <div className="col-1  text-center">
                                <img
                                  src={merchant_dashboard_location_icon}
                                  height={"18px"}
                                  alt=""
                                />
                              </div>
                              <div className="col">
                                <div
                                  className={`col-md-12 invoice-div-head d-flex align-items-center ${
                                    invoiceDetails?.latest_modified_field_flags
                                      ?.delivery_location ||
                                    invoiceDetails?.latest_modified_field_flags
                                      ?.delivery_date
                                      ? "text-danger"
                                      : ""
                                  }`}
                                >
                                  Delivery Date & Location
                                  {(invoiceDetails?.modified_field_flags
                                    ?.delivery_location ||
                                    invoiceDetails?.modified_field_flags
                                      ?.delivery_date) && (
                                    <OverlayTrigger
                                      container={refpopup}
                                      trigger={["hover"]}
                                      placement="top"
                                      overlay={popoverHoverFocus}
                                    >
                                      <div className="ps-2">
                                        <img
                                          src={manage_my_info_edit_logo}
                                          alt="Edit"
                                          width="18"
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  )}
                                </div>
                                <div className="invoice-inner-card-text">
                                  {formatDate(invoiceDetails?.delivery_date)} |
                                  {invoiceDetails?.delivery_location?.address}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-11 pt-3">
                              {invoiceDetails?.order_reassign_history_response?.some(
                                (history: any) => history?.delivery_date
                              ) &&
                                invoiceDetails?.order_reassign_history_response?.some(
                                  (history: any) =>
                                    history?.previous_delivery_date !==
                                    history?.delivery_date
                                ) && (
                                  <>
                                    <div className="d-flex">
                                      <div className="col-1 text-center">
                                        <img
                                          src={dashboard_invoice_order_date}
                                          height={"18px"}
                                          alt=""
                                        />
                                      </div>
                                      <div className="col">
                                        <div className="invoice-div-head">
                                          Initial Delivery Date
                                        </div>
                                        <div className="invoice-inner-card-text">
                                          {formatDate(
                                            invoiceDetails?.order_reassign_history_response?.find(
                                              (history: any) =>
                                                history?.previous_delivery_date &&
                                                history?.previous_delivery_date !==
                                                  history?.delivery_date
                                            )?.previous_delivery_date || ""
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="d-flex ps-2">
                                      <div className="col-1 text-center hor_line"></div>
                                    </div>

                                    {invoiceDetails?.order_reassign_history_response?.map(
                                      (history: any, index: number) =>
                                        history?.delivery_date &&
                                        history?.previous_delivery_date !==
                                          history?.delivery_date && (
                                          <div key={index}>
                                            <div className="d-flex pt-2">
                                              <div className="col-1 text-center">
                                                <img
                                                  src={
                                                    dashboard_invoice_order_date
                                                  }
                                                  height={"18px"}
                                                  alt=""
                                                />
                                              </div>
                                              <div className="col">
                                                <div className="invoice-div-head">
                                                  Updated Delivery Date
                                                </div>
                                                <div className="invoice-inner-card-text">
                                                  {formatDate(
                                                    history?.delivery_date
                                                  )}
                                                </div>
                                              </div>
                                            </div>

                                            <div className="d-flex ps-2">
                                              <div className="col-1 text-center hor_line"></div>
                                            </div>
                                          </div>
                                        )
                                    )}
                                  </>
                                )}
                            </div>
                            <div className="d-flex ps-2">
                              <div className="col-1 text-center hor_line"></div>
                            </div>
                            <div className="d-flex pt-2">
                              <div className="col-1  text-center">
                                <img
                                  src={merchant_dashboard_location_icon}
                                  height={"18px"}
                                  alt=""
                                />
                              </div>
                              <div className="col">
                                <div
                                  className={`col-md-12 invoice-div-head d-flex align-items-center ${
                                    invoiceDetails?.latest_modified_field_flags
                                      ?.recollection_location ||
                                    invoiceDetails?.latest_modified_field_flags
                                      ?.recollection_date
                                      ? "text-danger"
                                      : ""
                                  }`}
                                >
                                  Recollection Date & Location
                                  {(invoiceDetails?.modified_field_flags
                                    ?.recollection_location ||
                                    invoiceDetails?.modified_field_flags
                                      ?.recollection_date) && (
                                    <OverlayTrigger
                                      container={refpopup}
                                      trigger={["hover"]}
                                      placement="top"
                                      overlay={popoverHoverFocus}
                                    >
                                      <div className="ps-2">
                                        <img
                                          src={manage_my_info_edit_logo}
                                          alt="Edit"
                                          width="18"
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  )}
                                </div>
                                <div className="invoice-inner-card-text">
                                  {formatDate(
                                    invoiceDetails?.recollection_date
                                  )}{" "}
                                  |{" "}
                                  {
                                    invoiceDetails?.recollection_location
                                      ?.address
                                  }
                                </div>
                              </div>
                            </div>
                            {/* <div className="d-flex ps-2">
                              <div className="col-1 text-center hor_line"></div>
                            </div> */}
                            {/* <div className="d-flex pt-2">
                              <div className="col-1  text-center">
                                <img
                                  src={dashboard_invoice_payment_done}
                                  height={"18px"}
                                  alt=""
                                />
                              </div>
                              <div className="col">
                                <div className=" invoice-text-green">
                                  {invoiceDetails?.payment_status}
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message="Oops! Something went wrong"
      />

      <ImagePopupModal
        isOpen={showImagePopupModal}
        //  onClose={onCloseImagePopupModal}
        src={imageSource}
        onClose={() => setShowImagePopupModal(false)}
        message={""}
      />

      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      /> */}
    </div>
  );
};

export default RecollectedOrderDetails;

import { useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import { kirkos_merchant_profile } from "../../../assets/images";
import {
  CustomInput,
  ErrorModal,
  CustomRadio,
  AflMerchantOnboardLeftSide,
} from "../../../components";
import { useForm } from "react-hook-form";
import rules from "../../../rules";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { LoginData } from "../../../types";
import { useNavigate } from "react-router-dom";
import "../../../Pages/Customer/signin/styles.css";
import RegistrationModal from "../../../components/RegistrationModal";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../../Redux/Auth/authSlice";

const LoginAFL = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [userType, setUserType] = useState<string>("MERCHANT");
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  let access_token: string = "";
  let refresh_token: string = "";
  const decodeToken = (token: any) => {
    return JSON.parse(atob(token.split(".")[1]));
  };

  const handleLogin = useMutation({
    mutationFn: (userData: LoginData) => {
      setIsButtonLoading(true);
      return axiosInstance.post(API_URL.LOGIN, userData);
    },

    onSuccess: (successResponse: any) => {
      console.log("successResponse", successResponse);
      access_token = successResponse?.data?.access_token;
      refresh_token = successResponse?.data?.refresh_token;
      const decodedToken: any = decodeToken(access_token);

      const access = decodedToken?.permission;
      let lastLevel = successResponse?.data?.last_level;
      setIsButtonLoading(false);

      if (access === "partial-access") {
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
      }

      if (access === "full-access") {
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        saveTokenToLocalStorage();
        dispatch(loginSuccess());
        navigate("/dashboard");
      } else {
        setShowErrorModal(true);
        setErrorMessage("Access denied");
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setIsButtonLoading(false);
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const saveTokenToLocalStorage = () => {
    if (isChecked && access_token !== null && refresh_token !== null) {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
    }
  };

  const [isChecked, setChecked] = useState(false);

  const handleCheckBoxChange = () => {
    setChecked(!isChecked);
    if (isChecked) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    } else {
      saveTokenToLocalStorage();
    }
  };

  const combinedValidationRule = {
    validate: (value: any) => {
      const isValidEmail =
        /^(([^<>()[\]\\.+,;:\s@"']+(\.[^<>()[\]\\.,+;:\s@"']+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        );
      const isValidPhoneNumber = /^5\d{8}$/.test(value);

      if (!isValidEmail && !isValidPhoneNumber) {
        return "Invalid email address or phone number";
      }
      return true;
    },
  };

  const onHandleSubmit = () => {
    const userData: LoginData = {
      username: email,
      password: password,
      userType: "AFL_USER",
    };

    handleLogin.mutate(userData);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="outer-container">
      <div className="container-fluid bg-black custom-container">
        <div className="row onboard-garage-main">
          <div className="col-md-6 custom-yellow-bg ">
            <AflMerchantOnboardLeftSide />
          </div>

          <div className="col-md-6 merchant_login_right text-light background-right-side-onboard ">
            <div className=" ps-3 pt-5 pe-2 text-start">
              <div className=" d-flex  pt-5 ">
                <img
                  src={kirkos_merchant_profile}
                  className="img-fluid"
                  alt="Icon"
                />
                <p className=" merchant_login_text text-light ps-3 pt-3">
                  Alfuttaim Login
                </p>
              </div>
              {/* <div className="d-flex">
                <a
                  href="/"
                  style={{
                    color: "#f4b652",
                    textDecoration: "underline",
                    marginLeft: "4%",
                    fontFamily: "Poppins, sans-serif",
                    fontStyle: "initial",
                    display: "inline",
                  }}
                >
                  Home{" "}
                </a>
              </div> */}

              <div className="merchant_login_headingStyle1 pt-1">
                Username
                <span className="merchanttopstar">*</span>
              </div>

              <div className="inputContainerStyle pt-3">
                <CustomInput
                  inputName="email"
                  inputType="email"
                  className="merchant_login_input_field"
                  placeholder="email-id or phone number"
                  editable={true}
                  onInputChange={(value: string) => setEmail(value)}
                  control={control}
                  rules={combinedValidationRule}
                />
              </div>
              <div className="merchant_login_headingStyle3 pt-4">
                Password<span className="merchanttopstar">*</span>
              </div>
              <div className="d-flex pt-3 position-relative">
                <CustomInput
                  inputName="password"
                  inputType={showPassword ? "text" : "password"}
                  className="merchant_login_input_field"
                  placeholder="xxxxxx"
                  editable={true}
                  onInputChange={(value: string) => setPassword(value)}
                  control={control}
                  rules={rules.AuthRules.password}
                />
                <span
                  className="fs-5 password_eye"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <EyeSlash /> : <Eye />}
                </span>
              </div>

              {/* <div className="col-12 text-end pt-5">
                <a
                  href="/merchant/forgotpassword"
                  className="merchant_forgot_link"
                >
                  Forgot Password?
                </a>
              </div> */}
              <div className="col-12 pt-5">
                <button
                  className="btn all_button_style text-black mb-5 btn "
                  onClick={handleSubmit(onHandleSubmit)}
                  disabled={isButtonLoading || !isValid}
                >
                  SIGN IN
                </button>
              </div>
            </div>
          </div>
          <RegistrationModal
            isOpen={showSuccessModal}
            onClose={() => setShowSuccessModal(false)}
            onNav={() => {
              navigate("/");
            }}
            message="Waiting for backoffice approval...."
          />
          <ErrorModal
            isOpen={showErrorModal}
            //  onClose={onCloseErrorModal}
            onClose={() => setShowErrorModal(false)}
            message="The credentials you entered are invalid"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginAFL;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-invoice-bg-black-edit {
  border: none;
  outline: none;
  width: 100%;
  color: var(--font-color-primary);
  font-weight: 300;
  border-bottom: 1px solid var(--border-color-theme-7);
  background-color: var(--bg-colour-theme-24);
}

.select-invoice-bg-black-edit option {
  background-color: var(--bg-color-theme-18) !important;
  color: var(--font-color-primary) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/MerchantDashboard/AcceptedorderDetailsEdit/orderedit.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,WAAW;EACX,gCAAgC;EAChC,gBAAgB;EAChB,oDAAoD;EACpD,2CAA2C;AAC7C;;AAEA;EACE,qDAAqD;EACrD,2CAA2C;AAC7C","sourcesContent":[".select-invoice-bg-black-edit {\n  border: none;\n  outline: none;\n  width: 100%;\n  color: var(--font-color-primary);\n  font-weight: 300;\n  border-bottom: 1px solid var(--border-color-theme-7);\n  background-color: var(--bg-colour-theme-24);\n}\n\n.select-invoice-bg-black-edit option {\n  background-color: var(--bg-color-theme-18) !important;\n  color: var(--font-color-primary) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormControl,
  Pagination,
  Row,
} from "react-bootstrap";
import { dashboard_vertical_three_dots } from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse, Invoice } from "../../../types";
import "./RecollectedOrder.css";

const RecollectedOrder = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access_token");

  // const decodeToken = (token: any) => {
  //   return JSON.parse(atob(token.split(".")[1]));
  // };

  const decodeToken = (token: any) => {
    if (token) {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      return decoded;
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);
  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  const roles =
    decodedToken && decodedToken["roles"] ? decodedToken["roles"] : null;
  const showBranchColumn = roles?.includes("ROLE_MERCHANT_OPERATION");

  const [currentPage, setCurrentPage] = useState(0);
  const [currentSearchPage, setCurrentSearchPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("");
  const [searchResults, setSearchResults] = useState<ApiResponse | null>(null);

  const fetchDeliveredOrders = async (page: number) => {
    const response = await axiosInstance.get(
      `${API_URL.PURCHASE_ORDER}/${merchantId}?status=RECOLLECTED&page=${page}`
    );
    return response?.data;
  };
  const { data: DeliveredOrders } = useQuery<ApiResponse>({
    queryKey: ["DeliveredOrders", currentPage],
    queryFn: () => fetchDeliveredOrders(currentPage),
    //refetchInterval: 30000,
  });

  //----------------------------------------------------------
  const [visiblePages, setVisiblePages] = useState<number[]>(() => {
    const totalPages = DeliveredOrders?.totalPages || 0;

    return totalPages >= 3
      ? [0, 1, 2]
      : Array.from({ length: totalPages }, (_, i) => i);
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);

    const totalPages = DeliveredOrders?.totalPages || 0;

    if (totalPages >= 3) {
      const startPage = Math.floor(page / 3) * 3;
      const endPage = Math.min(startPage + 2, totalPages - 1);
      setVisiblePages(
        Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
      );
    } else {
      setVisiblePages(Array.from({ length: totalPages }, (_, i) => i));
    }
  };

  useEffect(() => {
    if (DeliveredOrders?.totalPages) {
      const totalPages = DeliveredOrders?.totalPages || 0;

      if (totalPages >= 3) {
        const startPage = Math.floor(currentPage / 3) * 3;
        const endPage = Math.min(startPage + 2, totalPages - 1);
        setVisiblePages(
          Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i
          )
        );
      } else {
        setVisiblePages(Array.from({ length: totalPages }, (_, i) => i));
      }
    }
  }, [DeliveredOrders, currentPage]);

  //==========================================

  // const handlePageChange = (page: number) => {
  //   setCurrentPage(page);
  // };
  // const handlePageSearchChange = (page: number) => {
  //   setCurrentSearchPage(page);
  //   handleSearch(page);
  // };

  //--------------------------------------------------------------------------

  const [searchVisiblePages, setSearchVisiblePages] = useState<number[]>(() => {
    const totalPages = searchResults?.totalPages || 0;

    return totalPages >= 3
      ? [0, 1, 2]
      : Array.from({ length: totalPages }, (_, i) => i);
  });

  //-----------------------

  useEffect(() => {
    if (searchResults?.totalPages) {
      const totalPages = searchResults?.totalPages;
      const startPage = Math.floor(currentSearchPage / 3) * 3;
      const endPage = Math.min(startPage + 2, totalPages - 1);

      setSearchVisiblePages(
        totalPages >= 3
          ? Array.from(
              { length: endPage - startPage + 1 },
              (_, i) => startPage + i
            )
          : Array.from({ length: totalPages }, (_, i) => i)
      );
    }
  }, [searchResults, currentSearchPage]);

  // const handleSearchPageChange = (page: number) => {
  //   setSearchPage(page);
  // };
  const handlePageSearchChange = (page: number) => {
    setCurrentSearchPage(page);
    handleSearch(page);
  };
  //--------------------------------------------------------------------------------

  const handleSearch = async (currentSearchPage: number) => {
    // const date_filter_key = searchCriteria.toLowerCase().replace(" ", "_");
    let date_filter_key = "";
    if (searchCriteria === "recollection_date") {
      date_filter_key = searchCriteria.toLowerCase().replace(" ", "_");
    }

    const payload = {
      order_number: searchCriteria === "order_number" ? searchQuery : "",
      date_filter_key: date_filter_key,
      date: searchCriteria === "recollection_date" ? searchQuery : "",
      insurance_claim: searchCriteria === "insurance_claim" ? searchQuery : "",
      count: 10,
      page: currentSearchPage,
      status: "RECOLLECTED",
    };

    // const response = await axiosInstance.post(API_URL.SEARCH_RESULTS, payload);
    // setSearchResults(response.data);
    try {
      const response = await axiosInstance.post(
        API_URL.SEARCH_RESULTS,
        payload
      );
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching search results", error);
    }
  };
  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const ordersToDisplay = searchResults?.content || DeliveredOrders?.content;

  return (
    <div className="row">
      <div className="col-md-12 mb-3 mt-2">
        <Form.Group as={Row} className="align-items-center">
          <Col sm="3">
            <Form.Select
              className="custom-select"
              aria-label="Select search criteria"
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
            >
              <option value="">Search by</option>
              <option value="order_number">Order Number</option>
              <option value="recollection_date">Date</option>
              <option value="insurance_claim">Insurance Claim</option>
            </Form.Select>
          </Col>
          {searchCriteria && (
            <>
              <Col sm="5">
                <FormControl
                  className="search-bar"
                  aria-label="Text input with select"
                  placeholder={
                    searchCriteria === "recollection_date"
                      ? "DD-MM-YYYY"
                      : "Enter your Search"
                  }
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Col>
              <Col sm="2">
                <Button
                  className="search-button"
                  onClick={() => handleSearch(currentSearchPage)}
                >
                  Search
                </Button>
              </Col>
            </>
          )}
        </Form.Group>
      </div>
      <div className="col-md-12 dashboard-table-div">
        <table className="table table-hover dashboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Order Number</th>
              {showBranchColumn && <th>Branch</th>}
              <th>Number of Days</th>
              <th>Customer Name</th>
              <th>Phone Number</th>
              <th>Product Name</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {ordersToDisplay?.map((invoice: Invoice, index: number) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{invoice?.orderId}</td>
                {showBranchColumn && <td>{invoice?.merchantName}</td>}
                <td>{invoice?.rental_days}</td>
                <td>{invoice?.customer_name}</td>
                <td>{invoice?.customer_phone_number}</td>
                <td>{invoice?.products?.[0]?.productName}</td>
                <td>
                  <span className="invoice-date-td">
                    {formatDate(invoice?.recollection_date)}
                  </span>
                </td>

                <td>
                  {" "}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="Dropdown-table"
                    >
                      <img
                        src={dashboard_vertical_three_dots}
                        width="15"
                        height="15"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="Dropdown-table-item">
                      <Dropdown.Item className="Dropdown-table-list Dropdown-table-border-bottom">
                        <Link
                          to={`/merchant/recollected/details?OrderId=${invoice.orderId}`}
                          className="nav-link Dropdown-table-item-link"
                          // onClick={() => handleOrderDetails(invoice.orderId)}
                        >
                          View Details
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {searchResults === null && DeliveredOrders ? (
          <Pagination className="pagination-align">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
            />
            {visiblePages.map((page) => (
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => handlePageChange(page)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === (DeliveredOrders?.totalPages || 0) - 1}
            />
          </Pagination>
        ) : searchResults ? (
          <Pagination className="pagination-align">
            <Pagination.Prev
              onClick={() => handlePageSearchChange(currentSearchPage - 1)}
              disabled={currentSearchPage === 0}
            />
            {searchVisiblePages?.map((page) => (
              <Pagination.Item
                key={page}
                active={page === currentSearchPage}
                onClick={() => handlePageSearchChange(page)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageSearchChange(currentSearchPage + 1)}
              disabled={
                currentSearchPage === (searchResults?.totalPages || 0) - 1
              }
            />
          </Pagination>
        ) : null}
      </div>
    </div>
  );
};

export default RecollectedOrder;

import React, { useEffect } from "react";
//import "./orderdetails.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/axios.instance";

const ServiceDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("OrderId");
  //const { orderDetails } = location.state;

  const {
    data: orderDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["orderDetails", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.SERVICE_DETAILS}/${OrderId}`,
        {
          headers: {
            location: "0,0",
            language: "english",
          },
        }
      );
      return response.data;
    },
  });

  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  //--------------------------------------------------------------------------

  const timeDifference = (createdAt: string) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInSeconds = Math.floor(
      (now.getTime() - createdDate.getTime()) / 1000
    );

    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const totalMinutes = hours * 60 + minutes;

    return { totalMinutes, formatted: formatTime(hours, minutes) };
  };

  const formatTime = (hours: number, minutes: number) => {
    if (hours > 0 && minutes > 0) {
      return `${hours} hour(s) ${minutes} minute(s) ago`;
    } else if (hours > 0) {
      return `${hours} hour(s) ago`;
    } else if (minutes > 0) {
      return `${minutes} minute(s) ago`;
    } else {
      return "Just now";
    }
  };
  const { totalMinutes, formatted } = timeDifference(orderDetails?.createdAt);
  const statusColor = totalMinutes < 10 ? "text-success" : "text-danger";
  const {
    totalMinutes: totalMinutesDriverReached,
    formatted: formattedDriverReached,
  } = timeDifference(
    orderDetails?.order_status_history_responses?.find(
      (status: any) =>
        status.delivery_partner_status === "REACHED_CUSTOMER_LOCATION"
    )?.status_changed_at
  );

  const driverReachedStatusColor =
    totalMinutesDriverReached < 45 ? "text-success" : "text-danger";

  return (
    <div>
      <div className="col-12">
        <div className="row">
          <div className="col-md-12 text-start div-bg-invoice-details pt-3">
            <div className="card card-bg-invoice-details">
              <div className="card-body px-5">
                <div className="row  pt-4 pb-3">
                  <div className="col-12 pt-3">
                    <div className="invoice-text-yellow">
                      JobRefNo:
                      {orderDetails?.order_service_details?.referenceNumber}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Vehicle Make</div>
                    <div className="invoice-div-data">
                      {orderDetails?.order_service_details?.vehicleMake}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Vehicle Model</div>
                    <div className="invoice-div-data">
                      {orderDetails?.order_service_details?.carModel}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Mobile</div>
                    <div className="invoice-div-data">
                      {orderDetails?.aaa_service_request_model?.mobileNumber}
                    </div>
                  </div>

                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Plate Number</div>
                    <div className="invoice-div-data">
                      {orderDetails?.aaa_service_request_model?.plateNumber}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Service Date</div>
                    <div className="invoice-div-data">
                      {" "}
                      {formatDate(orderDetails?.createdAt)}
                    </div>
                  </div>
                  {orderDetails?.recollection_location ? (
                    <>
                      <div className="col-md-6 pt-3">
                        <div className="invoice-div-head">Location From</div>
                        <div className="invoice-div-data">
                          {orderDetails?.delivery_location?.address}
                        </div>
                      </div>
                      <div className="col-md-6 pt-3">
                        <div className="invoice-div-head">Location To</div>
                        <div className="invoice-div-data">
                          {orderDetails?.recollection_location?.address}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-md-6 pt-3">
                      <div className="invoice-div-head">Location To</div>
                      <div className="invoice-div-data">
                        {orderDetails?.delivery_location?.address}
                      </div>
                    </div>
                  )}

                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Comments AFL</div>
                    <div className="invoice-div-data">
                      {orderDetails?.order_service_details?.aflComments}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Customer Name</div>
                    <div className="invoice-div-data">
                      {orderDetails?.customer_name}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Service Type</div>
                    <div className="invoice-div-data">
                      {orderDetails?.order_service_details?.serviceName}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Service Status</div>
                    <div className="invoice-div-data">
                      {orderDetails?.aaa_service_request_model?.state}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">
                      Scheduled Date & Time (if any)
                    </div>
                    <div className="invoice-div-data">
                      {formatDate(
                        orderDetails?.order_service_details?.scheduledFor
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Driver Name</div>
                    <div className="invoice-div-data">
                      {" "}
                      {orderDetails?.aaa_service_request_model?.driverName ??
                        orderDetails?.delivery_boy_details?.firstName}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Driver Contact</div>
                    <div className="invoice-div-data">
                      {" "}
                      {orderDetails?.aaa_service_request_model?.driverPhone ??
                        orderDetails?.delivery_boy_details?.phoneNumber}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">
                      Comments from AAA agent
                    </div>
                    <div className="invoice-div-data">
                      {orderDetails?.aaa_service_request_model?.comments}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Initiated DT</div>
                    <div className="invoice-div-data">
                      {" "}
                      {formatDate(orderDetails?.createdAt)}
                      {/* <span className="text-muted">
                        {timeDifference(orderDetails?.createdAt)}
                        STATUS
                      </span> */}
                      <span className="text-muted">
                        {/* {formatted}  */}
                        <span className={statusColor}>STATUS</span>
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Driver Started DT</div>
                    <div className="invoice-div-data">
                      {orderDetails?.order_status_history_responses?.some(
                        (status: any) =>
                          status.delivery_partner_status === "DELIVERY_ACCEPTED"
                      )
                        ? formatDate(
                            orderDetails?.order_status_history_responses?.find(
                              (status: any) =>
                                status.delivery_partner_status ===
                                "DELIVERY_ACCEPTED"
                            )?.status_changed_at
                          )
                        : "N/A"}
                    </div>
                  </div>

                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Driver Reached DT</div>
                    <div className="invoice-div-data">
                      {orderDetails?.order_status_history_responses?.some(
                        (status: any) =>
                          status.delivery_partner_status ===
                          "REACHED_CUSTOMER_LOCATION"
                      ) ? (
                        <>
                          {formatDate(
                            orderDetails?.order_status_history_responses?.find(
                              (status: any) =>
                                status.delivery_partner_status ===
                                "REACHED_CUSTOMER_LOCATION"
                            )?.status_changed_at
                          )}
                          <span className="text-muted">
                            <span className={driverReachedStatusColor}>
                              STATUS
                            </span>
                          </span>
                        </>
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Service Started DT</div>
                    <div className="invoice-div-data">
                      {orderDetails?.order_status_history_responses?.some(
                        (status: any) =>
                          status.delivery_partner_status === "SERVICE_STARTED"
                      )
                        ? formatDate(
                            orderDetails?.order_status_history_responses?.find(
                              (status: any) =>
                                status.delivery_partner_status ===
                                "SERVICE_STARTED"
                            )?.status_changed_at
                          )
                        : "N/A"}
                    </div>
                  </div>

                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Service Completed DT</div>
                    <div className="invoice-div-data">
                      {orderDetails?.order_status_history_responses?.some(
                        (status: any) =>
                          status.delivery_partner_status === "COMPLETED"
                      )
                        ? formatDate(
                            orderDetails?.order_status_history_responses?.find(
                              (status: any) =>
                                status.delivery_partner_status === "COMPLETED"
                            )?.status_changed_at
                          )
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;

import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import "./MerchantManageMyCars.css";
import rules from "../../../rules";
import CustomInput from "../../CustomInput";
import axios from "axios";
import {
  ChevronDown,
  ChevronUp,
  PencilSquare,
  Trash,
} from "react-bootstrap-icons";
import {
  file_browse_icon,
  info_details_sample_car,
} from "../../../assets/images";
import { FieldValues, Form, useForm } from "react-hook-form";
import { Carousel } from "react-bootstrap";
import CustomCheckBox from "../../customCheckbox";
import { Category, ProductData, Feature } from "../../../types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useNavigate, useParams } from "react-router-dom";
import { SuccessModal, ErrorModal } from "../../../components";

const MerchantManageMyCars = () => {
  const { productId } = useParams();
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();

  const handleMoreDetails = (event: any) => {
    event.preventDefault();
    setShowMoreDetails(!showMoreDetails);
  };

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
    register,
    getValues,
  } = useForm({ mode: "onChange" });

  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);
  // const merchantId = decodedToken["merchant-id"];
  // const businessArea = decodedToken["planet"];
  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  // Check if decodedToken exists and has the "planet" property
  const businessArea =
    decodedToken && decodedToken["planet"] ? decodedToken["planet"] : null;

  console.log("careeeeenbfhgfyghrihfugufdhgvf:", businessArea);
  // const shopId = decodedToken["shop-id"];
  const shopId =
    decodedToken && decodedToken["shop-id"] ? decodedToken["shop-id"] : null;

  const [editMode, setEditMode] = useState(false);
  const handleEditButtonClick = () => {
    setEditMode(true);
    console.log("butttton click for ediiitttttttttttt");
  };

  const { data: featuresList } = useQuery<Feature[], Error>({
    queryKey: ["feature"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.FEATURE_LIST);
      return response.data;
    },
  });

  const [images, setImages] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [previousImages, setpreviousImages] = useState<string[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);

  //------------------------------------
  const handleDeleteExistImage = (indexToRemove: number) => {
    console.log("button delete clicked");

    if (productDetails) {
      const updatedImages = productDetails.product_images.filter(
        (_, index) => index !== indexToRemove
      );

      productDetails.product_images = updatedImages;
    }

    setpreviousImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(indexToRemove, 1);
      return updatedImages;
    });
  };

  const handleUploadDeleteImage = (indexToRemove: number) => {
    setUploadedImages((uploadedFileURLs) => {
      const updatedImages = [...uploadedFileURLs];
      if (indexToRemove < updatedImages?.length) {
        updatedImages?.splice(indexToRemove, 1);
      }
      return updatedImages;
    });

    setpreviousImages((prevImages) => {
      const totalExistingImages = productDetails
        ? productDetails?.product_images?.length
        : 0;
      const startIndexOfUploadedImages = totalExistingImages;
      const updatedPreviousImages = [...prevImages];

      if (
        startIndexOfUploadedImages + indexToRemove <
        updatedPreviousImages?.length
      ) {
        updatedPreviousImages?.splice(
          startIndexOfUploadedImages + indexToRemove,
          1
        );
      }

      return updatedPreviousImages;
    });
  };

  //--------------------------------

  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileInput = (event: any) => {
    event.preventDefault();
    fileInputRef?.current?.click();
  };

  const [isSuccess, setIsSuccess] = useState(false);

  const handleImageUpload = async (files: FileList | null) => {
    if (files) {
      try {
        const promises = [];
        const uploadedFileURLs: any[] = [];
        for (const file of Array.from(files)) {
          const formData = new FormData();
          formData.append("product_image", file);

          const response = await axiosInstance.post(
            API_URL.PRODUCT_IMAGE_UPLOAD,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          uploadedFileURLs.push(response.data);

          promises.push(response);
        }
        // Wait for all uploads to complete
        await Promise.all(promises);

        setpreviousImages((prevImages) => [...prevImages, ...uploadedFileURLs]);
        setUploadedImages((prevUploadedImages) => [
          ...prevUploadedImages,
          ...uploadedFileURLs,
        ]);

        if (fileInputRef?.current) {
          fileInputRef.current.value = "";
        }
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    }
  };

  const handleSubmit = () => {
    let {
      dailyDeposit: daily_deposit,
      weeklyDeposit: weekly_deposit,
      monthlyDeposit: monthly_deposit,
      yearlyDeposit: yearly_deposit,
      deposit,
      description: description,

      category: parent_category_id,
      subcategory: category_id,

      insurenceType: insurance_type,
      enginenumber: engine_number,
      transmission,
      fuel: fuel_type,
      number_of_seats,
      modelyear: model_year,
      priceperday: price_per_day,
      priceperweek: price_per_week,
      pricepermonth: price_per_month,
      priceperyear: price_per_year,

      registernumber: register_number,
      car_color,
      carType: car_type,
      sipp,
      car_brand,
      car_model,
      engine_capacity,
      number_of_doors,
      airbag: air_bag,
      child_seat_amount,

      year,
      dailyMileage: daily_mileage,
      weeklyMileage: weekly_mileage,
      monthlyMileage: monthly_mileage,
      excessMileageSurcharge: excess_mileage_surcharge,

      dailyCollisionDamage: daily_collision_damage_waiver,
      weeklyCollisionDamage: weekly_collision_damage_waiver,
      monthlyCollisionDamage: monthly_collision_damage_waiver,
      dailySuperCollisionDamage: daily_super_collision_damage_waiver,
      weeklySuperCollisionDamage: weekly_super_collision_damage_waiver,
      monthlySuperCollisionDamage: monthly_super_collision_damage_waiver,
      dailyPAI: daily_personal_accident_insurance,
      weeklyPAI: weekly_personal_accident_insurance,
      monthlyPAI: monthly_personal_accident_insurance,
      excess,
      insurance,
      theftWaiver: theft_waiver,
      roadsideAssistance: road_side_assistance,
      trafficFine: traffic_fines_surcharge,
      salikSurcharge: salik_surcharge,
      deliveryCharges: delivery_charges_outside_40km,
      pickupCharge: pick_up_charge_outside_40km,
      Wtp: wind_shield_protection,

      generaldailymileage: general_daily_mileage,
      generalweeklyMileage: general_weekly_mileage,
      generalmonthlyMileage: general_monthly_mileage,
      generalexcessMileageSurcharge: general_excess_mileage_surcharge,
      generaldailyCollisionDamage: general_daily_collision_damage_waiver,
      generalweeklyCollisionDamage: general_weekly_collision_damage_waiver,
      generalmonthlyCollisionDamage: general_monthly_collision_damage_waiver,
      generaldailySuperCollisionDamage:
        general_daily_super_collision_damage_waiver,
      generalweeklySuperCollisionDamage:
        general_weekly_super_collision_damage_waiver,
      generalmonthlySuperCollisionDamage:
        general_monthly_super_collision_damage_waiver,
      generaldailyPAI: general_daily_personal_accident_insurance,
      generalweeklyPAI: general_weekly_personal_accident_insurance,
      generalmonthlyPAI: general_monthly_personal_accident_insurance,
      generalexcess: general_excess,
      generaltheftWaiver: general_theft_waiver,
      generalroadsideAssistance: general_road_side_assistance,
      generaltrafficFine: general_traffic_fines_surcharge,
      generalsalikSurcharge: general_salik_surcharge,
      generaldeliveryCharges: general_delivery_charges_outside_40km,
      generalpickupCharge: general_pick_up_charge_outside_40km,
      generalWtp: general_wind_shield_protection,
    }: FieldValues = getValues();

    const userData: ProductData = {
      merchant_id: merchantId,
      shop_id: shopId,
      business_area: "CarRentalPlanet",
      // business_area: businessArea,
      product_name: category_id,
      // category_id: sub_category_id,
      // parent_category_id: category_id,
      parent_category_id,
      category_id,
      description,
      unit_price: 6000,
      gst: 5,
      variants_info: [],
      custom_attributes: [],
      buy_more_pay_less_option: null,
      toppings: [],
      //product_images: [],
      product_images: previousImages,
      record_status: 1,
      product_details_car: {
        insurance_type,
        engine_number,
        transmission,
        fuel_type,
        number_of_seats,
        ac: true,
        bluetooth: false,
        cruise_control: true,
        key_less_entry: true,
        duel_front_air_bag: false,
        abs_break: true,
        max_power: "120 hp",
        torque: "150 Nm",
        category: parent_category_id,
        model_year,
        description,
        price_per_day: parseFloat(price_per_day),
        price_per_week: parseFloat(price_per_week),
        price_per_month: parseFloat(price_per_month),
        price_per_year: parseFloat(price_per_year),
        sub_category: category_id,
        engine_type: "Inline 4-Cylinder",
        register_number,
        car_color,
        car_type,
        sipp,
        car_brand,
        car_model,
        engine_capacity,
        number_of_doors,
        air_bag,
        child_seat_amount,
        //features: selectedFeatures.join(", "),
        features: features.join(", "),
        year,
        daily_deposit: parseFloat(daily_deposit),
        weekly_deposit: parseFloat(weekly_deposit),
        monthly_deposit: parseFloat(monthly_deposit),
        yearly_deposit: parseFloat(yearly_deposit),
        deposit: parseFloat(deposit),

        mileage: {
          daily_mileage: parseFloat(general_daily_mileage),
          weekly_mileage: parseFloat(general_weekly_mileage),
          monthly_mileage: parseFloat(general_monthly_mileage),
        },
        excess_mileage_surcharge: parseFloat(general_excess_mileage_surcharge),
        collision_damage_waiver_model: {
          daily_collision_damage_waiver: parseFloat(
            general_daily_collision_damage_waiver
          ),
          weekly_collision_damage_waiver: parseFloat(
            general_weekly_collision_damage_waiver
          ),
          monthly_collision_damage_waiver: parseFloat(
            general_monthly_collision_damage_waiver
          ),
        },
        super_collision_damage_waiver_model: {
          daily_super_collision_damage_waiver: parseFloat(
            general_daily_super_collision_damage_waiver
          ),
          weekly_super_collision_damage_waiver: parseFloat(
            general_weekly_super_collision_damage_waiver
          ),
          monthly_super_collision_damage_waiver: parseFloat(
            general_monthly_super_collision_damage_waiver
          ),
        },
        personal_accident_insurance_model: {
          daily_personal_accident_insurance: parseFloat(
            general_daily_personal_accident_insurance
          ),
          weekly_personal_accident_insurance: parseFloat(
            general_weekly_personal_accident_insurance
          ),
          monthly_personal_accident_insurance: parseFloat(
            general_monthly_personal_accident_insurance
          ),
        },
        excess: parseFloat(general_excess),
        theft_waiver: parseFloat(general_theft_waiver),
        wind_shield_protection: parseFloat(general_wind_shield_protection),
        road_side_assistance: parseFloat(general_road_side_assistance),
        traffic_fines_surcharge: parseFloat(general_traffic_fines_surcharge),
        salik_surcharge: parseFloat(general_salik_surcharge),
        delivery_charges_outside_40km: parseFloat(
          general_delivery_charges_outside_40km
        ),
        pick_up_charge_outside_40km: parseFloat(
          general_pick_up_charge_outside_40km
        ),
      },
      product_car_insurance: {
        mileage_model: {
          daily_mileage: parseFloat(daily_mileage),
          weekly_mileage: parseFloat(weekly_mileage),
          monthly_mileage: parseFloat(monthly_mileage),
        },

        excess_mileage_surcharge: parseFloat(excess_mileage_surcharge),
        collision_damage_waiver_model: {
          daily_collision_damage_waiver: parseFloat(
            daily_collision_damage_waiver
          ),
          weekly_collision_damage_waiver: parseFloat(
            weekly_collision_damage_waiver
          ),
          monthly_collision_damage_waiver: parseFloat(
            monthly_collision_damage_waiver
          ),
        },
        super_collision_damage_waiver_model: {
          daily_super_collision_damage_waiver: parseFloat(
            daily_super_collision_damage_waiver
          ),
          weekly_super_collision_damage_waiver: parseFloat(
            weekly_super_collision_damage_waiver
          ),
          monthly_super_collision_damage_waiver: parseFloat(
            monthly_super_collision_damage_waiver
          ),
        },
        personal_accident_insurance_model: {
          daily_personal_accident_insurance: parseFloat(
            daily_personal_accident_insurance
          ),
          weekly_personal_accident_insurance: parseFloat(
            weekly_personal_accident_insurance
          ),
          monthly_personal_accident_insurance: parseFloat(
            monthly_personal_accident_insurance
          ),
        },
        insurance: parseFloat(insurance),
        excess: parseFloat(excess),
        theft_waiver: parseFloat(theft_waiver),
        wind_shield_protection: parseFloat(wind_shield_protection),
        road_side_assistance: parseFloat(road_side_assistance),
        traffic_fines_surcharge: parseFloat(traffic_fines_surcharge),
        salik_surcharge: parseFloat(salik_surcharge),
        delivery_charges_outside_40km: parseFloat(
          delivery_charges_outside_40km
        ),
        pick_up_charge_outside_40km: parseFloat(pick_up_charge_outside_40km),
      },
    };
    console.log("button clicked");

    console.log("userData:", userData);

    handleproductCreation.mutate(userData);

    setIsFormSubmitted(true);
    // navigate("/merchant/managecars");
  };

  const handleproductCreation = useMutation({
    mutationFn: (userData: ProductData) =>
      axiosInstance.put(`${API_URL.CREATE_PRODUCT}/${productId}`, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    onSuccess: (successResponse) => {
      console.log("successResponseproduct", successResponse);
      if (successResponse.status && successResponse?.data) {
        // alert(successResponse?.data?.message);
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
        //setShowErrorModal(true);
      }
    },
  });

  const {
    data: productDetails,
    isLoading,
    isError,
    error,
  } = useQuery<ProductData, Error>({
    queryKey: ["product"],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.VIEW_PRODUCT_DETAILS}/${productId}`
      );
      console.log("res dataa765345678987656789", response);
      return response.data;
    },
  });
  console.log("kjhgfdghjkljhgfzsdfghjhgf", productDetails);

  useEffect(() => {
    if (!isLoading && productDetails) {
      const {
        description,
        product_details_car,
        product_car_insurance,
        parent_category_id,
        category_id,
      } = productDetails || {};

      const {
        price_per_day,
        price_per_week,
        price_per_month,
        price_per_year,
        model_year,
        insurance_type,
        car_color,
        engine_number,
        register_number,
        car_type,
        sipp,
        car_brand,
        car_model,
        year,
        sub_category,
        engine_capacity,
        transmission,
        fuel_type,
        number_of_seats,
        number_of_doors,
        air_bag,
        child_seat_amount,
        deposit,
        daily_deposit,
        weekly_deposit,
        monthly_deposit,
        yearly_deposit,
        mileage,
        excess: general_excess,
        theft_waiver: general_theft_waiver,
        road_side_assistance: general_road_side_assistance,
        traffic_fines_surcharge: general_traffic_fines_surcharge,
        salik_surcharge: general_salik_surcharge,
        delivery_charges_outside_40km: general_delivery_charges_outside_40km,
        pick_up_charge_outside_40km: general_pick_up_charge_outside_40km,
        excess_mileage_surcharge: genral_excess_mileage_surcharge,
        wind_shield_protection: general_wind_shield_protection,
      } = product_details_car || {};

      const {
        daily_mileage: general_daily_mileage,
        weekly_mileage: general_weekly_mileage,
        monthly_mileage: general_monthly_mileage,
      } = mileage || {};

      const {
        daily_collision_damage_waiver: general_daily_collision_damage_waiver,
        weekly_collision_damage_waiver: general_weekly_collision_damage_waiver,
        monthly_collision_damage_waiver:
          general_monthly_collision_damage_waiver,
      } = product_details_car?.collision_damage_waiver_model || {};
      const {
        daily_super_collision_damage_waiver:
          general_daily_super_collision_damage_waiver,
        weekly_super_collision_damage_waiver:
          general_weekly_super_collision_damage_waiver,
        monthly_super_collision_damage_waiver:
          general_monthly_super_collision_damage_waiver,
      } = product_details_car?.super_collision_damage_waiver_model || {};
      const {
        daily_personal_accident_insurance:
          general_daily_personal_accident_insurance,
        weekly_personal_accident_insurance:
          general_weekly_personal_accident_insurance,
        monthly_personal_accident_insurance:
          general_monthly_personal_accident_insurance,
      } = product_details_car?.personal_accident_insurance_model || {};

      const {
        mileage_model,
        excess_mileage_surcharge,
        collision_damage_waiver_model,
        super_collision_damage_waiver_model,
        personal_accident_insurance_model,
        excess,
        insurance,
        theft_waiver,
        road_side_assistance,
        traffic_fines_surcharge,
        wind_shield_protection,
        salik_surcharge,
        delivery_charges_outside_40km,
        pick_up_charge_outside_40km,
      } = product_car_insurance || {};
      const { daily_mileage, weekly_mileage, monthly_mileage } =
        mileage_model || {};
      const {
        daily_collision_damage_waiver,
        weekly_collision_damage_waiver,
        monthly_collision_damage_waiver,
      } = collision_damage_waiver_model || {};
      const {
        daily_super_collision_damage_waiver,
        weekly_super_collision_damage_waiver,
        monthly_super_collision_damage_waiver,
      } = super_collision_damage_waiver_model || {};
      const {
        daily_personal_accident_insurance,
        weekly_personal_accident_insurance,
        monthly_personal_accident_insurance,
      } = personal_accident_insurance_model || {};
      setValue("description", description);
      setValue("priceperday", price_per_day);
      setValue("priceperweek", price_per_week);
      setValue("pricepermonth", price_per_month);
      setValue("priceperyear", price_per_year);
      setValue("category", parent_category_id);
      setValue("subcategory", sub_category);
      setValue("modelyear", model_year);
      setValue("insurancetype", insurance_type);
      setValue("car_color", car_color);
      setValue("enginenumber", engine_number);
      setValue("registernumber", register_number);
      setValue("carType", car_type);
      setValue("sipp", sipp);
      setValue("car_brand", car_brand);
      setValue("car_model", car_model);
      setValue("year", year);
      setValue("engine_capacity", engine_capacity);
      setValue("transmission", transmission);
      setValue("fuel", fuel_type);
      setValue("number_of_seats", number_of_seats);
      setValue("number_of_doors", number_of_doors);
      setValue("airbag", air_bag);
      setValue("child_seat_amount", child_seat_amount);

      setValue("deposit", deposit);
      setValue("dailyDeposit", daily_deposit);
      setValue("weeklyDeposit", weekly_deposit);
      setValue("monthlyDeposit", monthly_deposit);
      setValue("yearlyDeposit", yearly_deposit);

      setValue("dailyMileage", daily_mileage);
      setValue("weeklyMileage", weekly_mileage);
      setValue("monthlyMileage", monthly_mileage);

      setValue("excessMileageSurcharge", excess_mileage_surcharge);
      setValue("dailyCollisionDamage", daily_collision_damage_waiver);
      setValue("weeklyCollisionDamage", weekly_collision_damage_waiver);
      setValue("monthlyCollisionDamage", monthly_collision_damage_waiver);
      setValue(
        "dailySuperCollisionDamage",
        daily_super_collision_damage_waiver
      );
      setValue(
        "weeklySuperCollisionDamage",
        weekly_super_collision_damage_waiver
      );
      setValue(
        "monthlySuperCollisionDamage",
        monthly_super_collision_damage_waiver
      );
      setValue("dailyPAI", daily_personal_accident_insurance);
      setValue("weeklyPAI", weekly_personal_accident_insurance);

      setValue("monthlyPAI", monthly_personal_accident_insurance);
      setValue("insurance", insurance);
      setValue("excess", excess);
      setValue("theftWaiver", theft_waiver);
      setValue("roadsideAssistance", road_side_assistance);
      setValue("trafficFine", traffic_fines_surcharge);
      setValue("Wtp", wind_shield_protection);
      setValue("salikSurcharge", salik_surcharge);
      setValue("deliveryCharges", delivery_charges_outside_40km);
      setValue("pickupCharge", pick_up_charge_outside_40km);
      setValue("generalmonthlyMileage", general_monthly_mileage);
      setValue("generaldailymileage", general_daily_mileage);
      setValue("generalweeklyMileage", general_weekly_mileage);
      setValue("generalWtp", general_wind_shield_protection);

      setValue(
        "generalexcessMileageSurcharge",
        genral_excess_mileage_surcharge
      );
      setValue(
        "generaldailyCollisionDamage",
        general_daily_collision_damage_waiver
      );
      setValue(
        "generalweeklyCollisionDamage",
        general_weekly_collision_damage_waiver
      );
      setValue(
        "generalmonthlyCollisionDamage",
        general_monthly_collision_damage_waiver
      );
      setValue("generalexcess", general_excess);
      setValue(
        "generaldailySuperCollisionDamage",
        general_daily_super_collision_damage_waiver
      );
      setValue(
        "generalweeklySuperCollisionDamage",
        general_weekly_super_collision_damage_waiver
      );
      setValue(
        "generalmonthlySuperCollisionDamage",
        general_monthly_super_collision_damage_waiver
      );
      setValue("generaldailyPAI", general_daily_personal_accident_insurance);
      setValue("generalweeklyPAI", general_weekly_personal_accident_insurance);
      setValue(
        "generalmonthlyPAI",
        general_monthly_personal_accident_insurance
      );
      setValue("generaltheftWaiver", general_theft_waiver);
      setValue("generalroadsideAssistance", general_road_side_assistance);
      setValue("generaltrafficFine", general_traffic_fines_surcharge);
      setValue("generalsalikSurcharge", general_salik_surcharge);
      setValue("generaldeliveryCharges", general_delivery_charges_outside_40km);
      setValue("generalpickupCharge", general_pick_up_charge_outside_40km);
    }
  }, [productDetails]);

  const [features, setFeatures] = useState<string[]>([]);

  const featuresArray = productDetails?.product_details_car?.features
    ? productDetails.product_details_car.features
        .split(",")
        .map((feature: string) => feature.trim())
    : [];

  useEffect(() => {
    if (
      productDetails &&
      productDetails.product_details_car &&
      productDetails.product_details_car.features
    ) {
      const featuresArray = productDetails.product_details_car.features
        .split(",")
        .map((feature) => feature.trim());
      setFeatures(featuresArray);
    }
    console.log("featyyy", featuresArray);
  }, [productDetails]);

  useEffect(() => {
    if (!isLoading && productDetails) {
      const existingImages = productDetails.product_images || [];
      setpreviousImages(existingImages);
    }
  }, [productDetails]);

  if (isLoading) return <div>Loading Product Details...</div>;
  if (isError) return <div>Error Loading Details: {error.message}</div>;
  console.log("productttttttttttttttttttttttttdata1233444", productDetails);

  const handleFeatureChange = (feature: string) => {
    const isSelected = features.includes(feature);

    if (isSelected) {
      setFeatures(features.filter((item) => item !== feature));
    } else {
      setFeatures([...features, feature]);
      console.log("newsavedfeaturreee", features);
    }
  };

  return (
    <div className="row justify-content-md-center pt-4">
      <div
        className={`col-md-10 pt-4 info_details_box ${
          showMoreDetails ? "display-scroll-product" : ""
        }`}
      >
        <Form onSubmit={handleSubmit} control={control}>
          <div className="row px-2">
            <div className="col-md-12 margin-line">
              <div className="row pt- text-start">
                <div className="col-md-7 info_details_vehicle_text margin-line">
                  {productDetails?.product_details_car?.category}
                </div>
                <div className="col-md-7 info_details_vehicle_model_text margin-line">
                  {productDetails?.product_details_car?.sub_category}
                </div>
              </div>
            </div>
            <div className="col-md-7 text-start margin-line mb-5">
              <div className="row">
                <div className="col-md-12 text-end">
                  <PencilSquare
                    color={"#F0B64F"}
                    size={20}
                    cursor={"pointer"}
                    onClick={handleEditButtonClick}
                  />
                </div>
                <div className="col-md-12">
                  <div className="p-3">
                    {editMode ? (
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="row pt-2">
                            <div className="col-md-12 pt-3 text-center">
                              <div className="p-3 dashed-box border-dashed drag-dash-box-modal">
                                <div className="dashed-pattern">
                                  <img
                                    src={file_browse_icon}
                                    alt="file_browse_icon"
                                    className="img-fluid mb-2"
                                  />

                                  <p className="text-light mb-2">
                                    Upload Images
                                  </p>
                                  <button
                                    className="btn btn-custom"
                                    onClick={openFileInput}
                                  >
                                    Browse Documents
                                  </button>
                                  <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    multiple
                                    onChange={(e) =>
                                      handleImageUpload(e.target.files)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="row">
                            <div className="col-md-12 pt-2">
                              <table className="img-managemycar-table">
                                {productDetails?.product_images?.map(
                                  (image, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div>
                                          <img
                                            src={image}
                                            alt={`Image ${index + 1}`}
                                            className="img-div-custom"
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <span
                                          className="text-danger cursor-pointer ms-2"
                                          onClick={() =>
                                            handleDeleteExistImage(index)
                                          }
                                        >
                                          <Trash />
                                        </span>
                                      </td>

                                      <td>{/* Render uploaded images */}</td>
                                    </tr>
                                  )
                                )}
                              </table>

                              <table className="img-managemycar-table">
                                {uploadedImages?.map((image, indexs) => (
                                  <tr key={indexs}>
                                    <td>
                                      <div>
                                        <img
                                          src={image}
                                          alt={`Uploaded Image ${indexs + 1}`}
                                          className="img-div-custom"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <span
                                        className="text-danger cursor-pointer ms-2"
                                        onClick={() =>
                                          handleUploadDeleteImage(indexs)
                                        }
                                      >
                                        <Trash />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Carousel className="managemycarlist">
                        {/* {productDetails?.product_images.map((image, index) => ( */}
                        {previousImages?.map((image, index) => (
                          <Carousel.Item
                            key={index}
                            className="image-managemycar-height"
                          >
                            <img
                              className="img-merchant-managecar"
                              src={image}
                              alt={`Image ${index + 1}`}
                              width="90%"
                            />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-md-12 text-end">
                  <PencilSquare
                    color={"#F0B64F"}
                    size={20}
                    onClick={handleEditButtonClick}
                  />
                </div> */}

                <div className="col-md-6 pt-2">
                  <label className="form-label info_details_white_text">
                    Category
                    <span className="info_details_error_star">*</span>
                  </label>

                  <CustomInput
                    inputName="category"
                    inputType="category"
                    editable={false}
                    control={control}
                    className="merchant-input-style-create-product"
                    placeholder="Honda"
                    onInputChange={(value: string) => {}}
                    rules={rules.AuthRules.category}
                  />
                </div>

                <div className="col-md-6 pt-2">
                  <label className="form-label info_details_white_text">
                    Sub Category
                    <span className="info_details_error_star">*</span>
                  </label>

                  <CustomInput
                    inputName="subcategory"
                    inputType="subcategory"
                    // editable={true}
                    editable={false}
                    control={control}
                    className="merchant-input-style-create-product"
                    placeholder="CR-V"
                    onInputChange={(value: string) => {}}
                    rules={rules.AuthRules.subcategory}
                  />
                </div>
                <div className="col-md-6 pt-2">
                  <label className="form-label info_details_white_text">
                    Brand<span className="info_details_error_star">*</span>
                  </label>
                  <CustomInput
                    inputName="car_brand"
                    inputType="car_brand"
                    className="merchant-input-style-create-product"
                    placeholder="drryerfgj5464"
                    editable={false}
                    onInputChange={(value: string) => {}}
                    control={control}
                    // rules={rules.AuthRules.phone_number}
                  />
                  {/* <label className="form-label info_details_white_text">
                    Description
                    <span className="info_details_error_star">*</span>
                  </label>
                  <CustomInput
                    inputName="description"
                    inputType="description"
                    editable={editMode}
                    //disabled={true}
                    //editable={false}
                    //disabled={!editMode}
                    //editable={editMode}
                    control={control}
                    className="merchant-input-style-create-product"
                    placeholder="Turbo engine, Extra cool A/C, Full Option...."
                    onInputChange={(value: string) => {}}
                    rules={rules.AuthRules.description}
                  /> */}
                </div>

                <div className="col-md-6 pt-2">
                  <label className="form-label info_details_white_text">
                    Model<span className="info_details_error_star">*</span>
                  </label>
                  <CustomInput
                    inputName="car_model"
                    inputType="car_model"
                    className="merchant-input-style-create-product"
                    placeholder="drryerfgj5464"
                    editable={false}
                    onInputChange={(value: string) => {}}
                    control={control}
                    // rules={rules.AuthRules.phone_number}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-5 text-start mb-5">
              <div className="row manage-car-height">
                <div className="d-flex ">
                  <div className="info_details_more_information_text">
                    More Information
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-md-12">
                    <label className="form-label info_details_white_text">
                      Year<span className="info_details_error_star">*</span>
                    </label>
                    <CustomInput
                      inputName="year"
                      inputType="year"
                      className="merchant-input-style-create-product"
                      placeholder="drryerfgj5464"
                      editable={false}
                      onInputChange={(value: string) => {}}
                      control={control}
                      // rules={rules.AuthRules.phone_number}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label info_details_white_text">
                      Model Year
                      <span className="info_details_error_star">*</span>
                    </label>
                    <CustomInput
                      inputName="modelyear"
                      inputType="modelyear"
                      // editable={true}
                      editable={editMode}
                      control={control}
                      className="merchant-input-style-create-product"
                      placeholder="22 03 2017"
                      onInputChange={(value: string) => {}}
                      rules={rules.AuthRules.modelyear}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label info_details_white_text">
                      Car Color
                      <span className="info_details_error_star">*</span>
                    </label>
                    <CustomInput
                      inputName="car_color"
                      inputType="car_color"
                      className="merchant-input-style-create-product"
                      placeholder="eg:Black,Blue...."
                      editable={false}
                      onInputChange={(value: string) => {}}
                      control={control}
                      // rules={rules.AuthRules.phone_number}
                    />
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-md-12">
                    <label className="form-label info_details_white_text">
                      Engine Number
                      <span className="info_details_error_star">*</span>
                    </label>
                    <CustomInput
                      inputName="enginenumber"
                      inputType="enginenumber"
                      editable={false}
                      control={control}
                      className="merchant-input-style-create-product"
                      placeholder="EN4634867"
                      onInputChange={(value: string) => {}}
                      rules={rules.AuthRules.enginenumber}
                    />
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-md-12">
                    <label className="form-label info_details_white_text">
                      Plate Number
                      <span className="info_details_error_star">*</span>
                    </label>
                    <CustomInput
                      inputName="registernumber"
                      inputType="registernumber"
                      editable={false}
                      control={control}
                      className="merchant-input-style-create-product"
                      placeholder=""
                      onInputChange={(value: string) => {}}
                      rules={rules.AuthRules.enginenumber}
                    />
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-md-12">
                    <label className="form-label info_details_white_text">
                      STATUS
                      <span className="info_details_error_star">*</span>
                    </label>
                    <CustomInput
                      inputName="status"
                      inputType="status"
                      editable={editMode}
                      control={control}
                      className="merchant-input-style-create-product"
                      placeholder="Running in-Route"
                      onInputChange={(value: string) => {}}
                      rules={rules.AuthRules.status}
                    />
                  </div>
                  <div className="col-md-12 mt-0 pt-0">
                    <div className="info_details_right_bottom_text">
                      <u>Status can only be Editable from a location status</u>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 text-end pb-3">
              <button
                className="show-more-button px-2"
                onClick={handleMoreDetails}
              >
                {/* {showMoreDetails ? 'Hide Details' : 'More Details'} */}
                {showMoreDetails ? (
                  <>
                    Hide Details <ChevronUp size={16} />
                  </>
                ) : (
                  <>
                    More Details <ChevronDown size={16} />
                  </>
                )}
              </button>
            </div>
            <div
              className={`col-md-12 text-start pb-3 ${
                showMoreDetails ? "" : "display-none-product"
              }`}
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 text-end">
                      <PencilSquare
                        color={"#F0B64F"}
                        size={20}
                        cursor={"pointer"}
                        onClick={handleEditButtonClick}
                      />
                    </div>

                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Description
                        <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="description"
                        inputType="description"
                        editable={editMode}
                        control={control}
                        className="merchant-input-style-create-product"
                        placeholder="Turbo engine, Extra cool A/C, Full Option...."
                        onInputChange={(value: string) => {}}
                        rules={rules.AuthRules.description}
                      />
                    </div>
                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Type <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="carType"
                        inputType="text"
                        className="merchant-input-style-create-product"
                        placeholder="eg:Economy"
                        editable={editMode}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.phone_number}
                      />
                    </div>
                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        SIPP<span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="sipp"
                        inputType="sipp"
                        className="merchant-input-style-create-product"
                        placeholder="eg:EDMR"
                        editable={editMode}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.phone_number}
                      />
                    </div>

                    {/* -------------------------------------- */}

                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Engine Ltr <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="engine_capacity"
                        inputType="engine_capacity"
                        className="merchant-input-style-create-product"
                        placeholder="eg:2 ltr"
                        editable={editMode}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.phone_number}
                      />
                    </div>
                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Transmission <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="transmission"
                        inputType="transmission"
                        className="merchant-input-style-create-product"
                        placeholder="eg:AUTOMATIC"
                        editable={editMode}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.phone_number}
                      />
                    </div>
                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Fuel <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="fuel"
                        inputType="fuel"
                        className="merchant-input-style-create-product"
                        placeholder="petrol"
                        editable={editMode}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.phone_number}
                      />
                    </div>
                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Seat <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="number_of_seats"
                        inputType="number_of_seats"
                        className="merchant-input-style-create-product"
                        placeholder="eg:5"
                        editable={editMode}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.phone_number}
                      />
                    </div>
                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Doors <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="number_of_doors"
                        inputType="number_of_doors"
                        className="merchant-input-style-create-product"
                        placeholder="4"
                        editable={editMode}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.phone_number}
                      />
                    </div>
                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        AirBags <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="airbag"
                        inputType="airbag"
                        className="merchant-input-style-create-product"
                        placeholder="Front"
                        editable={editMode}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.phone_number}
                      />
                    </div>
                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Child Seat Amount{" "}
                        <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="child_seat_amount"
                        inputType="child_seat_amount"
                        className="merchant-input-style-create-product"
                        placeholder="2"
                        editable={editMode}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.phone_number}
                      />
                    </div>
                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Deposit <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="deposit"
                        inputType="deposit"
                        className="merchant-input-style-create-product"
                        placeholder="1000"
                        editable={editMode}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.phone_number}
                      />
                    </div>
                  </div>

                  <div className="row pt-4 create-align-left px-3 info_details_white_text">
                    <div className="col-md-12 text-end">
                      <PencilSquare
                        color={"#F0B64F"}
                        size={20}
                        cursor={"pointer"}
                        onClick={handleEditButtonClick}
                      />
                    </div>

                    <h5 className="create-align-left headingdiv">Features</h5>
                    <div className="row ckeck-box-featurs p-3">
                      <div className="col-md-12">
                        <div className="row">
                          {editMode
                            ? featuresList &&
                              featuresList.map((feature: any) => (
                                <div className="col-md-6" key={feature.id}>
                                  <div className="d-flex">
                                    <CustomCheckBox
                                      isChecked={features.includes(
                                        feature.description
                                      )} // Check if feature is included in features state
                                      onChange={() =>
                                        handleFeatureChange(feature.description)
                                      }
                                    />
                                    <label className="form-label  info_details_white_text pt-1">
                                      {feature.description}
                                    </label>
                                  </div>
                                </div>
                              ))
                            : featuresArray.map((feature, index) => (
                                <li key={index}>{feature}</li>
                              ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row pt-4 create-align-left info_details_white_text">
                    <div className="col-md-12 text-end">
                      <PencilSquare
                        color={"#F0B64F"}
                        size={20}
                        cursor={"pointer"}
                        onClick={handleEditButtonClick}
                      />
                    </div>
                    <h3 className="create-align-left view-headind-style">
                      General Customer Pricing Information
                    </h3>

                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Price Per Day
                        <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="priceperday"
                        inputType="priceperday"
                        editable={editMode}
                        control={control}
                        className="merchant-input-style-create-product"
                        placeholder="250 AED"
                        onInputChange={(value: string) => {}}
                        rules={rules.AuthRules.priceperday}
                      />
                    </div>
                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Price Per Week
                        <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="priceperweek"
                        inputType="priceperweek"
                        editable={editMode}
                        control={control}
                        className="merchant-input-style-create-product"
                        placeholder="2500 AED"
                        onInputChange={(value: string) => {}}
                        rules={rules.AuthRules.priceperday}
                      />
                    </div>
                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Price Per Month
                        <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="pricepermonth"
                        inputType="pricepermonth"
                        editable={editMode}
                        control={control}
                        className="merchant-input-style-create-product"
                        placeholder="25000 AED"
                        onInputChange={(value: string) => {}}
                        rules={rules.AuthRules.priceperday}
                      />
                    </div>
                    <div className="col-md-4 pt-3">
                      <label className="form-label info_details_white_text">
                        Price Per Year
                        <span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="priceperyear"
                        inputType="priceperyear"
                        editable={editMode}
                        control={control}
                        className="merchant-input-style-create-product"
                        placeholder="250000 AED"
                        onInputChange={(value: string) => {}}
                        rules={rules.AuthRules.priceperday}
                      />
                    </div>
                    <div className="col-md-12 pt-3">
                      <span className="create-align-left headingdiv">
                        Milage
                      </span>
                      <div className="row">
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Daily <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generaldailymileage"
                            inputType="generaldailymileage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Weekly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalweeklyMileage"
                            inputType="generalweeklyMileage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Monthly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalmonthlyMileage"
                            inputType="generalmonthlyMileage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Excess Mileage Surcharge (AED)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalexcessMileageSurcharge"
                            inputType="generalexcessMileageSurcharge"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 pt-3">
                      <span className="create-align-left headingdiv">
                        CDW (Collision Damage Waiver)
                      </span>
                      <div className="row">
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Daily <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generaldailyCollisionDamage"
                            inputType="generaldailyCollisionDamage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Weekly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalweeklyCollisionDamage"
                            inputType="generalweeklyCollisionDamage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Monthly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalmonthlyCollisionDamage"
                            inputType="generalmonthlyCollisionDamage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Excess <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalexcess"
                            inputType="generalexcess"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 pt-3">
                      <span className="create-align-left headingdiv">
                        SCDW (Super Collision Damage Waiver)
                      </span>
                      <div className="row">
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Daily <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generaldailySuperCollisionDamage"
                            inputType="generaldailySuperCollisionDamage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Weekly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalweeklySuperCollisionDamage"
                            inputType="generalweeklySuperCollisionDamage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Monthly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalmonthlySuperCollisionDamage"
                            inputType="generalmonthlySuperCollisionDamage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 pt-3">
                      <span className="create-align-left headingdiv">
                        PAI (Personal Accident Insurance)
                      </span>
                      <div className="row">
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Daily <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generaldailyPAI"
                            inputType="generaldailyPAI"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Weekly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalweeklyPAI"
                            inputType="generalweeklyPAI"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Monthly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalmonthlyPAI"
                            inputType="generalmonthlyPAI"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 pt-3">
                      <span className="create-align-left headingdiv">
                        Other Details
                      </span>
                      <div className="row">
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            Wind Shield Protection(WTP)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalWtp"
                            inputType="generalWtp"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            THW (Theft Waiver)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generaltheftWaiver"
                            inputType="generaltheftWaiver"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            RoadSide Assistance
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalroadsideAssistance"
                            inputType="generalroadsideAssistance"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            Traffic Fines Surcharge (AED per fine)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generaltrafficFine"
                            inputType="generaltrafficFine"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            Salik Surcharge (AED Per Toll)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalsalikSurcharge"
                            inputType="generalsalikSurcharge"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            Delivery Charges (Outside of 40km radius of nearest
                            branch within same emirate) (AED per km)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generaldeliveryCharges"
                            inputType="generaldeliveryCharges"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            Pickup Charges (Outside of 40km radius of nearest
                            branch within same emirate) (AED per km)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="generalpickupCharge"
                            inputType="generalpickupCharge"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*-------------------------------------------------------------------------*/}

                  <div className="row pt-4 create-align-left info_details_white_text">
                    <div className="col-md-12 text-end">
                      <PencilSquare
                        color={"#F0B64F"}
                        size={20}
                        cursor={"pointer"}
                        onClick={handleEditButtonClick}
                      />
                    </div>
                    <h3 className="create-align-left view-headind-style">
                      Insurance
                    </h3>

                    <div className="col-md-3 pt-3">
                      <label className="form-label">
                        Insurance<span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="insurance"
                        inputType="insurance"
                        className="merchant-input-style-create-product"
                        placeholder=""
                        editable={editMode}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.phone_number}
                      />
                    </div>

                    <div className="col-md-12 pt-3">
                      <span className="create-align-left headingdiv">
                        Milage
                      </span>
                      <div className="row">
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Daily <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="dailyMileage"
                            inputType="dailyMileage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        {/* <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Weekly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="weeklyMileage"
                            inputType="weeklyMileage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div> */}
                        {/* <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Monthly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="monthlyMileage"
                            inputType="monthlyMileage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div> */}
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Excess Mileage Surcharge (AED)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="excessMileageSurcharge"
                            inputType="excessMileageSurcharge"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 pt-3">
                      <span className="create-align-left headingdiv">
                        CDW (Collision Damage Waiver)
                      </span>
                      <div className="row">
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Daily <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="dailyCollisionDamage"
                            inputType="dailyCollisionDamage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Weekly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="weeklyCollisionDamage"
                            inputType="weeklyCollisionDamage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Monthly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="monthlyCollisionDamage"
                            inputType="monthlyCollisionDamage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Excess<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="excess"
                            inputType="excess"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 pt-3">
                      <span className="create-align-left headingdiv">
                        SCDW (Super Collision Damage Waiver)
                      </span>
                      <div className="row">
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Daily <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="dailySuperCollisionDamage"
                            inputType="dailySuperCollisionDamage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Weekly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="weeklySuperCollisionDamage"
                            inputType="weeklySuperCollisionDamage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Monthly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="monthlySuperCollisionDamage"
                            inputType="monthlySuperCollisionDamage"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        {/* <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Excess <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="excess"
                            inputType="excess"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-12 pt-3">
                      <span className="create-align-left headingdiv">
                        PAI (Personal Accident Insurance)
                      </span>
                      <div className="row">
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Daily <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="dailyPAI"
                            inputType="dailyPAI"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Weekly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="weeklyPAI"
                            inputType="weeklyPAI"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-3 pt-3">
                          <label className="form-label">
                            Monthly<span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="monthlyPAI"
                            inputType="monthlyPAI"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 pt-3">
                      <span className="create-align-left headingdiv">
                        Other Details
                      </span>
                      <div className="row">
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            WTP
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="Wtp"
                            inputType="Wtp"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            THW (Theft Waiver)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="theftWaiver"
                            inputType="theftWaiver"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            RoadSide Assistance
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="roadsideAssistance"
                            inputType="roadsideAssistance"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            Traffic Fines Surcharge (AED per fine)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="trafficFine"
                            inputType="trafficFine"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            Salik Surcharge (AED Per Toll)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="salikSurcharge"
                            inputType="salikSurcharge"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            Delivery Charges (Outside of 40km radius of nearest
                            branch within same emirate) (AED per km)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="deliveryCharges"
                            inputType="deliveryCharges"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <label className="form-label">
                            Pickup Charges (Outside of radius of nearest branch
                            within same emirate) (AED per km)
                            <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="pickupCharge"
                            inputType="pickupCharge"
                            className="merchant-input-style-create-product"
                            placeholder=""
                            editable={editMode}
                            onInputChange={(value: string) => {}}
                            control={control}
                            // rules={rules.AuthRules.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*-------------------------------------------------------------------------*/}

                  {editMode && (
                    <div className="text-center pt-3">
                      <button
                        type="button"
                        className="btn all_button_style"
                        onClick={handleSubmit}
                      >
                        SUBMIT
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>

      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/merchant/managecars");
        }}
        message="Product Edited Successfully"
      />
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={errorMessage}
      />
    </div>
  );
};

export default MerchantManageMyCars;

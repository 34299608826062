import React, { useEffect, useState } from "react";
import { Bell, Search } from "react-bootstrap-icons";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { CustomInput } from "../../../components";
import {
  dashboard_profile,
  dashboard_search_normal,
  kirkos_merchant_profile,
} from "../../../assets/images";
import { notificationSound } from "../../../assets/sounds";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance, { log_out } from "../../../services/axios.instance";
import { useQuery } from "@tanstack/react-query";
import { CustomerDetails } from "../../../types";
import { API_URL } from "../../../services/apiConfig";
import { toast, ToastContainer } from "react-toastify";
interface Props {
  currentPage: string;
  notifications: string[];
  handleNotificationClick: (notification: string) => void;
  newNotificationsCount: number;
  setNewNotificationsCount: (count: number) => void;
}

const MerchantDashboardnavbar: React.FC<Props> = ({
  currentPage,
  notifications,
  handleNotificationClick,
  newNotificationsCount,
  setNewNotificationsCount,
}) => {
  const [showProfile, setShowProfile] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  //const [notifications, setNotifications] = useState<string[]>([]);

  const [profileImage, setProfileImage] = useState<string>(
    kirkos_merchant_profile
  );
  const navigate = useNavigate();

  const handleProfile = () => {
    setShowProfile(!showProfile);
  };
  const handleNotification = () => {
    setShowNotification(!showNotification);
    setNewNotificationsCount(0);
  };

  const handleSetActiveNavigationLogout = (nav: string) => {
    log_out();
    // dispatch(clearBookingValues());
    //navigate("/");
  };

  const {
    data: customerData,
    error,
    refetch,
  } = useQuery<CustomerDetails, Error>({
    queryKey: ["navprofiledetails"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.CUSTOMER_DETAILS);
      const details = response.data;
      setProfileImage(details?.merchant_details?.siteLogo);
      return details;
    },
  });

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  return (
    <div className="nav-bar-style">
      {/* <ToastContainer className={"custom-toast"} /> */}
      <Navbar expand="lg" className="nav-pills navbar-light nav-tab-style">
        <Navbar.Collapse>
          <div className="col navheaderdiv">
            <Navbar.Brand href="#" className="navbar-brand-style">
              {currentPage === "dashboard"
                ? "Merchant Dashboard"
                : currentPage === "manageMycar"
                ? "Manage My Cars  / Upload"
                : currentPage === "listmycar"
                ? "Manage My Cars  / Listings"
                : currentPage === "notification"
                ? "Notifications / Inbox"
                : currentPage === "carstatus"
                ? "Car Status"
                : currentPage === "tripstatus"
                ? "Trip Status"
                : currentPage === "coustomerinvoice"
                ? "Customer Order / Purchase Order"
                : currentPage === "Acceptedorder"
                ? "Accepted Orders / Approved Orders"
                : currentPage === "Deliveredorder"
                ? "Delivered Order"
                : currentPage === "recollectedorder"
                ? "Recollected order"
                : currentPage === "settings"
                ? "Settings"
                : currentPage === "depositandpenality"
                ? "Deposits & Penalty"
                : currentPage === "deliveryboylist"
                ? "DeliveryBoy List"
                : ""}
            </Navbar.Brand>
            <Navbar.Text>
              {/* <a className="nav-link brand-bottom " href="#">
              {currentPage === "manageMycar"
                ? '<span className="brand-bottom-first">Merchant Dashboard</span><span className="navspan-bg-color">Manage My Car / Listings</span>'
                : currentPage === "coustomerinvoice"
                ? '<span className="brand-bottom-first"></span><span className="navspan-bg-color"></span>'
                : ""}
            </a> */}

              <a className="nav-link brand-bottom " href="#">
                {currentPage === "manageMycar" ? (
                  <span>
                    <span className="brand-bottom-first">
                      Merchant Dashboard &gt;{" "}
                    </span>
                    <span className="navspan-bg-color">
                      Manage My Car / Listings
                    </span>
                  </span>
                ) : currentPage === "deliveryboylist" ? (
                  <>
                    <span className="brand-bottom-first">
                      Merchant Dashboard&gt;
                    </span>
                    <span className="navspan-bg-color">DeliveryBoy List</span>
                  </>
                ) : currentPage === "coustomerinvoice" ? (
                  <>
                    <span className="brand-bottom-first">
                      Merchant Dashboard Order Processing &gt;
                    </span>
                    <span className="navspan-bg-color">
                      Customer Orders/ Purchase Order
                    </span>
                  </>
                ) : currentPage === "Acceptedorder" ? (
                  <>
                    <span className="brand-bottom-first">
                      Merchant Dashboard Order Processing &gt;
                    </span>
                    <span className="navspan-bg-color">
                      Accepted Orders/ Approved Orders
                    </span>
                  </>
                ) : currentPage === "Deliveredorder" ? (
                  <>
                    <span className="brand-bottom-first">
                      Merchant Dashboard Order Processing &gt;
                    </span>
                    <span className="navspan-bg-color">Delivered Order</span>
                  </>
                ) : currentPage === "Acceptedorder" ? (
                  <>
                    <span className="brand-bottom-first">
                      Merchant Dashboard Order Processing &gt;
                    </span>
                    <span className="navspan-bg-color">Recollected Order</span>
                  </>
                ) : currentPage === "trackorders" ? (
                  <>
                    <span className="brand-bottom-first">
                      Merchant Dashboard&gt;
                    </span>
                    <span className="navspan-bg-color">
                      Track Orders/ Assigned Order
                    </span>
                  </>
                ) : currentPage === "depositandpenality" ? (
                  <>
                    <span className="brand-bottom-first">
                      Merchant Dashboard&nbsp;&gt;&nbsp;Reports&nbsp;&gt;&nbsp;
                    </span>
                    <span className="navspan-bg-color">Deposits & Penalty</span>
                  </>
                ) : null}
              </a>
            </Navbar.Text>
          </div>
        </Navbar.Collapse>
        {/* <Navbar.Toggle aria-controls="navbarText" /> */}
        <Nav className="right-side-nav justify-content-end">
          <div className="justify-content-end d-inline-flex">
            <li className="nav-item nav-bar-li">
              <a className="nav-link search-bar-bg p-1">
                <form className="d-flex">
                  <label htmlFor="searchbox">
                    <img src={dashboard_search_normal} width="30" height="30" />
                  </label>
                  <input
                    id="searchbox"
                    type="text"
                    className="search-bar-input-box px-1"
                    placeholder="Search your car activity log"
                  />
                </form>
              </a>
            </li>
            {/* <li
              className="nav-item px-2 nav-bar-li custom-d-none-navbar"
              style={{ width: 55 }}
              onClick={toastmessage}
            >
              <a className="nav-link search-bar-bg" href="#">
                <Bell size={20} color="white" />
              </a>
            </li> */}
            <Dropdown
              show={showNotification}
              onToggle={handleNotification}
              style={{ width: 55 }}
              className="pt-1"
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                className="notification-hover p-0 relative-div "
              >
                <div
                  className={notifications.length > 0 ? "icon-bell-hover" : ""}
                >
                  <Bell size={20} color=" white " />
                </div>

                {newNotificationsCount > 0 && (
                  <div className="notification-badge icon-hover ">
                    {newNotificationsCount}
                  </div>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="display-drop-down-notification">
                {notifications.length > 0 ? (
                  notifications.map((notification, index) => (
                    <Dropdown.Item
                      className="word-in-line "
                      key={index}
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <div className="">{notification}</div>
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item className="word-in-line">
                    No new notifications
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>

              {/* <Dropdown.Menu className="display-drop-down">
                <Dropdown.Item>notification 1</Dropdown.Item>
                <Dropdown.Item>notification 2</Dropdown.Item>
              </Dropdown.Menu> */}
            </Dropdown>
            <Dropdown
              show={showProfile}
              onToggle={handleProfile}
              style={{ width: 100 }}
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                className="profile-hover pt-1 px-2"
              >
                <img
                  src={profileImage || kirkos_merchant_profile}
                  className="rounded-pill profile-logo"
                  alt=""
                />
                <span className="custom-d-none-navbar">&nbsp;Profile</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="display-drop-down">
                <Dropdown.Item as={Link} to="/merchant/managemyprofile">
                  {/* <Link  className="link-navbar"> */}
                  Profile
                  {/* </Link> */}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="#">
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="/"
                  onClick={() => handleSetActiveNavigationLogout("logout")}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </Navbar>
    </div>
  );
};

export default MerchantDashboardnavbar;

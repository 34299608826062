import React, { useEffect } from "react";

import { dashboard_vertical_three_dots } from "../../../assets/images";

import CreateProduct from "../../../components/MerchantDashboard/CreateProduct";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useState } from "react";
import {
  Car,
  ProductList,
  ProductDelete,
  ApiResponse,
  AflApiResponse,
} from "../../../types";

import {
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  Form,
  FormControl,
  Pagination,
  Row,
} from "react-bootstrap";
import ConfirmationModal from "./../../../components/ConfirmationModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const ServiceList = () => {
  const navigate = useNavigate();

  const [selectedStatus, setSelectedStatus] = useState("PENDING");
  const [serviceList, setServiceList] = useState<AflApiResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [deletePayload, setDeletePayload] = useState<ProductDelete | null>(
    null
  );
  const [searchResults, setSearchResults] = useState<AflApiResponse | null>(
    null
  );

  const [searchCriteria, setSearchCriteria] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentSearchPage, setCurrentSearchPage] = useState(0);

  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);

  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  const businessArea =
    decodedToken && decodedToken["planet"] ? decodedToken["planet"] : null;

  const mutation = useMutation({
    mutationFn: (userData: any) =>
      axiosInstance.post(
        `${API_URL.SERVICE_LIST}?page=${currentPage}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ),

    onSuccess: (successResponse) => {
      console.log("successResponseproductlist", successResponse);

      setServiceList(successResponse?.data);
      setTotalPages(successResponse?.data?.totalPages);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
      }
    },
  });

  useEffect(() => {
    mutation.mutate({
      //  erp_service_number: "",
      phone_number: "",
      status: "",
      reference_number: "",
      service_type: "",
      date: "",
    });
  }, []);
  useEffect(() => {
    mutation.mutate({
      //erp_service_number: "",
      phone_number: "",
      status: "",
      reference_number: "",
      service_type: "",
      date: "",
    });
  }, [currentPage]); // Add currentPage as a dependency

  //-------------------------------

  const handleSearch = async (currentPage: number) => {
    const formattedDate =
      searchCriteria === "date" && searchQuery
        ? searchQuery.split("-").reverse().join("-")
        : "";
    const payload = {
      reference_number: searchCriteria === "ref_num" ? searchQuery : "",
      status: searchCriteria === "status" ? searchQuery : "",
      service_type: searchCriteria === "service_type" ? searchQuery : "",

      date: searchCriteria === "date" ? formattedDate : "",
      phone_number: searchCriteria === "phone_number" ? searchQuery : "",
      count: 10,
      page: currentPage,
    };

    try {
      const response = await axiosInstance.post(API_URL.SERVICE_LIST, payload);
      //   setServiceList(response?.data);
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching search results", error);
    }
  };

  //-----------------------------

  const [visiblePages, setVisiblePages] = useState<number[]>(() => {
    const totalPages = serviceList?.totalPages || 0;

    return totalPages >= 3
      ? [0, 1, 2]
      : Array.from({ length: totalPages }, (_, i) => i);
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);

    const totalPages = serviceList?.totalPages || 0;

    if (totalPages >= 3) {
      const startPage = Math.floor(page / 3) * 3;
      const endPage = Math.min(startPage + 2, totalPages - 1);
      setVisiblePages(
        Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
      );
    } else {
      setVisiblePages(Array.from({ length: totalPages }, (_, i) => i));
    }
  };

  useEffect(() => {
    if (serviceList?.totalPages) {
      const totalPages = serviceList?.totalPages || 0;

      if (totalPages >= 3) {
        const startPage = Math.floor(currentPage / 3) * 3;
        const endPage = Math.min(startPage + 2, totalPages - 1);
        setVisiblePages(
          Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i
          )
        );
      } else {
        setVisiblePages(Array.from({ length: totalPages }, (_, i) => i));
      }
    }
  }, [serviceList, currentPage]);

  //   const handlePageChange = (page: number) => {
  //     setCurrentPage(page);
  //   };

  const [searchVisiblePages, setSearchVisiblePages] = useState<number[]>(() => {
    const totalPages = searchResults?.totalPages || 0;

    return totalPages >= 3
      ? [0, 1, 2]
      : Array.from({ length: totalPages }, (_, i) => i);
  });

  //-----------------------

  useEffect(() => {
    if (searchResults?.totalPages) {
      const totalPages = searchResults?.totalPages;
      const startPage = Math.floor(currentSearchPage / 3) * 3;
      const endPage = Math.min(startPage + 2, totalPages - 1);

      setSearchVisiblePages(
        totalPages >= 3
          ? Array.from(
              { length: endPage - startPage + 1 },
              (_, i) => startPage + i
            )
          : Array.from({ length: totalPages }, (_, i) => i)
      );
    }
  }, [searchResults, currentSearchPage]);

  // const handleSearchPageChange = (page: number) => {
  //   setSearchPage(page);
  // };
  const handlePageSearchChange = (page: number) => {
    setCurrentSearchPage(page);
    handleSearch(page);
  };

  //------------------------------------------------------------------
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] =
    useState(false);

  const [acceptConfirmationMessage, setAcceptConfirmationMessage] =
    useState("");
  const [selectedOrderId, setSelectedOrderId] = useState<string>("");

  const mutationDelete = useMutation({
    mutationFn: (orderId: string) =>
      axiosInstance.put(`${API_URL.CANCEL_SERVICE}/${orderId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    onSuccess: (successResponse) => {
      //window.location.reload();
      mutation.mutate({
        //erp_service_number: "",
        phone_number: "",
        status: "",
        reference_number: "",
        service_type: "",
        date: "",
      });
      console.log("successResponse", successResponse);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
      }
      mutation.mutate({
        //erp_service_number: "",
        phone_number: "",
        status: "",
        reference_number: "",
        service_type: "",
        date: "",
      });
    },
  });

  const handleDeleteItem = (orderId: string) => {
    setAcceptConfirmationMessage(
      "Are you sure you want to cancel this service?"
    );
    setShowAcceptConfirmationModal(true);
    setSelectedOrderId(orderId);
    //mutationDelete.mutate(orderId);
  };

  const handleOpenConfirmationModal = (message: string) => {
    setAcceptConfirmationMessage(message);
    setShowAcceptConfirmationModal(true);
  };

  //-------------------------------------------------------------------
  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

  const location = useLocation();
  const pathname = location.pathname;
  const ordersToDisplay = searchResults?.content || serviceList?.content;
  return (
    <div className="row">
      <Card className="card-bg-afl text-start">
        <CardBody>
          {/* <h4 className='text-start car_upload_text align-items-center d-flex'>
            <FormatListBulletedIcon style={{ color: "#ed7d31", fontSize: "35px" }} />
            Service List
          </h4> */}
          <div className="col-md-12 mb-3 mt-2">
            <Form.Group as={Row} className="align-items-center ">
              <Col sm="3">
                <Form.Select
                  className="select-invoice-bg-black-edit dashboard-poppins-font-style custom-input-afl"
                  aria-label="Select search criteria"
                  value={searchCriteria}
                  onChange={(e) => setSearchCriteria(e.target.value)}
                >
                  <option value="">Search by</option>
                  <option value="ref_num">Reference Number</option>
                  <option value="service_type">Service Name</option>
                  <option value="status">Status</option>
                  <option value="phone_number">Phone Number</option>
                  <option value="date">Date</option>
                </Form.Select>
              </Col>
              {searchCriteria && (
                <>
                  <Col sm="5">
                    <FormControl
                      className="merchant-input-style-create-product custom-input-afl"
                      aria-label="Text input with select"
                      placeholder={
                        searchCriteria === "date"
                          ? "DD-MM-YYYY"
                          : "Enter your Search"
                      }
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Col>
                  <Col sm="2">
                    <Button
                      className="search-button"
                      onClick={() => handleSearch(currentPage)}
                    >
                      Search
                    </Button>
                  </Col>
                </>
              )}
            </Form.Group>
          </div>
          <div className="col-md-12">
            <div className="row pt-3">
              <div className="col-12 dashboard-table-div">
                <table className="table table-hover dashboard-table table-afl">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th style={{ minWidth: "150px" }}>Job Ref No</th>
                      <th style={{ minWidth: "150px" }}>Entrydate</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Plate No</th>
                      <th style={{ minWidth: "250px" }}>From Loc</th>
                      <th style={{ minWidth: "250px" }}>To Loc</th>
                      <th style={{ minWidth: "200px" }}>ServiceType </th>
                      <th>Status</th>
                      <th style={{ minWidth: "250px" }}>Comments</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ordersToDisplay &&
                      ordersToDisplay?.map(
                        (serviceOrder: any, index: number) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {
                                serviceOrder?.orderServiceDetails
                                  ?.referenceNumber
                              }
                            </td>
                            <td>
                              {formatDate(serviceOrder?.orderView?.createdAt)}
                            </td>
                            <td>{serviceOrder?.orderView?.customer_name}</td>
                            <td>
                              {serviceOrder?.orderView?.customer_phone_number}
                            </td>
                            <td>
                              {serviceOrder?.orderServiceDetails?.vehicleMake}
                            </td>
                            <td>
                              {serviceOrder?.orderServiceDetails?.carModel}
                            </td>
                            <td>{serviceOrder?.orderView?.plate_number}</td>

                            {serviceOrder?.orderView?.recollection_location ? (
                              <>
                                <td>
                                  {
                                    serviceOrder?.orderView?.delivery_location
                                      ?.address
                                  }
                                </td>
                                <td>
                                  {
                                    serviceOrder?.orderView
                                      ?.recollection_location?.address
                                  }
                                </td>
                              </>
                            ) : (
                              <>
                                <td></td>
                                <td>
                                  {
                                    serviceOrder?.orderView?.delivery_location
                                      ?.address
                                  }
                                </td>
                              </>
                            )}
                            <td>
                              {serviceOrder?.orderServiceDetails?.serviceName}
                            </td>
                            <td>
                              {serviceOrder?.aaaServiceRequestModel?.state}
                            </td>
                            <td>
                              {serviceOrder?.orderServiceDetails?.aflComments}
                            </td>
                            <td className="table-action-btn">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                  className="Dropdown-table"
                                >
                                  <MoreVertIcon
                                    style={{
                                      color: "#333134",
                                      fontSize: "20px",
                                    }}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="Dropdown-table-item border-none">
                                  {serviceOrder?.aaaServiceRequestModel
                                    ?.state === "initiate" && (
                                    <>
                                      <Dropdown.Item
                                        className="Dropdown-table-list Dropdown-table-border-top border-none"
                                        onClick={() =>
                                          handleDeleteItem(
                                            serviceOrder?.orderView?.orderId
                                          )
                                        }
                                      >
                                        Cancel
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as={Link}
                                        to={`/dashboard/servicelist/rescheduledate?OrderId=${serviceOrder.orderView.orderId}`}
                                        className="Dropdown-table-list Dropdown-table-border-bottom border-none"
                                      >
                                        Reschedule
                                      </Dropdown.Item>
                                    </>
                                  )}

                                  <Dropdown.Item
                                    as={Link}
                                    to={`/dashboard/servicelist/servicedetails?OrderId=${serviceOrder.orderView.orderId}`}
                                    className="Dropdown-table-list Dropdown-table-border-bottom border-none"
                                  >
                                    View Details
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
              <div className="text-end">
                {searchResults === null && serviceList ? (
                  <Pagination className="pagination-align">
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 0}
                    />
                    {visiblePages.map((page) => (
                      <Pagination.Item
                        key={page}
                        active={page === currentPage}
                        onClick={() => handlePageChange(page)}
                      >
                        {page + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={
                        currentPage === (serviceList?.totalPages || 0) - 1
                      }
                    />
                  </Pagination>
                ) : searchResults ? (
                  <Pagination className="pagination-align">
                    <Pagination.Prev
                      onClick={() =>
                        handlePageSearchChange(currentSearchPage - 1)
                      }
                      disabled={currentSearchPage === 0}
                    />
                    {searchVisiblePages?.map((page) => (
                      <Pagination.Item
                        key={page}
                        active={page === currentSearchPage}
                        onClick={() => handlePageSearchChange(page)}
                      >
                        {page + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() =>
                        handlePageSearchChange(currentSearchPage + 1)
                      }
                      disabled={
                        currentSearchPage ===
                        (searchResults?.totalPages || 0) - 1
                      }
                    />
                  </Pagination>
                ) : null}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <ConfirmationModal
        isOpen={showAcceptConfirmationModal}
        onClose={() => setShowAcceptConfirmationModal(false)}
        onConfirm={() => {
          if (selectedOrderId) {
            mutationDelete.mutate(selectedOrderId);
          }
          setShowAcceptConfirmationModal(false);
        }}
        message={acceptConfirmationMessage}
      />
    </div>
  );
};

export default ServiceList;

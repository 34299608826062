import { useEffect, useState } from "react";
import { Button, Col, Form, Pagination, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  NotificationData,
  NotificationList,
  NotificationState,
} from "../../../types";
import DatePicker from "react-datepicker";

const MerchantNotifications = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      return decoded;
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);
  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  const [currentPage, setCurrentPage] = useState(0);
  //const [visiblePages, setVisiblePages] = useState([0, 1, 2]);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [searchPage, setSearchPage] = useState(0);
  //   const [searchVisiblePages, setSearchVisiblePages] = useState([0, 1, 2]);

  const [searchResults, setSearchResults] = useState<any | null>(null);

  const fetchDeliveredOrders = async (page: number) => {
    const response = await axiosInstance.post(API_URL.NOTIFICATION_LIST, {
      page,
      size: "10",
      date: "",
    });
    return response?.data;
  };

  const { data: DeliveredOrders } = useQuery<NotificationList>({
    queryKey: ["DeliveredOrders", currentPage],
    queryFn: () => fetchDeliveredOrders(currentPage),
    // refetchInterval: 30000,
  });
  const [visiblePages, setVisiblePages] = useState<number[]>(() => {
    const totalPages = DeliveredOrders?.totalPages || 0;

    return totalPages >= 3
      ? [0, 1, 2]
      : Array.from({ length: totalPages }, (_, i) => i);
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);

    const totalPages = DeliveredOrders?.totalPages || 0;
    // const startPage = Math.floor(page / 3) * 3;
    // const endPage = Math.min(startPage + 2, totalPages - 1);
    // setVisiblePages(
    //   Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
    // );
    if (totalPages >= 3) {
      const startPage = Math.floor(page / 3) * 3;
      const endPage = Math.min(startPage + 2, totalPages - 1);
      setVisiblePages(
        Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
      );
    } else {
      setVisiblePages(Array.from({ length: totalPages }, (_, i) => i));
    }
  };

  //--------

  useEffect(() => {
    if (DeliveredOrders?.totalPages) {
      const totalPages = DeliveredOrders?.totalPages || 0;

      if (totalPages >= 3) {
        const startPage = Math.floor(currentPage / 3) * 3;
        const endPage = Math.min(startPage + 2, totalPages - 1);
        setVisiblePages(
          Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i
          )
        );
      } else {
        setVisiblePages(Array.from({ length: totalPages }, (_, i) => i));
      }
    }
  }, [DeliveredOrders, currentPage]);

  //-----------

  const formatDateForPayload = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSearch = async () => {
    if (selectedDate) {
      const formattedDate = formatDateForPayload(selectedDate);

      try {
        const response = await axiosInstance.post(API_URL.NOTIFICATION_LIST, {
          page: searchPage,
          size: "10",
          date: formattedDate,
        });

        setSearchResults(response?.data || null);
      } catch (error) {
        console.error("Error fetching notifications by date:", error);
      }
    }
  };

  useEffect(() => {
    if (selectedDate) {
      handleSearch();
    }
  }, [searchPage]);

  //-----------------------------
  const [searchVisiblePages, setSearchVisiblePages] = useState<number[]>(() => {
    const totalPages = searchResults?.totalPages || 0;

    return totalPages >= 3
      ? [0, 1, 2]
      : Array.from({ length: totalPages }, (_, i) => i);
  });

  //   const handleSearchPageChange = (page: number) => {
  //     setSearchPage(page);
  //     const totalPages = searchResults?.totalPages || 0;

  //     // const startPage = Math.floor(page / 3) * 3;
  //     // const endPage = Math.min(startPage + 2, totalPages - 1);
  //     // setSearchVisiblePages(
  //     //   Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
  //     // );
  //     if (totalPages >= 3) {
  //       const startPage = Math.floor(page / 3) * 3;
  //       const endPage = Math.min(startPage + 2, totalPages - 1);
  //       setSearchVisiblePages(
  //         Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
  //       );
  //     } else {
  //       setSearchVisiblePages(Array.from({ length: totalPages }, (_, i) => i));
  //     }
  //   };

  //-----------------------

  useEffect(() => {
    if (searchResults?.totalPages) {
      const totalPages = searchResults?.totalPages;
      const startPage = Math.floor(searchPage / 3) * 3;
      const endPage = Math.min(startPage + 2, totalPages - 1);

      setSearchVisiblePages(
        totalPages >= 3
          ? Array.from(
              { length: endPage - startPage + 1 },
              (_, i) => startPage + i
            )
          : Array.from({ length: totalPages }, (_, i) => i)
      );
    }
  }, [searchResults, searchPage]);

  const handleSearchPageChange = (page: number) => {
    setSearchPage(page);
  };

  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const handleClear = () => {
    setSelectedDate(null);
    setCurrentPage(0);
    setSearchResults(null);
    setSearchPage(0);
    fetchDeliveredOrders(0);
  };
  //---------------------------------------------------------------------

  //---------------------------------------------------------------------------
  const handleNavigate = async (messageData: NotificationData) => {
    const notificationPayload = {
      orderNumber: messageData?.orderNumber,
      sentScenario: messageData?.sentScenario,
    };

    try {
      await handleNotificationUpdate.mutateAsync(notificationPayload);
      console.log("Notification updated successfully, calling API...");

      const response = await axiosInstance.get(
        `${API_URL.ORDER_DETAILS}/${messageData?.orderNumber}/details`,
        {
          headers: {
            location: "0,0",
            language: "english",
          },
        }
      );

      console.log("Order details fetched successfully:", response?.data);

      const apiOrderStatus = response?.data?.orderStatus;

      if (apiOrderStatus === "ORDER_ACCEPT") {
        navigate(
          `/merchant/invoice/details?OrderId=${messageData?.orderNumber}`,
          {
            state: {
              notificationId: messageData?.orderNumber,
            },
          }
        );
      } else if (apiOrderStatus === "MERCHANT_ORDER_ACCEPT") {
        navigate(
          `/merchant/accepted/details?OrderId=${messageData?.orderNumber}`,
          {
            state: {
              notificationId: messageData?.orderNumber,
            },
          }
        );
      } else if (apiOrderStatus === "DELIVERED") {
        navigate(
          `/merchant/delivered/details?OrderId=${messageData?.orderNumber}`,
          {
            state: {
              notificationId: messageData?.orderNumber,
            },
          }
        );
      } else if (apiOrderStatus === "RECOLLECTED") {
        navigate(
          `/merchant/recollected/details?OrderId=${messageData?.orderNumber}`,
          {
            state: {
              notificationId: messageData?.orderNumber,
            },
          }
        );
      } else {
        console.warn("Unknown order status:", apiOrderStatus);
      }
    } catch (error: any) {
      console.error(
        "Error occurred while updating notification or fetching order details:",
        error
      );
    }
  };

  const handleNotificationUpdate = useMutation({
    mutationFn: (notificationStates: NotificationState) =>
      axiosInstance.put(API_URL.NOTIFICATION_UPDATE, notificationStates),
    onSuccess: (successResponse) => {
      console.log("Notification updated successfully:", successResponse);
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        console.error(
          "Error updating notification:",
          error.response.data.message
        );
      }
    },
  });

  const ordersToDisplay = searchResults?.content || DeliveredOrders?.content;
  return (
    <div className="row">
      <div className="col-md-12 mb-3 mt-2">
        <Form.Group as={Row} className="align-items-center">
          <Col sm="3">
            <DatePicker
              className="search-bar-export"
              selected={selectedDate}
              onChange={(date: Date | null) => setSelectedDate(date)}
              dateFormat="dd-MM-yyyy"
              customInput={
                <input
                  readOnly
                  className="input-date"
                  value={selectedDate ? formatDateForPayload(selectedDate) : ""}
                />
              }
            />
          </Col>
          <Col sm="3">
            <Button
              className="btn all_button_style text-black my-1"
              onClick={handleSearch}
              disabled={!selectedDate}
            >
              Search by Date
            </Button>
          </Col>
          <Col sm="2">
            <Button
              className="btn all_button_style text-black my-1"
              variant="danger"
              onClick={handleClear}
            >
              Clear
            </Button>
          </Col>
        </Form.Group>
      </div>
      <div className="col-md-12 dashboard-table-div">
        <table className="table table-hover dashboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Message</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {ordersToDisplay?.map(
              (messageData: NotificationData, index: number) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{messageData?.message}</td>
                  {/* <td></td> */}
                  <td>
                    <span className="invoice-date-td">
                      {formatDate(messageData?.created_at)}
                    </span>
                  </td>

                  <td>
                    <button
                      className={`notification_status ${
                        messageData.isViewed ? "read" : "unread"
                      }`}
                      onClick={() => handleNavigate(messageData)}
                    >
                      {messageData.isViewed ? "Read" : "Unread"}
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>

        {searchResults === null && DeliveredOrders ? (
          <Pagination className="pagination-align">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
            />
            {visiblePages.map((page) => (
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => handlePageChange(page)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === (DeliveredOrders?.totalPages || 0) - 1}
            />
          </Pagination>
        ) : searchResults ? (
          <Pagination className="pagination-align">
            <Pagination.Prev
              onClick={() => handleSearchPageChange(searchPage - 1)}
              disabled={searchPage === 0}
            />
            {searchVisiblePages?.map((page) => (
              <Pagination.Item
                key={page}
                active={page === searchPage}
                onClick={() => handleSearchPageChange(page)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handleSearchPageChange(searchPage + 1)}
              disabled={searchPage === (searchResults?.totalPages || 0) - 1}
            />
          </Pagination>
        ) : null}
      </div>
    </div>
  );
};

export default MerchantNotifications;
